import React from 'react';
import { PROFILE_TABS } from '../../constants';
import ProfileUpdate from './ProfileUpdate';
import ProfileSecurity from './ProfileSecurity'
import Header from '../header/Header';

class ProfileContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = { activeTabId: PROFILE_TABS.profile }
    }

    onChangeActiveTab = (tab) => {
        this.setState({
            activeTabId: tab
        })
    }

    render() {
        const { activeTabId } = this.state;
        return (
            <section className="profile-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="header-bar sticky-top col-12">
                            <div className="row">
                                <Header locationPathName={this.props.location.pathname} />
                                <div className="col-12 submenu">
                                    <div className="row d-flex justify-content-center">
                                        <div className="col-12 col-lg-4 col-xl-4 offset-xl-0">
                                            <div className="nav col-6 offset-1 col-md-4 offset-md-4 col-lg-9 offset-lg-2 d-flex justify-content-between">
                                                <div className="col-4 text-center">
                                                    <button className={`submenu-tab py-4 ${activeTabId === PROFILE_TABS.profile ? "active" : ""}`} id={PROFILE_TABS.profile} onClick={() => (this.onChangeActiveTab(PROFILE_TABS.profile))}>Profile</button>
                                                </div>
                                                <div className="col-4 text-center">
                                                    <button className={`submenu-tab py-4 ${activeTabId === PROFILE_TABS.security ? "active" : ""}`} id={PROFILE_TABS.security} onClick={() => (this.onChangeActiveTab(PROFILE_TABS.security))}>Security</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {activeTabId === PROFILE_TABS.profile && <ProfileUpdate />}
                        {activeTabId === PROFILE_TABS.security && <ProfileSecurity />}
                    </div>
                </div>
            </section >
        );
    }
}
export default ProfileContainer;