import React from 'react';

export default function Icon(props) {
   const iconNames = {
      back: 'M10.5381 19.863C15.9447 19.863 20.3433 15.5019 20.3433 10.1413C20.3433 4.7807 15.9447 0.419523 10.5381 0.419522C5.13157 0.419522 0.732978 4.7807 0.732978 10.1413C0.732977 15.5019 5.13156 19.863 10.5381 19.863ZM10.5381 5.2804L10.5381 9.1691L15.4407 9.1691L15.4407 11.1135L10.5381 11.1135L10.5381 15.0022L5.63555 10.1413L10.5381 5.2804Z',
   }

   return (
      <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d={iconNames[props.name]} fill="var(--faded)" />
      </svg>
   );
}