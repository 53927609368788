import moment from 'moment';

export const formatDate = (dateString, format = 'DD MMM YYYY, h:mm a') => {
   return dateString ? moment(Number(dateString)).format(format) : '';
};

export const updateQueryParam = ({ history, setOptions = {}, removeOptions = {}, action = 'replace' }) => {
   const searchParams = new URLSearchParams(history.location.search);
   Object.entries(setOptions).forEach(([key, value]) => {
      searchParams.set(key, value);
   });
   Object.entries(removeOptions).forEach(([key, value]) => {
      searchParams.delete(key);
   });
   if (action === 'replace') {
      history.replace(`?${searchParams.toString()}`);
   } else {
      history.push(`?${searchParams.toString()}`);
   }
}

// get the first 3 characters of the string
export const getSearchSubString = (value) => {
  if (!value || typeof value == 'number') {
    return "";
  }
  return value.slice(0, 3).trim().toLowerCase();
};

// get the search id from the query
export const getSearchId = (query) => {
   if (typeof query == 'number') {
      return query;
   }

  // Handle null or empty strings
  if (!query || query.trim() === "") {
    return "";
  }

  // if there is an _ return the string after the _
  if (query.includes("_")) {
    const parts = query.split("_");
    return parts.length > 1 ? parts[1] : "";
  }

  // split the string at the first number and return the numbers
  const firstNumber = query.match(/\d+/);
  if (firstNumber) {
    return firstNumber[0];
  }

  // if there is a space return the string after the space
  if (query.includes(" ")) {
    const parts = query.split(" ");
    return parts.length > 1 ? parts[1] : "";
  }

  // if there is a - return the string after the -
  if (query.includes("-")) {
    const parts = query.split("-");
    return parts.length > 1 ? parts[1] : "";
  }

  return query;
};