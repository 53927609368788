// React and related
import React from 'react';
import { Query, ApolloProvider } from 'react-apollo';
import { ApolloClient } from "apollo-client";
import gql from "graphql-tag";

// Third party
import moment from 'moment';

// Components
import BarOrderStatus from '../BarOrderStatus/BarOrderStatus';
import MapBox from '../Mapbox/Mapbox';
import Spinner from '../Spinner/Spinner';
import TrackingList from './TrackingsList';

// Config and constants
import { apolloSecureConfig } from '../../support/settings/apollo';
import {
    DELIVERY_METHOD_PLANS,
    SLUG,
    TRACKING_STATUSES,
    TRACKING_STATUSES_BAR,
    TRACKING_STATUSES_TITLE,
    VIEW_BAR_STATUS
} from '../../constants';

import './style.scss';
const client = new ApolloClient(apolloSecureConfig);
const GET_COORDS = gql`
    query getCoords($orderId: Int!) {
        getCoords(orderId: $orderId) {
            longitude
            latitude
       }
     }
`;


const Tracking = (props) => {
    const { OrderId, OrderName, Slug, Status, TrackingNumber, TrackingUrl, BookingDate, PickupDate, EstimateDelivery, DeliveryMethod, warningText, CheckPoints, SignedBy, Address, TrackingStatus } = props.trackingData;
    return (
        <ApolloProvider client={client}>
            <section className="order-tracking col-12 col-lg-10 offset-lg-1 mt-5">
                <div className="row">
                    <div className="col-12 col-md-5 px-0 mb-3">
                        <div className="col-12 id">
                            <div className="row">
                                <div className="col-1 px-0 col-md-2 d-flex justify-content-center justify-content-md-end px-md-2">
                                    {warningText ? <div className="circle active expire"></div> : null}
                                </div>
                                <div className="col-11 col-md-10 px-0">ORD_{OrderId}</div>
                            </div>
                        </div>
                        <div className="col-11 col-md-10 offset-1 offset-md-2 px-0 quote mt-2">
                            {OrderName}
                        </div>
                    </div>
                    <div className="col-12 col-md-7 px-0 order-first order-md-last mb-3">
                        <div className="col-12 px-lg-3 mx-lg-4">
                            <BarOrderStatus status={Status} warningText={Status === TRACKING_STATUSES.Exception || Status === TRACKING_STATUSES.AttemptFail ? true : false} view={VIEW_BAR_STATUS.tracking} SignedBy={SignedBy} title={TRACKING_STATUSES_TITLE} statusBar={TRACKING_STATUSES_BAR} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-5 mb-3">
                        <div className="row">
                            <div className="col-11 col-md-10 offset-1 offset-md-2 px-0 my-3">
                                {Slug && SLUG[Slug].image ? <img src={SLUG[Slug].image} alt="icon" /> : null}
                            </div>
                            <div className="col-11 col-md-10 px-0 offset-1 offset-md-2 order-details mb-3">
                                <p className="col-12 px-0">Tracking Number: <a href={TrackingUrl}>{TrackingNumber ? TrackingNumber : "Awaiting Number"}</a></p>
                                <p className="col-12 px-0">Booking date: {BookingDate ? moment(BookingDate).format("ddd, D MMM h:mma") : null}</p>
                                <p className="col-12 px-0">Pickup date: {PickupDate ? moment(PickupDate).format("ddd, D MMM h:mma") : null}</p>
                            </div>
                            <div className="col-12 delivery-method my-3">
                                <div className="row">
                                    <div className="col-1 px-0 col-md-2 d-flex justify-content-center justify-content-md-end px-md-2">
                                        {DeliveryMethod === DELIVERY_METHOD_PLANS.express ? <div className="icon-express"></div> : null}
                                    </div>
                                    <div className="col-11 col-md-10 px-0">{DeliveryMethod}</div>
                                </div>
                            </div>
                            <div className="col-11 col-md-10 offset-1 px-0 offset-md-2 delivery-date">
                                <p className="col-12 px-0 estimated">Estimated delivery:</p>
                                <p className="col-12 px-0 date">{EstimateDelivery ? moment(EstimateDelivery).format("ddd D MMM") : null}</p>
                            </div>
                            <Query query={GET_COORDS} variables={{ orderId: OrderId }}>
                                {
                                    ({ error, loading, data }) => {
                                        if (loading) {
                                            return (<Spinner size='small' />)
                                        }
                                        return data && data.getCoords ? (<div className="col-11 offset-1 px-0 col-md-10 offset-md-2 details my-4">
                                            <MapBox lat={Number(data.getCoords.latitude)} lng={Number(data.getCoords.longitude)} />
                                        </div>) : ''
                                    }
                                }
                            </Query>
                            <div className="col-11 offset-1 px-0 col-md-10 offset-md-2 details">
                                <p className="col-12">{Address}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-3 col-md-7 px-xl-0">
                        <div className="container px-md-3">
                            <div className="col-12 mt-md-5 mt-3">
                                <div className="row">
                                    <div className="col-12 col-md-12 mb-3 mt-md-3">
                                        <p className="col-12 px-0 title">Tracking History</p>
                                    </div>

                                    <TrackingList trackings={CheckPoints} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </ApolloProvider>
    );
}

export default Tracking;