import React from 'react';
import { withRouter } from "react-router-dom";
import { debounce } from 'lodash';
import { apolloSecureConfig } from '../../support/settings/apollo';
import gql from "graphql-tag";
import { ApolloClient } from "apollo-client";
import { ApolloProvider } from 'react-apollo';
import { Formik } from 'formik';
import { SelectInput } from '../FormElements/index.js';
import SavedQuoteForm from './SavedQuoteForm';
import NewQuoteForm from './NewQuoteForm';
import Spinner from '../Spinner/Spinner';
import { PRODUCT_BY_BINDING, PAPER_TYPE, QUOTES_TABS, ORDER_QUOTE } from "../../constants";
import Header from '../header/Header';
import './style.scss';

const queryString = require('query-string');
const client = new ApolloClient(apolloSecureConfig);

const GET_PRODUCTS = gql`
    query {
        products {
            ProductId
            Name
            SetupPrice
        }
    }
`;
const FIND_PAPER_WEIGHT = gql`
    query getPaperPropertiesByProductId($productName: String!,$paperType: String! ) {
        getPaperPropertiesByProductId(productName: $productName,paperType: $paperType) {
            Id
            Name
        }
    }
`;

const GET_SAVED_QUOTES = gql`
    query getQuotes($page: Int!, $filter: FilterQuote) {
        getQuotes(page:$page, filter: $filter) {
            content {
                OrderId,
                OrderTotal,
                DeliveryCost,
                ProductName,
                DateCreated,
                OrderName,
                DeliveryPlan,
                Quantity
            }
            totalElements,
            totalPages,
            last,
            first
       }
     }
`;
const SEARCH_QUOTES = gql`
query searchQuotes($quoteName: String!,$page: Int!) {
    searchQuotes(quoteName: $quoteName,page: $page) {
        content {
            OrderId,
            OrderTotal,
            ProductName,
            DateCreated,
            OrderName,
            DeliveryPlan,
            Quantity
            }
        totalElements,
        totalPages,
        last,
        first
    }
}
`;
const DELETE_ORDER = gql`
    query deleteOrder($orderId: Int!) {
        deleteOrder(orderId:$orderId)
     }
`;
const GET_QUOTE = gql`
    query getQuote($orderId: Int!) {
        getQuote(orderId: $orderId) {
            OrderId
            QuoteName
            ClientReference
            ProductId
            Quantity
            FinishedSizeWidth
            FinishedSizeHeight
            Pages
            FlatSizeWidth
            FlatSizeHeight
            PrintSpecType
            PrintSpecTypeCover
            PaperId
            PaperType
            CoverPaperType
            CoverPaperId
            LaminationFrontId
            LaminationBackId
            LaminationCoverId
            LaminationCoverFrontId
            LaminationCoverBackId
            IsVattable
            deliveryMethod
            DeliveryCost
       }
     }
`;

class Quotes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedProduct: null,
            paperProperties: [],
            products: [],
            selectedPaperProperty: null,
            activeProduct: false,
            productsByBinding: PRODUCT_BY_BINDING,
            editQuote: {},
            page: 0,
            quotes: {},
            filter: { OrderId: ORDER_QUOTE.desc },
            searchQuoteName: '',
            hasErrors: []
        }
        this.newQuoteRef = React.createRef();
        this.getProductSubscription = {};
        this.quotesSubscription = {};
        this.editQuoteSubscription = {};
        this.searchQuotesDebounced = debounce(this.onSearchQuotes, 500);
    }


    componentDidMount() {
        const { filter } = this.state;
        const parsed = queryString.parse(this.props.location.search);
        if (parsed.search && parsed.search.length) {
            client.query({
                query: SEARCH_QUOTES,
                variables: { quoteName: parsed.search, page: parsed.page ? Number(parsed.page) - 1 : 0 },
                fetchPolicy: 'network-only'
            }).then(response => {
                const { searchQuotes } = response.data;
                this.setState({
                    quotes: searchQuotes,
                    searchQuoteName: parsed.search,
                    page: parsed.page ? Number(parsed.page) - 1 : 0
                })
            })
        } else {
            if (parsed.page) {
                client.query({
                    query: GET_SAVED_QUOTES,
                    variables: { page: parsed.page ? Number(parsed.page) - 1 : 0, filter },
                    fetchPolicy: 'network-only'
                }).then(response => {
                    const { getQuotes } = response.data;
                    this.setState({
                        quotes: getQuotes,
                        page: parsed.page ? Number(parsed.page) - 1 : 0
                    })
                });
            } else {
                this.onGetListQuotes()
            }
        }
        client.query({
            query: GET_PRODUCTS,
        }).then((response) => {
            const { products } = response.data;
            if (this.props.orderId) {

            } else {
                this.findPaperWeight(products);
            }
            this.setState({
                products
            });
            if (this.props.orderId) {
                this.getQuoteParameters(products);
            }
        })
    }
    componentWillUnmount() {
        this.onResetState();
        if (Object.keys(this.getProductSubscription).length) {
            this.getProductSubscription.unsubscribe();
        }
        if (Object.keys(this.quotesSubscription).length) {
            this.quotesSubscription.unsubscribe();
        }
        if (Object.keys(this.editQuoteSubscription).length) {
            this.editQuoteSubscription.unsubscribe();
        }
    }
    onResetState = () => {
        this.setState({
            selectedProduct: null,
            paperProperties: [],
            products: [],
            selectedPaperProperty: null,
            activeProduct: false,
            productsByBinding: PRODUCT_BY_BINDING,
            editQuote: {},
            hasErrors: []
        });
    }
    onResetStateSavedQuotes = () => {
        this.setState({
            page: 0,
            quotes: {},
            filter: { OrderId: ORDER_QUOTE.desc }
        })
    }

    getQuoteParameters = (products) => {
        const { orderId } = this.props;
        this.editQuoteSubscription = client.watchQuery({
            query: GET_QUOTE,
            variables: { orderId: Number(orderId) },
            fetchPolicy: 'network-only'
        }).subscribe({
            next: ({ data }) => {
                if (data) {
                    const newQuote = { ...data.getQuote };
                    const selectedProduct = products.find(product => Number(product.ProductId) === Number(newQuote.ProductId));
                    if (selectedProduct) {
                        this.findPaperWeightByProductId(selectedProduct, newQuote.PaperId);
                        this.setState({
                            selectedProduct: selectedProduct ? selectedProduct : this.state.selectedProduct,
                            editQuote: newQuote,
                            activeProduct: true,
                            productsByBinding: PRODUCT_BY_BINDING
                        })
                    }
                    else this.props.history.push('/error')
                }
            },
            error: (error) => { console.log('🚨 error.graphQLErrors = ', error.graphQLErrors); this.createError(error.graphQLErrors) }
        })
    }
    createError = (errors) => {
        const errorsMaped = errors && errors.length ? errors.map(error => error.message) : [];
        this.setState({
            hasErrors: errorsMaped
        });
    }
    onDisplayErrors = (errors) => {
        return errors.map((error, index) => {
            return <p key={index}>{error}</p>
        })
    }

    findPaperWeight = (products) => {
        client.query({
            query: FIND_PAPER_WEIGHT,
            variables: {
                productName: products[0].Name,
                paperType: PAPER_TYPE[0].value
            }
        }).then((response) => {
            const { getPaperPropertiesByProductId } = response.data;
            this.setState({
                paperProperties: getPaperPropertiesByProductId,
                selectedPaperProperty: { name: getPaperPropertiesByProductId[0].Name, value: getPaperPropertiesByProductId[0].Id },
                selectedProduct: products.length ? products[0] : {}
            })
        })
    }

    findPaperWeightByProductId = (selectedProduct, paperId) => {
        client.query({
            query: FIND_PAPER_WEIGHT,
            variables: {
                productName: selectedProduct.Name,
                paperType: PAPER_TYPE[0].value
            }
        }).then((response) => {
            const { getPaperPropertiesByProductId } = response.data;
            const findedPaperWeight = getPaperPropertiesByProductId.find(paper => Number(paper.Id) === Number(paperId));
            this.setState({
                paperProperties: getPaperPropertiesByProductId,
                selectedPaperProperty: findedPaperWeight ? { name: findedPaperWeight.Name, value: findedPaperWeight.Id } : { name: getPaperPropertiesByProductId[0].Name, value: getPaperPropertiesByProductId[0].Id }
            })
        })
    }
    findPaperWeightByProduct = (selectedProduct) => {
        const { products } = this.state;
        client.query({
            query: FIND_PAPER_WEIGHT,
            variables: {
                productName: selectedProduct.Name,
                paperType: PAPER_TYPE[0].value
            }
        }).then((response) => {
            const { getPaperPropertiesByProductId } = response.data;
            this.setState({
                paperProperties: getPaperPropertiesByProductId,
                selectedPaperProperty: { name: getPaperPropertiesByProductId[0].Name, value: getPaperPropertiesByProductId[0].Id },
                selectedProduct: products.length ? products[0] : {}
            })
        })
    }


    onFormatProducts = (products) => {
        let formatedProducts = []
        if (products && products.length) {
            products.forEach((element) => {
                formatedProducts.push({
                    name: element.Name,
                    value: element.ProductId
                })
            })
        }
        return formatedProducts;
    }

    onChangeProduct = (productId) => {
        const { products } = this.state;
        const { orderId } = this.props;
        const findedProduct = products.find(product => product.ProductId === productId);
        client.query({
            query: FIND_PAPER_WEIGHT,
            variables: {
                productName: findedProduct.Name,
                paperType: PAPER_TYPE[0].value
            }
        }).then((response) => {
            const { getPaperPropertiesByProductId } = response.data;
            this.setState({
                paperProperties: getPaperPropertiesByProductId
            })
        })
        this.setState({
            selectedProduct: findedProduct ? findedProduct : {},
            activeProduct: true,
            productsByBinding: PRODUCT_BY_BINDING,
            editQuote: orderId ? {} : this.state.editQuote
        })
    }
    onSaveProducts = (data) => {
        const { products } = data;
        client.query({
            query: FIND_PAPER_WEIGHT,
            variables: {
                productName: products[0].Name,
                paperType: PAPER_TYPE[0].value
            }
        }).then((response) => {
            const { getPaperPropertiesByProductId } = response.data;
            this.setState({
                paperProperties: getPaperPropertiesByProductId,
                selectedPaperProperty: { name: getPaperPropertiesByProductId[0].Name, value: getPaperPropertiesByProductId[0].Id },
                selectedProduct: products.length ? products[0] : {}
            })
        })
        this.setState({
            products
        })
    }
    onResetBinding = () => {
        this.setState({
            productsByBinding: []
        })
    }
    onChangeSelectedProduct = (binding) => {
        const { products } = this.state;
        const productBinding = PRODUCT_BY_BINDING.find(product => product.bindingName === binding);
        const findedProduct = products.find(product => product.Name === productBinding.productName);
        this.setState({
            selectedProduct: findedProduct ? findedProduct : {}
        })
    }
    onFormatSelectedProduct = (selectedProduct) => {
        return { name: this.state.selectedProduct.Name, value: this.state.selectedProduct.ProductId }
    }

    onChangeEditQuote = (quote) => {
        const newQuote = { ...quote };
        this.getProductSubscription = client.watchQuery({
            query: GET_PRODUCTS,
            fetchPolicy: 'cache-and-network'
        }).subscribe({
            next: ({ data }) => {
                if (data) {
                    const { products } = data;
                    this.setState({
                        products
                    })
                    const selectedProduct = products.find(product => Number(product.ProductId) === Number(quote.ProductId));
                    this.findPaperWeightByProductId(selectedProduct, quote.PaperId);
                    this.props.changeTab(QUOTES_TABS.new);
                    this.setState({
                        selectedProduct: selectedProduct ? selectedProduct : this.state.selectedProduct,
                        editQuote: newQuote,
                        activeProduct: true,
                        productsByBinding: PRODUCT_BY_BINDING
                    })
                }
            },
            error: (e) => console.error(e)
        })
    }

    onChangeTab = (tabName) => {
        this.props.changeTab(tabName);
        if (tabName === QUOTES_TABS.saved) {
            this.onGetListQuotes();
            this.onResetState();
        } else {
            client.query({
                query: GET_PRODUCTS,
            }).then((response) => {
                const { products } = response.data;
                this.findPaperWeight(products);
                this.setState({
                    products
                })
            })
            this.onResetStateSavedQuotes();
        }
    }

    saveQuoteToState = data => {
        const { getQuotes } = data;
        this.setState({
            quotes: getQuotes
        })
    }

    onGetListQuotes = () => {
        const { page, pageSize, filter } = this.state;
        this.quotesSubscription = client.watchQuery({
            query: GET_SAVED_QUOTES,
            variables: { page, pageSize, filter },
            fetchPolicy: 'cache-and-network'
        }).subscribe({
            next: ({ data }) => {
                if (data) {
                    this.saveQuoteToState(data);
                }
            },
            error: (e) => console.error(e)
        })
    }

    onOrderQuote = () => {
        const { page, pageSize, filter } = this.state;
        this.setState(
            prevState => {
                const newFilter = { ...this.state.filter, OrderId: prevState.filter.OrderId === ORDER_QUOTE.desc ? ORDER_QUOTE.asc : ORDER_QUOTE.desc }
                client.query({
                    query: GET_SAVED_QUOTES,
                    variables: { page, pageSize, filter: newFilter }
                }).then(response => {
                    this.saveQuoteToState(response.data);
                })
                return { ...this.state, filter: newFilter }
            }
        )
        client.query({
            query: GET_SAVED_QUOTES,
            variables: { page, pageSize, filter }
        }).then(response => {
            this.saveQuoteToState(response.data);
        })
    }
    onRemoveQuote = orderId => {
        const { quotes } = this.state;
        const filteredQuote = quotes.content.filter(quote => quote.OrderId !== orderId);
        this.setState({ quotes: { ...quotes, content: filteredQuote } });

        client.query({
            query: DELETE_ORDER,
            variables: { orderId }
        }).then(response => {
            console.log('ℹ️ ', response)
        })
    }
    onChangeSearchQuoteName = (event) => {
        this.setState({
            searchQuoteName: event.target.value
        });
        this.searchQuotesDebounced()
    }
    onSearchQuotes = () => {
        const { searchQuoteName } = this.state;
        this.props.history.push(`?search=${searchQuoteName}`)
        client.query({
            query: SEARCH_QUOTES,
            variables: { quoteName: searchQuoteName, page: 0 }
        }).then(response => {
            const { searchQuotes } = response.data;
            this.setState({
                quotes: searchQuotes,
                page: 0
            })
        })
    }

    onChangePage = (page) => {
        const { searchQuoteName } = this.state;
        const { filter } = this.state;
        if (searchQuoteName && searchQuoteName.length) {
            client.query({
                query: SEARCH_QUOTES,
                variables: { quoteName: searchQuoteName, page }
            }).then(response => {
                const { searchQuotes } = response.data;
                this.setState({
                    quotes: searchQuotes,
                    page
                })
            });
            this.props.history.push(`?page=${page + 1}&search=${searchQuoteName}`)
        } else {
            client.query({
                query: GET_SAVED_QUOTES,
                variables: { page, filter }
            }).then(response => {
                const { getQuotes } = response.data;
                this.setState({
                    quotes: getQuotes,
                    page
                })
            });
            this.props.history.push(`?page=${page + 1}`)
        }
    }

    render() {
        const { selectedProduct, paperProperties, selectedPaperProperty, productsByBinding, products, editQuote, quotes, filter, hasErrors, searchQuoteName } = this.state;
        const saveQuoteFilter = this.props.activeTab === QUOTES_TABS.saved ? true : false;
        return (
            <ApolloProvider client={client}>
                <section className="quote-list">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="header-bar xddd sticky-top col-12">
                                <div className="row">
                                    <Header locationPathName={this.props.location.pathname} />
                                    {hasErrors && hasErrors.length ? null : (
                                        <div className="col-12 submenu">
                                            <div className="row">
                                                <div className={`col-12 col-md-4 col-lg-4 px-lg-0 col-xl-3 mt-3 mt-md-0 order-last order-md-first d-flex align-items-center ${saveQuoteFilter ? 'hide' : ''}`}>
                                                    <div className="container-fluid">
                                                        <div className="row">
                                                            <Formik>
                                                                {products && products.length ? (
                                                                    <SelectInput
                                                                        groupStyle='col-12 m-md-0'
                                                                        label=''
                                                                        labelClass='col-12'
                                                                        fieldClass='select'
                                                                        selectedOption={{ name: products[0].Name, value: products[0].ProductId }}
                                                                        options={this.onFormatProducts(products)}
                                                                        optionClass=''
                                                                        name='ProductId'
                                                                        changeSelect={this.onChangeProduct}
                                                                        active={false}
                                                                        itemValue={selectedProduct ? selectedProduct.ProductId : null}
                                                                    />
                                                                ) : (<Spinner size='small' />)}
                                                            </Formik>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`col-11 col-md-5 px-md-0 col-lg-7 offset-lg-1 col-xl-6 offset-xl-2 ${saveQuoteFilter ? 'offset-md-1 offset-lg-1' : ''}`}>
                                                    <div className={`nav col-10 offset-1 col-md-11 offset-md-1 col-lg-6 col-xl-5 offset-lg-0 ${saveQuoteFilter ? 'offset-lg-5 offset-xl-6' : ''} d-flex justify-content-between`}>
                                                        <button className={`submenu-tab mt-2 pt-2 mt-md-0 py-md-4 ${this.props.activeTab === QUOTES_TABS.new ? "active" : ""}`} id={QUOTES_TABS.new} onClick={() => this.onChangeTab(QUOTES_TABS.new)}>New quote </button>
                                                        <button className={`submenu-tab mt-2 pt-2 mt-md-0 py-md-4 ${this.props.activeTab === QUOTES_TABS.saved ? "active" : ""}`} id={QUOTES_TABS.saved} onClick={() => this.onChangeTab(QUOTES_TABS.saved)}> Saved quotes</button>
                                                    </div>
                                                </div>
                                                <div className={`col-12 col-md-5 offset-md-1 col-lg-4 col-xl-3 offset-xl-1 offset-lg-0 py-3 d-flex align-items-center justify-content-center justify-content-lg-end order-last ${!saveQuoteFilter ? 'hide' : ''} `}>
                                                    <div className={`search-bar col-11 col-lg-10 d-flex align-items-center justify-content-between`}>
                                                        <input onChange={(event) => this.onChangeSearchQuoteName(event)} type="text" name="search" className="col-11" defaultValue={searchQuoteName} />
                                                        <i className="fa fa-search"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {hasErrors && hasErrors.length ? (<section className="col-lg-12 mt-5 section-error">{this.onDisplayErrors(hasErrors)}</section>) : (
                                <React.Fragment>
                                    {this.props.activeTab === QUOTES_TABS.new && <NewQuoteForm ref={this.newQuoteRef} editQuote={editQuote} paperProperties={paperProperties} selectedProduct={selectedProduct} changeSelectedProduct={this.onChangeSelectedProduct} selectedPaperProperty={selectedPaperProperty} productsByBinding={productsByBinding} resetBinding={this.onResetBinding} changeActiveTab={() => this.onChangeTab(QUOTES_TABS.saved)} />}
                                    {this.props.activeTab === QUOTES_TABS.saved && <SavedQuoteForm quotes={quotes} filter={filter} orderQuote={this.onOrderQuote} removeQuote={this.onRemoveQuote} changeEditQuote={this.onChangeEditQuote} changePage={this.onChangePage} currentPage={this.state.page} />}
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </section >
            </ApolloProvider>
        );
    }
}

export default withRouter(Quotes);