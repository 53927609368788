import React from 'react';
import Quotes from './Quotes';
import { withRouter } from 'react-router';
import { QUOTES_TABS } from '../../constants';

class EditQuoteContainer extends React.Component {
    constructor(props) {
        super(props)
        const currentPathName = props.location.pathname.replace("/", "");
        this.state = { activeTabID: currentPathName.includes(QUOTES_TABS.new) ? QUOTES_TABS.new : QUOTES_TABS.saved }
    }
    changeTab = (id) => {
        this.setState({ activeTabID: id });
        this.props.history.push("/" + id);
    }
    render() {
        const { activeTabID } = this.state;
        return (
            <Quotes orderId={this.props.orderId} activeTab={activeTabID} changeTab={this.changeTab} />
        );
    }
}
export default withRouter(EditQuoteContainer);