import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { formatDate } from "../../libs/utils";
import {
  DELIVERY_METHOD_PLANS,
  ORDER_STATUSES_TITLE,
  ORDER_STATUSES_BAR,
  ORDER_QUOTE,
} from "../../constants";
import "./style.scss";
import Pagination from "../Pagination/Pagination";

const arrowAsc = require("../../assets/img/quotes/arrow-asc.svg");
const arrowDesc = require("../../assets/img/quotes/arrow-desc.svg");

const renderBarOrdeStatus = (orderStatus, warningText) => {
  const findedIndexOrder = ORDER_STATUSES_BAR.findIndex(
    (order) => order.orderStatus === orderStatus
  );
  const formatedOrderBar = ORDER_STATUSES_BAR.map((order, index) => {
    if (index < findedIndexOrder) {
      return { ...order, line: true, circle: true };
    } else if (index === findedIndexOrder) {
      return { ...order, circle: true, expire: warningText ? true : false };
    } else {
      return order;
    }
  });
  return formatedOrderBar.map((order, index) => {
    return (
      <div className={"block-bar"} key={index}>
        <div
          className={`circle ${order.circle ? "active" : ""} ${order.expire ? "expire" : ""
            }`}
        ></div>
        {index < formatedOrderBar.length - 1 ? (
          <div className={`line ${order.line ? "active" : ""}`}></div>
        ) : (
          ""
        )}
      </div>
    );
  });
};

const renderOrderList = (orders, props) => {
  if (orders.content && orders.content.length) {
    return orders.content.map((order) => {
      const totalPriceUnit = Math.round(order.OrderTotal) + order.DeliveryCost;
      return (
        <div
          className="row mb-3 job"
          key={order.OrderId}
          onClick={() => props.history.push("/editorder/" + order.OrderId)}
        >
          <div className="col-md-2 d-none d-md-inline-block">
            <div className="row">
              <div className="col-4 d-flex justify-content-end align-items-center">
                {order.DeliveryPlan === DELIVERY_METHOD_PLANS.express ? (
                  <div className="icon-express"></div>
                ) : null}{" "}
              </div>
              <div className="col-6 px-0">{order.OrderId}</div>
            </div>
          </div>
          <div className="col-4 d-inline-block d-md-none">
            <div className="row">
              <div className="col-6 d-flex justify-content-end align-items-center">
                {order.DeliveryPlan === DELIVERY_METHOD_PLANS.express ? (
                  <div className="icon-express"></div>
                ) : null}{" "}
              </div>
              <div className="col-6 px-0">ORD{order.OrderId}</div>
            </div>
          </div>
          <div className="col-6 col-md-3">
            {order.OrderName ? order.OrderName : ""}
          </div>
          <div className="col-2 d-none d-md-inline-block">
            £{totalPriceUnit}
          </div>
          <div className="col-2 px-0 d-none d-md-inline-block">
            <div
              className={`${order.warningText ? "col-12 px-0 expire" : "col-12 px-0"
                }`}
            >
              {order.warningText
                ? order.warningText
                : ORDER_STATUSES_TITLE[order.OrderStatus].title
                  ? ORDER_STATUSES_TITLE[order.OrderStatus].title
                  : ""}
            </div>
            <div className="col-12 px-0 bar my-2">
              {renderBarOrdeStatus(order.OrderStatus, order.warningText)}
            </div>
          </div>
          <div className="col-2 px-0 d-none d-md-inline-block">
            <div className="col-12 px-0">
              {formatDate(order.DespatchDateTime, "ddd, D MMM YYYY")}
            </div>
            <div className="col-12 px-0 details my-2">
              {formatDate(order.DespatchDateTime, "h:mma")}
            </div>
          </div>
          <div className="col-1 d-flex justify-content-start align-items-baseline">
            <button
              type="button"
              className={`button-edit float-right ${order.warningText ? "expire" : ""
                }`}
            >
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.3779 0.0263672C4.96379 0.0263672 0.559082 4.41927 0.559082 9.81883C0.559082 15.2184 4.96379 19.6113 10.3779 19.6113C15.7919 19.6113 20.1967 15.2184 20.1967 9.81883C20.1967 4.41927 15.7919 0.0263672 10.3779 0.0263672ZM10.3779 14.7151V10.7981H5.46848V8.83959H10.3779V4.9226L15.2873 9.81883L10.3779 14.7151Z"
                  fill="var(--faded)"
                />
              </svg>
            </button>
          </div>
        </div>
      );
    });
  } else {
    return (
      <tr key="emptyOrders">
        <td>🤔 You don't have any orders</td>
      </tr>
    );
  }
};

const OrderList = (props) => {
  const { orders } = props;
  const [orderListState, changOrderState] = useState({
    dispatchedSort: ORDER_QUOTE.desc,
  });

  const onChangeOrderState = () => {
    const newDespatchedSort =
      orderListState.dispatchedSort === ORDER_QUOTE.asc
        ? ORDER_QUOTE.desc
        : ORDER_QUOTE.asc;
    changOrderState({ dispatchedSort: newDespatchedSort });
    props.changedDespatchedSort(newDespatchedSort);
  };

  return (
    <section className="order-list col-12 mt-5">
      <div className="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
        <div className="table col-12">
          <div className="thead row mb-4">
            <div className="col-3 col-md-2 d-none d-md-inline-block">
              <div className="col-9 px-2 float-right">Order #</div>
            </div>
            <div className="col-4 d-inline-block d-md-none">
              <div className="col-6 px-0 float-right">ID</div>
            </div>
            <div className="col-6 col-md-3">Order Name</div>
            <div className="col-2 d-none d-md-inline-block">Order Total</div>
            <div className="col-2 px-0 d-none d-md-inline-block">Progress</div>
            <div
              className="col-2 d-none d-md-inline-block px-0"
              onClick={() => onChangeOrderState()}
            >
              Dispatch Date
              <img
                src={
                  orderListState.dispatchedSort === ORDER_QUOTE.asc
                    ? arrowAsc
                    : arrowDesc
                }
                className="img-fluid pl-1"
                alt="icon"
              />
            </div>
          </div>
          <div className="col-12 px-0">{renderOrderList(orders, props)}</div>
        </div>
      </div>
      <div className="col-12 mt-4 d-flex justify-content-center">
        <Pagination
          totalPages={orders.totalPages}
          currentPage={props.currentPage}
          onPageChange={props.changePage}
        />
      </div>
    </section>
  );
};

export default withRouter(OrderList);
