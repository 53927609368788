// External dependencies
import React, { useEffect } from 'react';
import moment from 'moment';
import gql from "graphql-tag";
import { ApolloClient } from "apollo-client";
import { ApolloProvider, Query } from 'react-apollo';

// Internal dependencies
import { apolloSecureConfig } from '../../support/settings/apollo';
import {
    ORDER_STATUSES_BAR,
    ORDER_STATUSES_TITLE,
    DELIVERY_METHOD_PLANS,
    VIEW_BAR_STATUS
} from '../../constants';
import BarOrderStatus from '../BarOrderStatus/BarOrderStatus';
import MapBox from '../Mapbox/Mapbox';
import Spinner from '../Spinner/Spinner';
import JobList from './JobList';
// Styles
import './style.scss';
const client = new ApolloClient(apolloSecureConfig);

const GET_COORDS = gql`
    query getCoords($orderId: Int!) {
        getCoords(orderId: $orderId) {
            longitude
            latitude
       }
     }
`;

const OrderDetail = (props) => {
    const { OrderId, OrderName, POReference, DateCreated, deliveryMethod, OrderTotal, DeliveryCost, jobs, OrderStatus, warningText, estimateDelivery } = props.orderData;

    useEffect(() => {
        props.resetCurrentJob();
    }, [])

    return (
        <ApolloProvider client={client}>
            <section className="order-detail col-12 col-xl-10 offset-xl-1 mt-5">
                <div className="row">
                    <div className="col-12 col-md-5">
                        <div className="col-12 id">
                            <div className="row">
                                <div className="col-1 col-md-2 px-0 d-flex justify-content-center align-items-center justify-content-lg-end">
                                    {warningText ? <div className="circle active expire"></div> : null}
                                </div>
                                <span className="col-11 col-md-10">ORD_{OrderId}</span>
                            </div>
                        </div>
                        <div className='col-11 offset-1 col-md-10 offset-md-2 quote mt-2'>{OrderName}</div>
                    </div>
                    <div className="col-12 col-md-7 mb-5 mb-md-0 order-first order-md-last">
                        <div className="col-12 px-0">
                            <BarOrderStatus status={OrderStatus} warningText={warningText} view={VIEW_BAR_STATUS.order} title={ORDER_STATUSES_TITLE} statusBar={ORDER_STATUSES_BAR} />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-5 mb-3">
                        <div className="col-11 offset-1 col-md-10 offset-md-2 order-details mt-4 mb-3">
                            <div className="row">
                                <p className="col-12">PO Reference: {POReference}</p>
                                <p className="col-12">Order Date: {moment(Number(DateCreated)).format("ddd, D MMM")}</p>
                            </div>
                        </div>
                        <div className="col-12 delivery-method my-3">
                            <div className="row">
                                <div className='col-1 col-md-2 px-0 d-flex justify-content-center justify-content-md-end px-md-3'>
                                    <div className="row">
                                        {deliveryMethod === DELIVERY_METHOD_PLANS.express ? <div className="icon-express"></div> : null}
                                    </div>
                                </div>
                                <span className="col-11 col-md-10">{deliveryMethod}</span>
                            </div>
                        </div>
                        <div className="col-11 offset-1 col-md-10 offset-md-2 delivery-date">
                            <p className="estimated">Estimated delivery:</p>
                            <p className="date">{estimateDelivery ? moment(estimateDelivery).format("ddd D MMM") : null}</p>
                        </div>

                        <Query query={GET_COORDS} variables={{ orderId: OrderId }}>
                            {
                                ({ error, loading, data }) => {
                                    if (loading) {
                                        return (<Spinner size='small' />)
                                    }
                                    return data && data.getCoords ? (<div className="col-11 offset-1 col-md-10 offset-md-2 details my-4">
                                        <MapBox lat={Number(data.getCoords.latitude)} lng={Number(data.getCoords.longitude)} />
                                    </div>) : ''
                                }
                            }
                        </Query>
                        {OrderTotal ? (
                            <div className="col-11 offset-1 col-md-10 offset-md-2 details my-4">
                                <div className="row">
                                    <p className="col-12">Total: <span className="order-price">£{Math.round(OrderTotal) + DeliveryCost}</span></p>
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div className="col-11 offset-1 mb-3 col-md-7 offset-md-0 mt-md-3 px-xl-0">
                        <div className="row">
                            <JobList jobs={jobs} orderId={OrderId} props={props} />
                        </div>
                    </div>
                </div>
            </section>
        </ApolloProvider>
    );
}

export default OrderDetail;