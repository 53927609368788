import React from 'react';
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import { ApolloClient } from "apollo-client";
import { ApolloProvider, Query } from 'react-apollo';
import { apolloSecureConfig } from '../support/settings/apollo';

import { FILTER_ORDERS_NAME } from '../constants';

import OrdersInProgressWidget from '../components/widgets/OrdersInProgressWidget/OrdersInProgressWidget';
import SavedQuotesWidget from '../components/widgets/SavedQuotesWidget/SavedQuotesWidget';
import CompletedOrdersWidget from '../components/widgets/CompletedOrdersWidget/CompletedOrdersWidget';
// import Printspiration from '../components/widgets/PrintspirationWidget/PrintspirationWidget';
import Spinner from '../components/Spinner/Spinner';
import Header from '../components/header/Header';

import './style.scss';


const client = new ApolloClient(apolloSecureConfig);

const GET_LOGGED_USER = gql`
    {
        user
    }
`;

const GET_ORDERS_DATA = gql`
    query getOrdersData {
        getOrdersData {
            totalCount
            production
            awaitingApproval
            awaitingApprovalToday
            invoiced
            estimated
            conversionRate
            countMonths {
                name
                count
                index
            }
        }
     }
`;

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            totalPages: 2,
            currentPage: 1
        }
    }
    onChangePage = index => {
        this.setState({
            currentPage: index + 1
        })
    }
    onChangePrevious = () => {
        this.setState({
            currentPage: this.state.currentPage > 1 ? this.state.currentPage - 1 : 1
        });
    }
    onChangeNext = () => {
        this.setState({
            currentPage: this.state.currentPage < this.state.totalPages ? this.state.currentPage + 1 : this.state.totalPages
        });
    }

    renderPagination = () => {
        const { totalPages, currentPage } = this.state;
        if (totalPages < 6) {
            return new Array(totalPages).fill(1).map((elem, index) => {
                return (
                    <li className={`page-item ${currentPage - 1 === index ? 'active' : ''}`} key={index}>
                        <a className="page-link d-flex justify-content-center align-items-center" onClick={() => this.onChangePage(index)}>{index + 1}</a>
                    </li>
                )
            })
        }
        else {
            return new Array(totalPages).fill(1).map((elem, index) => {
                if (index === 0 || (index === 1 && currentPage < 2) || index === currentPage || (currentPage === totalPages - 1 && index === totalPages - 2) || index === totalPages - 1) {
                    return (
                        <li className={`page-item ${currentPage - 1 === index ? 'active' : ''}`} key={index}>
                            <a className="page-link d-flex justify-content-center align-items-center" onClick={() => this.onChangePage(index)}>{index + 1}</a>
                        </li>
                    )
                }
                if (index === currentPage - 1 || (currentPage === totalPages - 1 && index === totalPages - 3)) {
                    return (
                        <li className={`page-item ${currentPage - 1 === index ? 'active' : ''}`} key={index}>
                            <a className="page-link d-flex justify-content-center align-items-center" onClick={() => this.onChangePrevious()}>...</a>
                        </li>
                    )
                }
                if (index === totalPages - 2) {
                    return (
                        <li className={`page-item ${currentPage - 1 === index ? 'active' : ''}`} key={index}>
                            <a className="page-link d-flex justify-content-center align-items-center" onClick={() => this.onChangeNext()}>...</a>
                        </li>
                    )
                }
            })
        }
    }
    render() {
        const { currentPage, totalPages } = this.state;
        return (
            <React.Fragment>
                <Header locationPathName={this.props.location.pathname} />
                <section className="home-section col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2 pt-lg-4">
                    <div className="row">
                        <ApolloProvider client={client}>
                            <Query fetchPolicy={'network-only'} query={GET_ORDERS_DATA}>
                                {
                                    ({ error, loading, data }) => {
                                        if (loading) {
                                            return (<Spinner size='small' />)
                                        }
                                        return data.getOrdersData ? (
                                            <React.Fragment>
                                                <div className="col-12 widget-list">
                                                    <Query query={GET_LOGGED_USER}>
                                                        {
                                                            ({ error, loading, data }) => {
                                                                if (loading) {
                                                                    return (<Spinner size='small' />)
                                                                }
                                                                return data.user ? (<p className="col-12 px-0 col-lg-7 text py-4">Hi {data.user && data.user.FirstName ? data.user.FirstName : ''},</p>) : null
                                                            }
                                                        }
                                                    </Query>
                                                    <p className="col-12 px-0 col-lg-8 text-description py-md-4">You have
                                                        <a href={`/orders?filter=${FILTER_ORDERS_NAME.production}`} className="order-number"> {data.getOrdersData.totalCount === 1 ? <span> {data.getOrdersData.totalCount} order in progress.</span> : <span> {data.getOrdersData.totalCount}  orders in progress.</span>}</a>
                                                        {data.getOrdersData.awaitingApproval ? <a href={`/orders?filter=${FILTER_ORDERS_NAME.needAction}`} className="expire"> {data.getOrdersData.awaitingApproval === 1 ?<span className="expire"> {data.getOrdersData.awaitingApproval} order need action.</span> : <span className="expire"> {data.getOrdersData.awaitingApproval} orders need action.</span>}</a> : ''}
                                                        {data.getOrdersData.awaitingApprovalToday ? <a href={`/orders?filter=${FILTER_ORDERS_NAME.needAction}`} className="expire"><span className="expire"> {data.getOrdersData.awaitingApprovalToday} needs actioning today.</span></a> : null}
                                                    </p>
                                                    <div className={`col-12 py-md-4 mt-md-4 widgets-slider ${currentPage === 1 ? 'active' : ''}`}>
                                                        <div className="row d-flex justify-content-center justify-content-md-between">
                                                            <div className="widget py-3 py-lg-4">
                                                                <OrdersInProgressWidget productionData={{ totalCount: data.getOrdersData.totalCount, production: data.getOrdersData.production, awaitingApproval: data.getOrdersData.awaitingApproval }} />
                                                            </div>
                                                            <div className="widget py-3 py-lg-4">
                                                                <SavedQuotesWidget count={data.getOrdersData.estimated} conversionRate={data.getOrdersData.conversionRate} />
                                                            </div>
                                                            <div className="widget py-3 py-lg-4">
                                                                <CompletedOrdersWidget jobData={{ invoiced: data.getOrdersData.invoiced, countMonths: data.getOrdersData.countMonths }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </React.Fragment>
                                        ) : null
                                    }
                                }
                            </Query>
                        </ApolloProvider>
                    </div >
                </section >
            </React.Fragment>
        );
    }
}
export default withRouter(Home);