import React from 'react';
import { JOB_STATUSES_TITLE } from '../../constants';

const renderJobList = ({ jobs, orderId, deepLink = false, props }) => {
   const handleJobClick = (index) => {
      // Use the history prop from react-router-dom
      // props.history.push(`/editorder/${orderId}?tab=job&jobIndex=${index}`);

      if (deepLink) {
         props.history.push(`/editorder/${orderId}?tab=job&jobIndex=${index}`);
      } else {
         props.onChangeActiveTab();
         props.changeActiveJobIndex(index)
      }
   }

   return jobs && jobs.length > 0 ? jobs.map((job, index) => {
      return (
         <div className="row mb-3 job" key={index} onClick={() => { handleJobClick(index) }}>
            <div className="col-2 col-md-2 px-0">{job.JobId}</div>
            <div className="col-6 pr-0 col-md-3">{job.JobName ? job.JobName : ''}</div>
            <div className="col-2 d-none d-md-inline-block">{job.Quantity}</div>
            <div className="col-2 col-md-4">
               <div className="row">
                  <div className="col-5 col-md-2 px-0 d-flex justify-content-end">
                     <div className={`circle active ${job.warningText ? 'expire' : ''}`}></div>
                  </div>
                  <div className={`col-10 pr-md-0 d-none d-md-inline-block ${job.warningText ? 'expire' : ''}`}>{job.warningText ? job.warningText : JOB_STATUSES_TITLE[job.JobStatus].title}</div>
               </div>
            </div>
            <div className="col-1 d-flex justify-content-md-end align-items-baseline">
               <button type="button" className={`button-edit ${job.warningText ? 'expire' : ''}`}>
                  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M10.3779 0.0263672C4.96379 0.0263672 0.559082 4.41927 0.559082 9.81883C0.559082 15.2184 4.96379 19.6113 10.3779 19.6113C15.7919 19.6113 20.1967 15.2184 20.1967 9.81883C20.1967 4.41927 15.7919 0.0263672 10.3779 0.0263672ZM10.3779 14.7151V10.7981H5.46848V8.83959H10.3779V4.9226L15.2873 9.81883L10.3779 14.7151Z" fill="var(--faded)" />
                  </svg>
               </button>
            </div>
         </div>
      )
   }) : null
}


const JobList = (jobs, orderId, props) => {
   return (
      <div className="table col-12 col-md-12">
         <div className="col-12 thead">
            <div className="row mb-3">
               <div className="col-2 col-md-2 px-0">Ref #</div>
               <div className="col-6 col-md-3">Name</div>
               <div className="col-2 d-none d-md-inline-block">Qty</div>
               <div className="col-4 col-md-4 d-none d-md-inline-block">
                  <div className="col-10 px-2 float-right">Status</div>
               </div>
            </div>
         </div>

         <div className="col-12">
            {renderJobList(jobs, orderId, props)}
         </div>
      </div>
   )
};

export default JobList;