import { LOCAL_STORAGE_NAME } from '../constants';
class UserService {
    saveToLocalStorage = (token) => {
        localStorage.setItem(LOCAL_STORAGE_NAME, JSON.stringify({ token }));
    }
    getTokenFromLocalStorage = () => {
        return localStorage.getItem(LOCAL_STORAGE_NAME) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_NAME)).token : null;
    }
    isAuth = () => {
        return localStorage.getItem(LOCAL_STORAGE_NAME) ? true : false;
    }
    deleteTokenFromLocalStorage = () => {
        localStorage.removeItem(LOCAL_STORAGE_NAME)
    }
  }

  export let userService = new UserService();