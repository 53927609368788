import React from 'react';
import { withRouter } from 'react-router-dom';
import { formatDate } from '../../libs/utils';
import { DELIVERY_STATUSES_TITLE, DELIVERY_STATUSES_BAR, ORDER_QUOTE } from '../../constants';
import Pagination from '../Pagination/Pagination';

const arrowAsc = require("../../assets/img/quotes/arrow-asc.svg");
const arrowDesc = require("../../assets/img/quotes/arrow-desc.svg");

const renderBarOrdeStatus = (deliveryStatus, warningText) => {
   const formatedOrderBar = DELIVERY_STATUSES_BAR.map((order, index) => {
      if (index < deliveryStatus) {
         return { ...order, line: true, circle: true };
      } else if (index === deliveryStatus) {
         return { ...order, circle: true, expire: warningText ? true : false };
      } else {
         return order;
      }
   });

   return formatedOrderBar.map((order, index) => {
      return (
         <div className={"block-bar"} key={index}>
            <div
               className={`circle ${order.circle ? "active" : ""} ${order.expire ? "expire" : ""
                  }`}
            ></div>

            {index < formatedOrderBar.length - 1 ? (
               <div className={`line ${order.line ? "active" : ""}`}></div>
            ) : (
               ""
            )}
         </div>
      );
   });
};

const renderShipmentList = (deliveries, props) => {
   if (deliveries.length) {
      return deliveries.map(delivery => (
         <div
            className="row mb-3 job"
            key={delivery.DeliveryId}
            onClick={() => props.history.push(`/shipment/${delivery.DeliveryId}`)}
         >
            <div className="col-5 col-md-2 d-inline-block">SHP_{delivery.DeliveryId}</div>
            <div className="col-2 d-none d-md-inline-block">ORD_{delivery.OrderId}</div>
            <div className={`col-3 d-none d-md-inline-block ${delivery.TrackingNumber ? "" : "text-faded"}`}>{delivery.TrackingNumber || 'None available'}</div>
            <div className="col-2 d-none d-md-inline-block">
               <div className={`${delivery.warningText ? "col-12 px-0 expire" : "col-12 px-0"}`}>
                  {delivery.warningText
                     ? delivery.warningText
                     : DELIVERY_STATUSES_TITLE[delivery.DeliveryStatus]
                        ? DELIVERY_STATUSES_TITLE[delivery.DeliveryStatus]
                        : ""}
               </div>
               <div className="col-12 px-0 bar my-2">
                  {renderBarOrdeStatus(delivery.DeliveryStatus, delivery.warningText)}
               </div>
            </div>
            <div className="col-5 col-md-2 d-inline-block">
               <div className="col-12 px-0">
                  {formatDate(delivery.ExpectedDespatchDateTime, "ddd, D MMM YYYY")}
               </div>
               <div className="col-12 px-0 details my-2">
                  {formatDate(delivery.ExpectedDespatchDateTime, "h:mma")}
               </div>
            </div>
            <div className="col-1 d-flex justify-content-start align-items-baseline">
               <button type="button" className="button-edit float-right">
                  <svg
                     width="21"
                     height="20"
                     viewBox="0 0 21 20"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                  >
                     <path
                        d="M10.3779 0.0263672C4.96379 0.0263672 0.559082 4.41927 0.559082 9.81883C0.559082 15.2184 4.96379 19.6113 10.3779 19.6113C15.7919 19.6113 20.1967 15.2184 20.1967 9.81883C20.1967 4.41927 15.7919 0.0263672 10.3779 0.0263672ZM10.3779 14.7151V10.7981H5.46848V8.83959H10.3779V4.9226L15.2873 9.81883L10.3779 14.7151Z"
                        fill="var(--faded)"
                     />
                  </svg>
               </button>
            </div>
         </div>
      ));
   } else {
      return (
         <div key="emptyShipments">
            <div><span role="img" aria-label="thinking">🤔</span> You don't have any shipments</div>
         </div>
      );
   }
};

const ShipmentList = (props) => {
   const onChangeOrderState = () => {
      props.changedDespatchedSort();
   };

   return (
      <section className="order-list col-12 mt-5">
         <div className="col-12 col-lg-10 offset-lg-1 col-xl-8- offset-xl-2-">
            <div className="table col-12">
               <div className="thead row mb-4">
                  <div className="col-5 col-md-2 d-inline-block">Shipment #</div>
                  <div className="col-2 d-none d-md-inline-block">Order #</div>
                  <div className="col-3 d-none d-md-inline-block">Tracking Number</div>
                  <div className="col-2 d-none d-md-inline-block">Progress</div>
                  <div
                     className="col-5 col-md-2 px-0"
                     onClick={() => onChangeOrderState()}
                  >
                     Dispatch Date
                     <img
                        src={
                           props.dispatchedSort === ORDER_QUOTE.asc
                              ? arrowAsc
                              : arrowDesc
                        }
                        className="img-fluid pl-1"
                        alt="icon"
                     />
                  </div>
               </div>

               <div className="col-12 px-0">{renderShipmentList(props.deliveries, props)}</div>
            </div>
         </div>
         <div className="col-12 mt-4 d-flex justify-content-center">
            <Pagination
               totalPages={props.totalPages}
               currentPage={props.currentPage}
               onPageChange={props.changePage}
            />
         </div>
      </section>
   );
};

export default withRouter(ShipmentList);