// React and routing
import React from 'react';
import { withRouter } from "react-router-dom";

// Apollo/GraphQL
import { ApolloClient } from "apollo-client";
import { ApolloProvider, Query } from 'react-apollo';
import gql from "graphql-tag";
import { apolloSecureConfig } from '../../support/settings/apollo';

// Third party
import moment from 'moment';

// Components
import BarOrderStatus from '../BarOrderStatus/BarOrderStatus';
import Header from '../header/Header';
import Icon from '../Icons/Icon';
import MapBox from '../Mapbox/Mapbox';
import Spinner from '../Spinner/Spinner';
import TrackingList from '../orders/TrackingsList';
import JobList from '../orders/JobList';

// Constants
import { VIEW_BAR_STATUS, TRACKING_STATUSES, TRACKING_STATUSES_TITLE, TRACKING_STATUSES_BAR, SLUG, DELIVERY_METHOD_PLANS, SHIPMENT_TABS, DELIVERY_STATUSES_TITLE, DELIVERY_STATUSES_BAR } from '../../constants';
const client = new ApolloClient(apolloSecureConfig);

// Define GraphQL query to fetch shipment details
const GET_SHIPMENT = gql`
   query getDelivery($deliveryId: Int!) {
      getDelivery(deliveryId: $deliveryId) {
         DeliveryId
         OrderId
         DeliveryStatus
         ExpectedDespatchDateTime
         TrackingNumber
         ParcelCount
         IsDeliveryComplete
         SelectedCarrier
         TrackingNumber
         DeliveryMethod
         carrier {
            Name
         }
         estimateDelivery
         jobs {
            JobId
            JobName
            Quantity
            JobStatus
            warningText
         }
         tracking {
            Slug
            Status
            TrackingNumber
            TrackingUrl
            BookingDate
            PickupDate
            SignedBy
            CheckPoints {
               EventDate
               EventTime
               EventName
               EventLocation
               EventStatus
            }
         }
      }
   }
`;

const GET_COORDS = gql`
    query getCoords($orderId: Int!) {
        getCoords(orderId: $orderId) {
            longitude
            latitude
       }
     }
`;

class ShipmentDetailPage extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         activeTab: SHIPMENT_TABS.id,
         hasError: null,
         delivery: {}
      };
   }

   componentDidMount() {
      client.query({
         query: GET_SHIPMENT,
         variables: { deliveryId: Number(this.props.shipmentId) }
      }).then((response) => {
         const { getDelivery } = response.data;

         this.setState({
            delivery: getDelivery,
         });
      })
   }


   onDisplayErrors = (errors) => {
      return errors.map((error, index) => {
         return <p key={index}>{error.message}</p>
      });
   }

   render() {
      const { delivery } = this.state;
      const shipmentId = this.props.match.params.shipmentId;

      // Add validation to ensure shipmentId is a valid number
      const deliveryId = parseInt(shipmentId, 10);
      if (isNaN(deliveryId)) {
         return (
            <ApolloProvider client={client}>
               <section className="order-detail">
                  <div className="container-fluid">
                     <div className="row d-flex justify-content-center">
                        <div className="header-bar sticky-top col-12">
                           <div className="row">
                              <Header locationPathName={this.props.location.pathname} />

                              <div className="col-12 submenu">
                                 <div className="row">
                                    <div className="col-2 px-0 py-3 col-lg-1 d-flex align-items-center order-first">
                                       <div className="container-fluid">
                                          <div className="row d-flex justify-content-center">
                                             <button className="btn-back" onClick={() => this.props.history.goBack()}>
                                                <Icon name="back" />
                                                <span className="px-2 d-none d-md-inline-block">Back</span>
                                             </button>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="row mt-2 section-error">
                           <div className="col-lg-12">
                              <p>Invalid shipment ID provided</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
            </ApolloProvider>
         );
      }

      const onChangeActiveTab = (tab) => {
         this.setState({
            activeTab: tab
         })
      }

      return (
         <ApolloProvider client={client}>
            <div className="orders-section">
               <div className="header-bar sticky-top col-12">
                  <div className="row">
                     <Header locationPathName={this.props.location.pathname} />

                     <div className="col-12 submenu">
                        <div className="row">
                           <div className="col-2 px-0 py-3 col-lg-1 d-flex align-items-center order-first">
                              <div className="container-fluid">
                                 <div className="row d-flex justify-content-center">
                                    <button className="btn-back" onClick={() => this.props.history.goBack()}>
                                       <Icon name="back" />
                                       <span className="px-2 d-none d-md-inline-block">Back</span>
                                    </button>
                                 </div>
                              </div>
                           </div>

                           <div className="col-10 col-lg-6 d-flex justify-content-center">
                              <div className="nav col-12 col-md-10 offset-md-1 col-lg-12 offset-lg-4 col-xl-10 offset-xl-6">
                                 <div className="container px-0">
                                    <div className="row d-flex justify-content-center">
                                       <div className="col-4 px-0 col-md-3 col-lg-4 col-xl-3 text-center">
                                          <button
                                             className={`submenu-tab py-3 py-md-4 ${this.state.activeTab === SHIPMENT_TABS.id ? "active" : ""}`}
                                             id={SHIPMENT_TABS.id}
                                             onClick={() => (onChangeActiveTab(SHIPMENT_TABS.id))}>SHP_{delivery.DeliveryId}</button>
                                       </div>

                                       <div className="col-4 px-0 col-md-3 col-lg-4 col-xl-3 text-center">
                                          <button
                                             className={`submenu-tab py-3 py-md-4 ${this.state.activeTab === SHIPMENT_TABS.job ? "active" : ""}`}
                                             id={SHIPMENT_TABS.job}
                                             onClick={() => (onChangeActiveTab(SHIPMENT_TABS.job))}>Job(s)</button>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            {delivery.DeliveryId && (
               <section className="order-tracking order-detail col-12 col-lg-10 offset-lg-1 mt-5">
                  <div className="row">
                     <div className="col-12 col-md-5 px-0 mb-3--">
                        <div className="col-12 id">
                           <div className="row">
                              <div className="col-1 px-0 col-md-2 d-flex justify-content-center justify-content-md-end px-md-2">
                                 {delivery.warningText ? <div className="circle active expire"></div> : null}
                              </div>
                              <div className="col-11 col-md-10 px-0">SHP_{delivery.DeliveryId}</div>
                           </div>
                        </div>
                     </div>
                     <div className="col-12 col-md-7 px-0 order-first order-md-last mb-3--">
                        <div className="col-12 px-lg-3 mx-lg-4">
                           <BarOrderStatus
                              status={delivery.tracking.Status}
                              warningText={delivery.tracking.Status === TRACKING_STATUSES.Exception || delivery.tracking.Status === TRACKING_STATUSES.AttemptFail ? true : false}
                              view={VIEW_BAR_STATUS.tracking}
                              SignedBy={delivery.tracking.SignedBy}
                              title={TRACKING_STATUSES_TITLE}
                              statusBar={TRACKING_STATUSES_BAR} />
                        </div>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-12 col-md-5 mb-3">
                        <div className="row">
                           <div className="col-11 col-md-10 offset-1 offset-md-2 px-0 my-3">
                              {delivery.tracking.Slug && SLUG[delivery.tracking.Slug].image ? <img src={SLUG[delivery.tracking.Slug].image} alt="icon" /> : null}
                           </div>
                           <div className="col-11 col-md-10 px-0 offset-1 offset-md-2 order-details mb-3">
                              <p className="col-12 px-0 text-faded">Tracking Number: <a href={delivery.tracking.TrackingUrl}>{delivery.tracking.TrackingNumber ? delivery.tracking.TrackingNumber : "Awaiting Number"}</a></p>
                              <p className="col-12 px-0 text-faded">Booking date: {delivery.tracking.BookingDate ? moment(delivery.tracking.BookingDate).format("ddd, D MMM h:mma") : null}</p>
                              <p className="col-12 px-0 text-faded">Pickup date: {delivery.tracking.PickupDate ? moment(delivery.tracking.PickupDate).format("ddd, D MMM h:mma") : null}</p>
                           </div>

                           <div className="col-12 delivery-method my-3">
                              <div className="row">
                                 <div className='col-1 col-md-2 px-0 d-flex justify-content-center justify-content-md-end px-md-3'>
                                    <div className="row">
                                       {delivery.DeliveryMethod === DELIVERY_METHOD_PLANS.express ? <div className="icon-express"></div> : null}
                                    </div>
                                 </div>
                                 <span className="col-11 col-md-10">{delivery.DeliveryMethod}</span>
                              </div>
                           </div>

                           <div className="col-11 offset-1 col-md-10 offset-md-2 delivery-date">
                              <p className="estimated">Estimated delivery:</p>
                              <p className="date">{delivery.estimateDelivery ? moment(delivery.estimateDelivery).format("ddd D MMM") : null}</p>
                           </div>

                           <Query query={GET_COORDS} variables={{ orderId: delivery.OrderId }}>
                              {
                                 ({ error, loading, data }) => {
                                    if (loading) {
                                       return (<Spinner size='small' />)
                                    }
                                    return data && data.getCoords ? (<div className="col-11 offset-1 px-0 col-md-10 offset-md-2 details my-4">
                                       <MapBox lat={Number(data.getCoords.latitude)} lng={Number(data.getCoords.longitude)} />
                                    </div>) : ''
                                 }
                              }
                           </Query>
                        </div>
                     </div>
                     <div className="col-12 mb-3 col-md-7 px-xl-0">
                        <div className="container px-md-3">
                           <div className="col-12 mt-md-5 mt-3">
                              {this.state.activeTab === SHIPMENT_TABS.id ? (
                                 <div className="row">
                                    <div className="col-12 col-md-12 mb-3 mt-md-3">
                                       <p className="col-12 px-0 title">Tracking History</p>
                                    </div>
                                    <TrackingList trackings={delivery.tracking.CheckPoints} />
                                 </div>
                              ) : this.state.activeTab === SHIPMENT_TABS.job ? (
                                 <div className="row">
                                    <div className="col-12 col-md-12 mb-3 mt-md-3">
                                       <p className="col-12 px-0 title">Job</p>
                                    </div>

                                    <JobList jobs={delivery.jobs} orderId={delivery.OrderId} deepLink={true} props={this.props} />
                                 </div>
                              ) : null}
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
            )}
         </ApolloProvider>
      );
   }
}

export default withRouter(ShipmentDetailPage);