// React and routing
import React from 'react';
import { withRouter } from "react-router-dom";

// Apollo/GraphQL
import { ApolloClient } from "apollo-client";
import { ApolloProvider, Query } from 'react-apollo';
import gql from "graphql-tag";
import { apolloSecureConfig } from '../../support/settings/apollo';

// Components
import BasicHeader from '../header/BasicHeader';
import Header from '../header/Header';
import Icon from '../Icons/Icon';
import OrderArtwork from './OrderArtwork';
import OrderDetail from './OrderDetail';
import OrderJob from './OrderJob';
import Spinner from '../Spinner/Spinner';
import Tracking from './Tracking';

// Constants and utils
import { EDIT_ORDER_TABS, ACTION_EDIT_ORDER, ORDER_STATUSES } from '../../constants';
import { updateQueryParam } from '../../libs/utils';

// Styles
import './style.scss';

// Commented out imports
// import { InfoBanner } from '../InfoBanner/InfoBanner';
const queryString = require('query-string');

const client = new ApolloClient(apolloSecureConfig);

const GET_ORDER_AUTH = gql`
    query getOrder($orderId: Int!) {
        getOrder(orderId: $orderId) {
            OrderId
            OrderName
            POReference
            ProductId
            ProductName
            DateCreated
            deliveryMethod
            DeliveryCost
            OrderTotal
            OrderStatus
            warningText
            estimateDelivery
            jobs {
              JobName
              JobId
              Quantity
              JobStatus
              warningText
            }
            artworks {
                JobId
                ArtworkId
                Version
                Quantity
                JobId
                ArtworkType
                ArtworkStatus
                ArtworkComments
                DateCreated
                DateProofCreated
                DateProofImposed
                DateProofApproved
                DateProofSentToPrint
                ApprovalRequiredTime
                Expired
            }
       }
     }
`;
const GET_ORDER = gql`
    query getOrder($orderId: Int!,$clientContactKey: String) {
        getOrder(orderId: $orderId,clientContactKey: $clientContactKey) {
            OrderId
            OrderName
            POReference
            ProductId
            ProductName
            DateCreated
            deliveryMethod
            OrderStatus
            warningText
            estimateDelivery
            jobs {
              JobName
              JobId
              Quantity
              JobStatus
              warningText
            }
            artworks {
                JobId
                ArtworkId
                Version
                Quantity
                JobId
                ArtworkType
                ArtworkStatus
                ArtworkComments
                DateCreated
                DateProofCreated
                DateProofImposed
                DateProofApproved
                DateProofSentToPrint
                ApprovalRequiredTime
                Expired
            }
       }
     }
`;
const GET_JOB_AUTH = gql`
    query getJob($jobId: Int!) {
        getJob(jobId: $jobId) {
            JobId
            JobName
            ProductName
            ClientJobReference
            Quantity
            FinishedSizeWidth
            FinishedSizeHeight
            ColorFront
            ColorBack
            ColorThroughout
            Orientation
            Binding
            Pages
            TextPaper
            PaperType
            LaminationFront
            LaminationBack
            CoverColorFront
            CoverColorBack
            CoverColour
            CoverPaper
            CoverPaperType
            LaminationCover
            LaminationCoverFront
            LaminationCoverBack
            DeliveryCost
            TotalPrice
            warningText
            JobStatus
            IsComplete
            IsOnAutoProof
            artworks {
               ArtworkId
               Version
               Quantity
               JobId
               ArtworkType
               ArtworkStatus
               warningArtwork
            }
       }
     }
`;
const GET_JOB = gql`
    query getJob($jobId: Int!,$clientContactKey: String) {
        getJob(jobId: $jobId,clientContactKey:$clientContactKey ) {
            JobId
            JobName
            ProductName
            ClientJobReference
            Quantity
            FinishedSizeWidth
            FinishedSizeHeight
            ColorFront
            ColorBack
            ColorThroughout
            Orientation
            Binding
            Pages
            TextPaper
            PaperType
            LaminationFront
            LaminationBack
            CoverColorFront
            CoverColorBack
            CoverColour
            CoverPaper
            CoverPaperType
            LaminationCover
            LaminationCoverFront
            LaminationCoverBack
            warningText
            JobStatus
            IsComplete
            IsOnAutoProof
            artworks {
               ArtworkId
               Version
               Quantity
               JobId
               ArtworkType
               ArtworkStatus
               warningArtwork
            }
       }
     }
`;

const GET_ARTWORK_AUTH = gql`
    query getArtwork($artworkId: Int!) {
        getArtwork(artworkId: $artworkId) {
            ArtworkId
            Version
            Quantity
            JobId
            ArtworkType
            ArtworkPath
            ArtworkStatus
            ArtworkComments
            DateCreated
            DateProofCreated
            DateProofImposed
            DateProofApproved
            DateProofSentToPrint
            ApprovalRequiredTime
            NextWorkingDay
            Expired
       }
     }
`;
const GET_ARTWORK = gql`
    query getArtwork($artworkId: Int!, $clientContactKey: String) {
        getArtwork(artworkId: $artworkId, clientContactKey: $clientContactKey) {
            ArtworkId
            Version
            Quantity
            JobId
            ArtworkType
            ArtworkStatus
            ArtworkComments
            DateCreated
            DateProofCreated
            DateProofImposed
            DateProofApproved
            DateProofSentToPrint
            ApprovalRequiredTime
            Expired
            NextWorkingDay
       }
     }
`;

const GET_TRACKING = gql`
    query getTracking($orderId: Int!) {
        getTracking(orderId: $orderId) {
            OrderId
            OrderName
            Slug
            Status
            TrackingNumber
            TrackingUrl
            BookingDate
            PickupDate
            EstimateDelivery
            DeliveryMethod
            SignedBy
            Address
            CheckPoints {
              EventDate
              EventTime
              EventName
              EventLocation
              EventStatus
            }
       }
     }
`;

const GET_COUNTERS_TAB_AUTH = gql`
    query getCountersDataByOrderId($orderId: Int!) {
        getCountersDataByOrderId(orderId: $orderId) {
            artworks
            jobs
            tracking
        }
    }
`;

const GET_COUNTERS_TAB = gql`
    query getCountersDataByOrderId($orderId: Int!,$clientContactKey: String) {
        getCountersDataByOrderId(orderId: $orderId,clientContactKey: $clientContactKey) {
            artworks
            jobs
        }
    }
`;

class EditOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: EDIT_ORDER_TABS.id,
            currentOrder: {},
            activeJobIndex: 0,
            activeArtworkIndex: 0,
            currentJob: {},
            currentArtwork: {},
            hasError: '',
            action: ACTION_EDIT_ORDER.fromTab,
            mounted: false
        }
    }

    componentDidMount() {
        if (this.props.approvalRequest) {
            const parsed = queryString.parse(this.props.location.search);
            client.query({
                query: GET_ORDER,
                variables: { orderId: Number(this.props.orderId), clientContactKey: parsed.key }
            }).then((response) => {
                const { getOrder } = response.data;
                this.setState({
                    currentOrder: getOrder,
                    activeTab: EDIT_ORDER_TABS.id
                });
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const parsed = queryString.parse(this.props.location.search);
        const jobIndex = parsed.jobIndex !== undefined && !isNaN(Number(parsed.jobIndex))
            ? Number(parsed.jobIndex)
            : 0;

        // if tab param is different from current state, then update the active tab
        if (parsed.tab && this.state.activeTab !== EDIT_ORDER_TABS[parsed.tab]) {
            this.setState({
                activeTab: EDIT_ORDER_TABS[parsed.tab]
            });
        }

        // If jobIndex is different from current state, then update the active job index
        if (jobIndex !== this.state.activeJobIndex || (parsed.jobId && this.state.activeTab !== EDIT_ORDER_TABS.job)) {
            if (jobIndex !== this.state.activeJobIndex && !prevState.mounted) {
                this.setState({
                    activeJobIndex: jobIndex,
                });
            }
        }

        // if active job index is different from current state, then update the URL
        if (prevState.activeJobIndex !== this.state.activeJobIndex) {
            const currentUrlJobIndex = Number(queryString.parse(this.props.location.search).jobIndex);
            if (currentUrlJobIndex !== this.state.activeJobIndex) {
                updateQueryParam({ history: this.props.history, setOptions: { jobIndex: this.state.activeJobIndex } });
            }
        }

        // if mounted is different from current state, then update the mounted state
        if (!this.state.mounted) {
            this.setState({
                mounted: true
            });
        }
    }

    onChangeActiveTab = (tab, action) => {
        const { currentOrder, activeJobIndex } = this.state;

        // update the query param with the new tab
        updateQueryParam({
            history: this.props.history,
            setOptions: { tab: this.getKeyByValue(EDIT_ORDER_TABS, tab) },
            removeOptions: { jobIndex: null }
        });

        if (tab === EDIT_ORDER_TABS.artwork) {
            const parsed = queryString.parse(this.props.location.search);
            const variablesGetJob = (this.props.approvalRequest ? { jobId: Number(currentOrder.jobs[activeJobIndex].JobId), clientContactKey: parsed.key } : { jobId: Number(currentOrder.jobs[activeJobIndex].JobId) });
            client.query({
                query: this.props.approvalRequest ? GET_JOB : GET_JOB_AUTH,
                variables: variablesGetJob
            }).then((response) => {
                const { getJob } = response.data;
                this.setState({
                    currentJob: getJob
                });
            })
        }
        this.setState({
            activeTab: tab,
            action
        })
        if (tab === EDIT_ORDER_TABS.id) {
            this.setState({
                activeJobIndex: 0
            })
        }
    }

    onSaveOrder = (data) => {
        const { getOrder } = data;
        this.setState({
            currentOrder: getOrder,
            hasError: ''
        })
    }

    onSaveJob = (data) => {
        const { getJob } = data;
        this.setState({
            currentJob: getJob
        })
    }

    onChangeActiveJobIndex = (activeJobIndex) => {
        this.setState({
            activeJobIndex
        })
    }

    onChangeActiveArtworkIndex = (activeArtworkIndex) => {
        this.setState({
            activeArtworkIndex
        })
    }

    changeArtwork = (updatedArtwork) => {
        const { activeArtworkIndex } = this.state;

        const newArtworks = this.state.currentJob.artworks;
        newArtworks[activeArtworkIndex] = updatedArtwork;

        this.setState({
            currentJob: {
                ...this.state.currentJob,
                artworks: newArtworks
            },
            currentArtwork: updatedArtwork
        })
    }

    onSaveArtwork = data => {
        const { getArtwork } = data;
        this.setState({
            currentArtwork: getArtwork
        })
    }

    onResetCurrentJob = () => {
        this.setState({
            currentJob: {},
            activeArtworkIndex: 0,
            activeJobIndex: 0
        })
    }

    onDisplayErrors = (errors) => {
        if (errors && errors.length) {
            return errors.map((error, index) => {
                return <p key={index}>{error.message}</p>
            })
        }
    }

    onShowError = (error) => {
        if (error.graphQLErrors && error.graphQLErrors.length) {
            this.setState({
                hasError: error.graphQLErrors[0].message
            })
        }
    }

    getKeyByValue = (object, value) => {
        return Object.keys(object).find(key => object[key] === value);
    }

    render() {
        const { activeTab, currentOrder, currentJob, activeJobIndex, activeArtworkIndex, currentArtwork, action } = this.state;

        const parsed = queryString.parse(this.props.location.search);
        const variablesGetOrder = this.props.approvalRequest ? { orderId: Number(this.props.orderId), clientContactKey: parsed.key } : { orderId: Number(this.props.orderId) };
        const variablesGetJob = activeTab === EDIT_ORDER_TABS.job ? (this.props.approvalRequest ? { jobId: Number(currentOrder.jobs[activeJobIndex].JobId), clientContactKey: parsed.key } : { jobId: Number(currentOrder.jobs[activeJobIndex].JobId) }) : null;
        const variablesGetArtwork = activeTab === EDIT_ORDER_TABS.artwork && Object.keys(currentOrder).length !== 0 && currentOrder.artworks.length ? (this.props.approvalRequest ? { artworkId: action === ACTION_EDIT_ORDER.fromTab ? Number(currentOrder.artworks[activeArtworkIndex].ArtworkId) : Number(currentJob.artworks[activeArtworkIndex].ArtworkId), clientContactKey: parsed.key } : { artworkId: action === ACTION_EDIT_ORDER.fromTab ? Number(currentOrder.artworks[activeArtworkIndex].ArtworkId) : Number(currentJob.artworks[activeArtworkIndex].ArtworkId) }) : null;
        const variablesGetCounterByOrderId = this.props.approvalRequest ? { orderId: Number(this.props.orderId), clientContactKey: parsed.key } : { orderId: Number(this.props.orderId) };
        const isArtworks = currentOrder.artworks ? currentOrder.artworks.length : 0;

        return (
            <ApolloProvider client={client}>
                <section className="orders-section">
                    <div className="container-fluid">
                        <div className="row">
                            {this.state.hasError ? null : (
                                <div className="header-bar sticky-top col-12">
                                    <div className="row">
                                        {this.props.approvalRequest ? <BasicHeader /> : <Header locationPathName={this.props.location.pathname} />}
                                        <div className="col-12 submenu">
                                            <div className="row">
                                                <div className="col-2 px-0 py-3 col-lg-1 d-flex align-items-center order-first">
                                                    <div className="container-fluid">
                                                        {!this.props.approvalRequest ? (
                                                            <div className="row d-flex justify-content-center">
                                                                <button className="btn-back" onClick={() => this.props.history.goBack()}>
                                                                    <Icon name="back" />
                                                                    <span className="px-2 d-none d-md-inline-block">Back</span>
                                                                </button>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-10 col-lg-6 offset-lg-1 d-flex justify-content-center">
                                                    <div className="nav col-12 col-md-10 offset-md-1 col-lg-12 offset-lg-4 col-xl-10 offset-xl-6">
                                                        <div className="container px-0">
                                                            <div className="row d-flex justify-content-between">
                                                                <div className="col-4 px-0 col-md-3 col-lg-4 col-xl-3 text-center">
                                                                    <button className={`submenu-tab py-3 py-md-4 ${activeTab === EDIT_ORDER_TABS.id ? "active" : ""}`} id={EDIT_ORDER_TABS.id} onClick={() => (this.onChangeActiveTab(EDIT_ORDER_TABS.id, ACTION_EDIT_ORDER.fromTab))}>ORD_{this.props.orderId}</button>
                                                                </div>
                                                                <div className="col-4 col-md-3 text-center">
                                                                    <button className={`submenu-tab py-3 py-md-4 ${activeTab === EDIT_ORDER_TABS.job ? "active" : ""}`} id={EDIT_ORDER_TABS.job} onClick={() => (this.onChangeActiveTab(EDIT_ORDER_TABS.job, ACTION_EDIT_ORDER.fromTab))}>Job(s)
                                                                        <span className="badge rounded-circle badge-danger">
                                                                            <Query fetchPolicy={'network-only'} query={this.props.approvalRequest ? GET_COUNTERS_TAB : GET_COUNTERS_TAB_AUTH} variables={variablesGetCounterByOrderId}>
                                                                                {
                                                                                    ({ error, loading, data }) => {
                                                                                        return data && data.getCountersDataByOrderId && data.getCountersDataByOrderId.jobs > 0 ? data.getCountersDataByOrderId.jobs : ''
                                                                                    }
                                                                                }
                                                                            </Query>
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                                <div className="col-4 px-0 col-md-3 text-center">
                                                                    {isArtworks > 0 ? (
                                                                        <button className={`submenu-tab py-3 py-md-4 ${activeTab === EDIT_ORDER_TABS.artwork ? "active" : ""}`} id={EDIT_ORDER_TABS.artwork} onClick={() => (this.onChangeActiveTab(EDIT_ORDER_TABS.artwork, ACTION_EDIT_ORDER.fromTab))}>Artwork(s)
                                                                            <span className="badge rounded-circle badge-danger">
                                                                                <Query fetchPolicy={'network-only'} query={this.props.approvalRequest ? GET_COUNTERS_TAB : GET_COUNTERS_TAB_AUTH} variables={variablesGetCounterByOrderId}>
                                                                                    {
                                                                                        ({ error, loading, data }) => {
                                                                                            return data && data.getCountersDataByOrderId && data.getCountersDataByOrderId.artworks > 0 ? data.getCountersDataByOrderId.artworks : ''
                                                                                        }
                                                                                    }
                                                                                </Query>
                                                                            </span>
                                                                        </button>
                                                                    ) : null}
                                                                </div>
                                                                <div className="col-4 px-0 col-md-3 col-lg-2 col-xl-3 text-center">
                                                                    {(currentOrder.OrderStatus === ORDER_STATUSES.Despatched || currentOrder.OrderStatus === ORDER_STATUSES.Invoiced) ? (
                                                                        <button className={`submenu-tab py-3 py-md-4 ${activeTab === EDIT_ORDER_TABS.tracking ? "active" : ""}`} id={EDIT_ORDER_TABS.tracking} onClick={() => (this.onChangeActiveTab(EDIT_ORDER_TABS.tracking, ACTION_EDIT_ORDER.fromTab))}>Tracking
                                                                            <span className="badge rounded-circle badge-danger">
                                                                                <Query fetchPolicy={'network-only'} query={this.props.approvalRequest ? GET_COUNTERS_TAB : GET_COUNTERS_TAB_AUTH} variables={variablesGetCounterByOrderId}>
                                                                                    {
                                                                                        ({ error, loading, data }) => {
                                                                                            return data && data.getCountersDataByOrderId && data.getCountersDataByOrderId.tracking > 0 ? '!' : ''
                                                                                        }
                                                                                    }
                                                                                </Query>
                                                                            </span>
                                                                        </button>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {activeTab === EDIT_ORDER_TABS.id &&
                                <Query fetchPolicy={'network-only'} query={this.props.approvalRequest ? GET_ORDER : GET_ORDER_AUTH} variables={variablesGetOrder} onCompleted={this.onSaveOrder} onError={this.onShowError}>
                                    {
                                        ({ error, loading, data }) => {
                                            if (loading) {
                                                return (<Spinner size='small' />)
                                            }
                                            if (error) {
                                                return error.graphQLErrors && error.graphQLErrors.length ? (<div className="col-lg-12 mt-5 section-error">{this.onDisplayErrors(error.graphQLErrors)}</div>) : null;
                                            }
                                            return data.getOrder ? (
                                                <OrderDetail orderData={data.getOrder} onChangeActiveTab={() => this.onChangeActiveTab(EDIT_ORDER_TABS.job, ACTION_EDIT_ORDER.fromTab)} resetCurrentJob={() => this.onResetCurrentJob()} changeActiveJobIndex={(index) => this.onChangeActiveJobIndex(index)} history={this.props.history} />
                                            ) : null
                                        }
                                    }
                                </Query>
                            }
                            {activeTab === EDIT_ORDER_TABS.job &&
                                <Query fetchPolicy={'network-only'} query={this.props.approvalRequest ? GET_JOB : GET_JOB_AUTH} variables={variablesGetJob} onCompleted={this.onSaveJob} >
                                    {
                                        ({ error, loading, data }) => {
                                            if (loading) {
                                                return (<Spinner size='small' />)
                                            }
                                            return data.getJob ? (
                                                <OrderJob jobs={currentOrder.jobs} activeJobIndex={activeJobIndex} changeActiveJobIndex={(index) => this.onChangeActiveJobIndex(index)} jobData={data.getJob} orderStatus={currentOrder.OrderStatus} onChangeActiveTab={() => this.onChangeActiveTab(EDIT_ORDER_TABS.artwork, ACTION_EDIT_ORDER.fromJob)} changeActiveArtworkIndex={(index) => this.onChangeActiveArtworkIndex(index)} resetCurrentJob={() => this.onResetCurrentJob()} />
                                            ) : null
                                        }
                                    }
                                </Query>
                            }
                            {activeTab === EDIT_ORDER_TABS.artwork && Object.keys(currentOrder).length !== 0 && currentOrder.artworks.length &&
                                <Query fetchPolicy={'network-only'} query={this.props.approvalRequest ? GET_ARTWORK : GET_ARTWORK_AUTH} variables={variablesGetArtwork} onCompleted={this.onSaveArtwork}>
                                    {
                                        ({ error, loading, data }) => {
                                            if (loading) {
                                                return (<Spinner size='small' />)
                                            }
                                            return data.getArtwork ? (
                                                <OrderArtwork job={currentJob} clientContactKey={this.props.approvalRequest ? parsed.key : null} approvalRequest={this.props.approvalRequest} artworks={action === ACTION_EDIT_ORDER.fromTab ? currentOrder.artworks : currentJob.artworks} artworkData={currentArtwork} activeArtworkIndex={activeArtworkIndex} changeActiveArtworkIndex={(index) => this.onChangeActiveArtworkIndex(index)} curentJobUpdate={(updatedArtwork) => this.changeArtwork(updatedArtwork)} resetCurrentJob={() => this.onResetCurrentJob()} />
                                            ) : null
                                        }
                                    }
                                </Query>
                            }
                            {activeTab === EDIT_ORDER_TABS.tracking && (currentOrder.OrderStatus === ORDER_STATUSES.Despatched || currentOrder.OrderStatus === ORDER_STATUSES.Invoiced) ?
                                <Query fetchPolicy={'network-only'} query={GET_TRACKING} variables={{ orderId: Number(currentOrder.OrderId) }}>
                                    {
                                        ({ error, loading, data }) => {
                                            if (loading) {
                                                return (<Spinner size='small' />)
                                            }
                                            return data.getTracking ? (
                                                <Tracking trackingData={data.getTracking} resetCurrentJob={() => this.onResetCurrentJob()} />
                                            ) : null
                                        }
                                    }
                                </Query>
                                : null}
                        </div>
                    </div>
                </section >
            </ApolloProvider>
        );
    }
}

export default withRouter(EditOrder);