import React from "react";
import { TRACKING_STATUSES, CHECKPOINT_TITLE } from "../../constants";

const TrackingList = ({ trackings }) => {
   return (
      <div className="table col-12 col-md-12">
         <div className="col-12 thead">
            <div className="row mb-3">
               <div className="col-4 col-md-3">Date</div>
               <div className="col-6 col-md-5">Event</div>
               <div className="col-1 col-md-4 d-none d-md-inline-block">
                  <div className="col-10- px-0- float-right- text-center">Status</div>
               </div>
            </div>
         </div>
         <div className="col-12">
            {trackings && trackings.length > 0 ? (
               trackings.map((tracking, index) => {
                  return (
                     <div key={index} className="row mb-3">
                        <div className="col-4 col-md-3 px-0">
                           <p className="col-12 px-0">{tracking.EventDate}</p>
                           <p className="col-12 px-0 details">{tracking.EventTime}</p>
                        </div>
                        <div className="col-6 col-md-5">
                           <p className="col-12 px-0">{tracking.EventName}</p>
                           <p className="col-12 px-0 details">{tracking.EventLocation}</p>
                        </div>
                        <div className="col-1 col-md-4">
                           <div className="row justify-content-center">
                              <div className="px-2">
                                 <div
                                    className={`circle active ${tracking.EventStatus === TRACKING_STATUSES.Exception ||
                                       tracking.EventStatus === TRACKING_STATUSES.AttemptFail
                                       ? "expire"
                                       : ""
                                       }`}
                                 ></div>
                              </div>
                              <div
                                 className={`col-10- pr-md-0- d-none d-md-inline-block ${tracking.EventStatus === TRACKING_STATUSES.Exception ||
                                    tracking.EventStatus === TRACKING_STATUSES.AttemptFail
                                    ? "expire"
                                    : ""
                                    }`}
                              >
                                 {tracking.EventStatus === CHECKPOINT_TITLE.Exception ||
                                    tracking.EventStatus === CHECKPOINT_TITLE.AttemptFail
                                    ? CHECKPOINT_TITLE[tracking.EventStatus].title
                                    : ""}
                              </div>
                           </div>
                        </div>
                     </div>
                  );
               })
            ) : (
               <div className="col-12 text-center">No tracking history available</div>
            )}
         </div>
      </div>
   );
};

export default TrackingList;
