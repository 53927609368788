import React from 'react';
import { Row, Col } from 'antd';
import gql from "graphql-tag";
import { withRouter } from "react-router-dom";
import { ApolloProvider, Mutation } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { apolloConfig } from "../../support/settings/apollo";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { validateForgotPassword } from '../../support/validation';
import { GOOGLE_RECAPTCHA_SITE_KEY } from '../../constants';
import ReCAPTCHA from "react-google-recaptcha";
import './style.scss';
const client = new ApolloClient(apolloConfig);
const FORGOT_PASSWORD = gql`
    mutation forgotPassword($email: String!) {
      forgotPassword(email: $email) {
        email
       }
     }
`;
class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        email: ''
      },
      recapthaError: null,
      validRecaptcha: false,
      errorCheckUser: [],
      successNotification: null,
      disabledButton: false
    }
  }
  componentWillUnmount() {
    this.onResetState()
  }
  onResetState() {
    this.setState({
      initialValues: {
        email: ''
      },
      errorCheckUser: [],
      successNotification: null,
      disabledButton: false
    })
  }
  displayCheckError = () => {
    const { errorCheckUser } = this.state;
    return errorCheckUser.map((error, index) => {
        return <p className="error" key={index}>{error}</p>
    })
}
  onCompleteRecaptcha = () => {
    this.setState({
      validRecaptcha: true
    })
  }
  clearError = () => {
    this.setState({
      errorCheckUser: [],
      successNotification: 'Please check your email!'
    })
  }

  submit = (mutation,data, setSubmitting) => {
    const { validRecaptcha, recapthaError } = this.state;
    if (validRecaptcha) {
        this.setState({
            recapthaError: '',
            disabledButton: true
        })
        setSubmitting(true);
        mutation({
            variables: {
                email: data.email
            }
        })
            .then((response) => {
                this.clearError()
                setSubmitting(false);
            },
                (error) => {
                  this.setState({ errorCheckUser: error.graphQLErrors.map(error => error.message), disabledButton: false });
                }
            );
    } else {
        this.setState({
            recapthaError: 'Invalid recaptcha'
        })
    }
}

renderForgotPassword = () => {
  const { validRecaptcha, recapthaError } = this.state;
  const { initialValues, errorCheckUser,successNotification,disabledButton } = this.state;
  return (
    <section className="forgot-section pt-5">
      <Row>
        <Col sm={{ span: 20, offset: 2 }} lg={{ span: 6, offset: 8 }}>
          <Row type="flex" justify="center">
            <a href="#" className="logo">
              <img src={require('../../assets/img/printhub_logo.svg')} alt="logo" className="img-fluid" />
            </a>
          </Row>

          {errorCheckUser && errorCheckUser.length ? (
            <Row className="mt-5">
              <Col span={20} offset={2}>
                <div className="form-group text-center">
                  {this.displayCheckError()}
                </div>
              </Col>
            </Row>
          ) : ''}
          {successNotification ? (
              <Row className="mt-5">
              <Col span={20} offset={2}>
                <div className="form-group text-center">
                  <p className="blue-notification">{successNotification}</p>
                </div>
              </Col>
            </Row>
          ) : null}
            <Form>
              <Row className="mt-4">
                <Col span={20} offset={2}>
                  <Field className="form-control px-0" type="email" aria-required="true" name="email" placeholder="you@example.com" />
                  <ErrorMessage className="error" name="email" component="div" />
                </Col>
              </Row>

              <Row className="my-4">
                <Col span={20} offset={2}>
                  <div className="form-group text-center">
                    <button disabled={disabledButton} type="submit" className="form-button">Email Link</button>
                  </div>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col span={20} offset={2}>
                  <div className="form-group">
                    <a className="form-forgot" href="/">Back to Login</a>
                  </div>
                </Col>
              </Row>

              <Row className="mt-4">
                <Col span={20} offset={2}>
                  <ReCAPTCHA
                    sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
                    onChange={() => this.onCompleteRecaptcha()}
                  />
                  {!validRecaptcha && recapthaError ? (<p className="error">{recapthaError}</p>) : null}
                </Col>

              </Row>
            </Form>
        </Col>
      </Row>
    </section>
  );
}

  render() {
    const { initialValues } = this.state;
    return (
      <ApolloProvider client={client}>
      <Mutation mutation={FORGOT_PASSWORD}>
          {(forgotPassword) => (
          <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validate={validateForgotPassword}
          onSubmit={(values, { setSubmitting }) => this.submit(forgotPassword,values, setSubmitting)}
        >
                  {({ isSubmitting }) => this.renderForgotPassword()}
              </Formik>
          )}
      </Mutation>
  </ApolloProvider>
    )
  }
}


export default ForgotPassword;
