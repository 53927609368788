import React from "react";
import { withRouter } from "react-router-dom";

import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from "apollo-client";
import { apolloSecureConfig } from '../../support/settings/apollo';

import Spinner from '../Spinner/Spinner';
import { SelectInput } from '../FormElements/index.js';
import Header from '../header/Header';
import ShipmentList from './ShipmentList';
import './style.scss';
import gql from "graphql-tag";
import { debounce } from 'lodash';
import { FILTER_DELIVERIES, ORDER_QUOTE } from '../../constants';
import { getSearchId, getSearchSubString } from '../../libs/utils';
const queryString = require('query-string');

const client = new ApolloClient(apolloSecureConfig);

export const GET_DELIVERIES = gql`
  query GetDeliveries($filter: DeliveryFilterInput, $page: Int, $dispatchedSort: String) {
    getDeliveries(filter: $filter, page: $page, dispatchedSort: $dispatchedSort) {
      content {
        DeliveryId
        OrderId
        ExpectedDespatchDateTime
        TrackingNumber
        IsDeliveryComplete
        DeliveryStatus
        Weight
        SelectedCarrier
        ParcelCount
      }
      totalElements,
      totalPages,
      last,
      first
    }
  }
`;

class Shipments extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         deliveries: [],
         searchDeliveryValue: "",
         hasError: "",
         selectedFilterDelivery: FILTER_DELIVERIES[0],
         page: 0,
         loading: false,
         totalCount: 0,
         totalPages: 0,
         dispatchedSort: ORDER_QUOTE.desc
      };
      this.searchDeliveriesDebounced = debounce(this.onSearchDeliveries, 500);
   }

   componentDidMount() {
      const parsed = queryString.parse(this.props.location.search);

      // Create a local variable for the search ID
      const searchId = parsed.search ? parsed.search : null;
      let filter = parsed.filter ? parsed.filter : null;

      if (searchId) {
         this.setState({ searchDeliveryValue: searchId });
      }

      if (filter) {
         this.setState({ selectedFilterDelivery: FILTER_DELIVERIES.find(f => f.name === filter) });
      } else {
         this.setState({ selectedFilterDelivery: FILTER_DELIVERIES[0] });
      }

      // Pass the search ID directly to fetchDeliveries
      this.fetchDeliveries({ overrideSearchId: searchId, overrideFilter: filter });
   }

   componentDidUpdate(prevProps, prevState) {
      // Fetch deliveries when page or status filter changes
      if (prevState.page !== this.state.page ||
         prevState.selectedFilterDelivery !== this.state.selectedFilterDelivery) {
         const filters = this.state.selectedFilterDelivery === -100 ? null : this.state.selectedFilterDelivery;

         // Preserve the search ID when filter changes
         this.fetchDeliveries({ overrideFilter: filters });

         this.handleSearchParams();
      }
   }

   formatSearchFilters = (searchDeliveryValue) => {
      const filters = {};
      const searchSubString = getSearchSubString(searchDeliveryValue);
      const searchId = getSearchId(searchDeliveryValue);

      if (searchSubString === 'shp' || !isNaN(searchDeliveryValue)) {
         filters.DeliveryId = parseInt(searchId);
      } else if (searchSubString === 'ord') {
         filters.OrderId = parseInt(searchId);
      }

      return filters;
   }

   fetchDeliveries = (options = {}) => {
      const { page } = this.state;
      const searchDeliveryValue = options.overrideSearchId !== undefined ? options.overrideSearchId : this.state.searchDeliveryValue;
      const selectedFilterDelivery = options.overrideFilter !== undefined ? options.overrideFilter : this.state.selectedFilterDelivery;
      const dispatchedSort = options.overrideSort !== undefined ? options.overrideSort : this.state.dispatchedSort;

      let filters = {};
      if (selectedFilterDelivery && selectedFilterDelivery.value !== null && selectedFilterDelivery.value !== -100) {
         filters.DeliveryStatus = selectedFilterDelivery.value;
      }

      if (searchDeliveryValue) {
         if (searchDeliveryValue.length > 10 || (!isNaN(searchDeliveryValue) && searchDeliveryValue.length > 6)) {
            filters.TrackingNumber = searchDeliveryValue;
         } else {
            const searchFilters = this.formatSearchFilters(searchDeliveryValue);
            filters = { ...filters, ...searchFilters };
         }
      }

      this.setState({ loading: true, hasError: "" });

      client.query({
         query: GET_DELIVERIES,
         variables: {
            filter: filters,
            page,
            dispatchedSort  // Make sure this is included
         },
         fetchPolicy: "network-only"
      })
         .then(response => {
            const { getDeliveries } = response.data;
            this.setState({
               deliveries: getDeliveries.content,
               totalCount: getDeliveries.totalElements,
               totalPages: getDeliveries.totalPages,
               loading: false
            });
         })
         .catch(error => {
            this.setState({
               loading: false,
               hasError: `Error loading shipments: ${error.message}`
            });
         });
   }

   onResetState = () => {
      this.setState({
         deliveries: [],
         searchDeliveryValue: "",
         hasError: "",
         page: 0
      })
   }

   onChangeFilterStatus = (selectedOption) => {
      const selectedFilter = FILTER_DELIVERIES.find(filter => filter.value === Number(selectedOption));

      this.handleSearchParams();

      this.setState({
         selectedFilterDelivery: selectedFilter,
         dispatchedSort: ORDER_QUOTE.desc,
         page: 0
      });
   }

   onSearchDeliveries = () => {
      if (this.state.searchDeliveryValue.length === 0 || this.state.searchDeliveryValue.length >= 10 || (!isNaN(this.state.searchDeliveryValue) && this.state.searchDeliveryValue.length >= 6)) {
         this.handleSearchParams();
         this.setState({ page: 0 }, this.fetchDeliveries);
      }
   }

   onChangesearchDeliveryValue = (event) => {
      this.setState({
         searchDeliveryValue: event.target.value.trim()
      });
      this.searchDeliveriesDebounced()
   }

   onSearchDeliveriesClick = () => {
      this.searchDeliveriesDebounced();
   }

   onChangePage = (page) => {
      this.setState({ page });
   }

   onChangeDespatchedSort = () => {
      const newSortDirection = this.state.dispatchedSort === ORDER_QUOTE.asc ? ORDER_QUOTE.desc : ORDER_QUOTE.asc;
      this.setState({ dispatchedSort: newSortDirection });
      this.fetchDeliveries({ overrideSort: newSortDirection });
      this.handleSearchParams();
   }

   handleSearchParams = () => {
      const searchParams = new URLSearchParams();
      if (this.state.searchDeliveryValue) {
         searchParams.set('search', this.state.searchDeliveryValue);
      }
      if (this.state.selectedFilterDelivery && this.state.selectedFilterDelivery.name) {
         searchParams.set('filter', this.state.selectedFilterDelivery.name);
      }
      if (this.state.dispatchedSort !== ORDER_QUOTE.desc) {
         searchParams.set('sort', this.state.dispatchedSort);
      }
      this.props.history.replace(`?${searchParams.toString()}`);
   }

   render() {
      const {
         searchDeliveryValue,
         hasError,
         selectedFilterDelivery,
         page,
         deliveries,
         loading,
         totalCount,
         totalPages,
      } = this.state;

      return (
         <ApolloProvider client={client}>
            <section className="orders-section">
               <div className="container-fluid">
                  <div className="row d-flex justify-content-center">
                     <div className="header-bar sticky-top col-12">
                        <div className="row">
                           <Header locationPathName={this.props.location.pathname} />

                           <div className="col-12 submenu">
                              <div className="row d-flex justify-content-center">
                                 <div className="col-12">
                                    <div className="row d-flex justify-content-between py-2 py-md-3">
                                       <div className="col-12 col-md-5 col-lg-5 d-flex align-items-center justify-content-lg-start my-0">
                                          <div className="container-fluid">
                                             <div className="row">
                                                <SelectInput
                                                   groupStyle='col-12 col-lg-8 col-xl-6 my-0'
                                                   fieldClass='select'
                                                   selectedOption={selectedFilterDelivery}
                                                   options={FILTER_DELIVERIES}
                                                   optionClass=''
                                                   name='deliveryStatus'
                                                   changeSelect={this.onChangeFilterStatus}
                                                   active={false}
                                                   itemValue={selectedFilterDelivery ?
                                                      (selectedFilterDelivery.value === 0 ? "0" : selectedFilterDelivery.value)
                                                      : null}
                                                />
                                             </div>
                                          </div>
                                       </div>

                                       <div className="col-12 col-md-5 col-lg-5 col-xl-4 d-flex align-items-center justify-content-center justify-content-lg-end mt-2">
                                          <div className="search-bar col-11 col-lg-8 d-flex align-items-center justify-content-between">
                                             <input
                                                onChange={this.onChangesearchDeliveryValue}
                                                value={searchDeliveryValue || ''}
                                                type="text"
                                                name="search"
                                                className="col-11"
                                                placeholder="SHP_, ORD_ or Tracking Number"
                                             />
                                             <i onClick={this.onSearchDeliveriesClick} className="fa fa-search"></i>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                     {hasError ? (
                        <div className="row mt-2 section-error">
                           <div className="col-lg-12">
                              <p>{hasError}</p>
                           </div>
                        </div>
                     ) : loading ? (
                        <Spinner size='small' />
                     ) : (
                        <ShipmentList
                           deliveries={deliveries}
                           totalCount={totalCount}
                           totalPages={totalPages}
                           currentPage={page}
                           dispatchedSort={this.state.dispatchedSort}
                           changePage={this.onChangePage}
                           changedDespatchedSort={this.onChangeDespatchedSort}
                        />
                     )}
                  </div>
               </div>
            </section>
         </ApolloProvider>
      );
   }
}

export default withRouter(Shipments);