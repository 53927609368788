import React from 'react';
import gql from "graphql-tag";
import { ApolloProvider } from 'react-apollo';
import { Row, Col } from 'antd';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import { SelectInput } from '../FormElements/index';
import { PROFILE_TITLE, GRAVAVATAR_URL } from '../../constants';
import { Query } from 'react-apollo';
import { apolloSecureConfig } from '../../support/settings/apollo';
import { ApolloClient } from "apollo-client";
import Spinner from '../Spinner/Spinner';
import { notificationService } from '../../services/NotificationService';
import { validateProfileUser } from '../../support/validation';
import { userService } from '../../services/UserService';
import './styles.scss';

const client = new ApolloClient(apolloSecureConfig);

const GET_PROFILE_DATA = gql`
    query me {
        me {
            Title
            FirstName
            LastName
            Mobile
            Telephone
            Email
       }
     }
`;

const UPDATE = gql`
  mutation updateUser($Title:Int!, $FirstName:String!, $LastName:String!, $Email:String!, $Mobile: String, $Telephone:String){
    updateUser(Title: $Title, FirstName: $FirstName, LastName: $LastName, Email: $Email, Mobile: $Mobile, Telephone: $Telephone){
        Title
        FirstName
        LastName
        Mobile
        Email
    }
  }
`;
class ProfileUpdate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTitle: false,
            selectedTitle: {},
            hasImage: null,
            image: null,
        }
    }

    onChangeTitle = (value) => {
        const findedTitle = PROFILE_TITLE.find(title => title.value === Number(value));
        this.setState({
            activeTitle: true,
            selectedTitle: findedTitle
        });
    }

    onSubmitProfile = (values) => {
        client.mutate({
            mutation: UPDATE,
            variables: values
        }).then(() => {
            notificationService.showSuccessNotification('Profile Updated');
        })
    }

    onSaveProfile = (data) => {
        const { me } = data;
        const findedTitle = PROFILE_TITLE.find(title => title.value === Number(me.Title));
        this.setState({
            selectedTitle: findedTitle
        });
        fetch(`${GRAVAVATAR_URL}${me.Email}?fallback=false`).then(response => {
            this.setState({
                hasImage: response.status === 200 ? true : false,
                image: response.url
            })
        }, () => {
            this.setState({
                hasImage: false
            })
        })
    }

    onLogOut = () => {
        userService.deleteTokenFromLocalStorage()
        window.location.assign('/')
    }

    render() {
        const { activeTitle, selectedTitle, hasImage } = this.state;
        return (
            <section className="update-section col-12 pt-5">
                <ApolloProvider client={client}>
                    <Query fetchPolicy={"cache-and-network"} query={GET_PROFILE_DATA} onCompleted={this.onSaveProfile}>
                        {
                            ({ error, loading, data }) => {
                                if (loading) {
                                    return (<Spinner size='small' />)
                                }
                                return data.me ? (
                                    <Row>
                                        <Col xs={{ span: 24 }} lg={{ span: 14, offset: 4 }}>
                                            <Row>
                                                <Col xs={{ span: 24 }} lg={{ span: 3, offset: 3 }} className="mt-lg-5">
                                                    <Row type='flex' justify='center'>
                                                        <Col offset={1}>
                                                            <div className="profile-icon">
                                                                <div className="row d-flex justify-content-center align-items-center">
                                                                    <div className="col-5 col-lg-11">
                                                                        {hasImage !== null ? (
                                                                            <React.Fragment>
                                                                                {hasImage ? (<img src={`${this.state.image}`} className="img-fluid rounded-circle" alt="icon" />) : (<div className="avatar d-flex align-items-center justify-content-center">{data.me.FirstName[0]}{data.me.LastName[0]}</div>)}
                                                                            </React.Fragment>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xs={{ span: 24 }} lg={{ span: 18 }}>
                                                    <Formik enableReinitialize={true}
                                                        initialValues={{
                                                            Title: data.me.Title ? data.me.Title : '',
                                                            FirstName: data.me.FirstName ? data.me.FirstName : '',
                                                            LastName: data.me.LastName ? data.me.LastName : '',
                                                            Email: data.me.Email ? data.me.Email : '',
                                                            Mobile: data.me.Mobile ? data.me.Mobile : '',
                                                            Telephone: data.me.Telephone ? data.me.Telephone : ''
                                                        }}
                                                        validate={validateProfileUser}
                                                        onSubmit={(values) => this.onSubmitProfile(values)}
                                                    >
                                                        <Form>
                                                            <Row className="mt-lg-5">
                                                                <Col xs={{ span: 23, offset: 1 }} xl={{ span: 14, offset: 1 }}>
                                                                    <Row>
                                                                        <Col xs={{ span: 18 }} lg={{ span: 10 }} >
                                                                            <SelectInput
                                                                                groupStyle='col-7 col-md-3 col-lg-10 col-xl-9 px-0 my-0'
                                                                                fieldClass='select'
                                                                                selectedOption={selectedTitle ? selectedTitle : PROFILE_TITLE[0]}
                                                                                options={PROFILE_TITLE}
                                                                                optionClass=''
                                                                                label="Title:"
                                                                                name='title'
                                                                                changeSelect={this.onChangeTitle}
                                                                                active={activeTitle ? true : false}
                                                                                itemValue={selectedTitle ? selectedTitle.value : ''}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={{ span: 23, offset: 1 }} xl={{ span: 14, offset: 1 }}>
                                                                    <Row>
                                                                        <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 10 }} xl={{ span: 11 }} className="mt-3">
                                                                            <label htmlFor="FirstName" className="field-label">First name:</label>
                                                                            <Field className="form-control px-0" type="text" name="FirstName" />
                                                                            <ErrorMessage name="FirstName" component="div" className="error" />
                                                                        </Col>
                                                                        <Col xs={{ span: 24 }} md={{ span: 6, push: 1 }} lg={{ span: 10, push: 1 }} xl={{ span: 12, push: 1 }} className="mt-3">
                                                                            <label htmlFor="LastName" className="field-label">Last name:</label>
                                                                            <Field className="form-control px-0" type="text" name="LastName" />
                                                                            <ErrorMessage name="LastName" component="div" className="error" />
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={{ span: 23, offset: 1 }} xl={{ span: 14, offset: 1 }}>
                                                                    <Row>
                                                                        <Col xs={{ span: 24 }} md={{ span: 13 }} lg={{ span: 21 }} xl={{ span: 24 }} className="mt-3">
                                                                            <label htmlFor="Email" className="field-label">Contact Email:</label>
                                                                            <Field className="form-control px-0" type="email" aria-required="true" name="Email" />
                                                                            <ErrorMessage name="Email" component="div" className="error" />
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col xs={{ span: 23, offset: 1 }} xl={{ span: 14, offset: 1 }}>
                                                                    <Row>
                                                                        <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 10 }} xl={{ span: 11 }} className="mt-3">
                                                                            <label htmlFor="Mobile" className="field-label">Mobile:</label>
                                                                            <Field className="form-control px-0" type="text" aria-required="true" name="Mobile" />
                                                                            <ErrorMessage name="Mobile" component="div" className="error" />
                                                                        </Col>
                                                                        <Col xs={{ span: 24 }} md={{ span: 6, push: 1 }} lg={{ span: 10, push: 1 }} xl={{ span: 12, push: 1 }} className="mt-3">
                                                                            <label htmlFor="Telephone" className="field-label">Telephone:</label>
                                                                            <Field className="form-control px-0" type="text" aria-required="true" name="Telephone" />
                                                                            <ErrorMessage name="Telephone" component="div" className="error" />
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>

                                                            <Row className="mt-5">
                                                                <Col xs={{ span: 23, offset: 1 }} xl={{ span: 14, offset: 1 }}>
                                                                    <Row>
                                                                        <Col xs={{ span: 10 }} md={{ span: 5 }} lg={{ span: 8 }} xl={{ span: 10 }}>
                                                                            <div className="form-group text-center">
                                                                                <button type="submit" className="form-button">Save</button>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs={{ span: 10, offset: 4 }} md={{ span: 5, push: 2, offset: 1 }} lg={{ span: 8, push: 1 }} xl={{ span: 10, offset: 3 }}>
                                                                            <div className="form-group text-center">
                                                                                <button type="button" className="form-button cancel" onClick={() => this.onLogOut()}>Logout</button>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>

                                                        </Form>
                                                    </Formik>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                ) : null
                            }
                        }
                    </Query>
                </ApolloProvider >
            </section >
        );
    }
}

export default ProfileUpdate;