import React from 'react';
import { withRouter } from "react-router-dom";
import { ApolloProvider } from 'react-apollo';
import gql from "graphql-tag";
import { Query } from 'react-apollo';
import { ApolloClient } from "apollo-client";
import { apolloSecureConfig } from '../../support/settings/apollo';
import QuoteDetail from './QuoteDetail';
import QuoteJob from './QuoteJob';
import Spinner from '../Spinner/Spinner';
import { EDIT_QUOTE_TABS } from '../../constants';
import Header from '../header/Header';
import './style.scss';

const client = new ApolloClient(apolloSecureConfig);

const GET_QUOTE = gql`
    query getOrder($orderId: Int!) {
        getOrder(orderId: $orderId) {
            OrderId
            OrderName
            ProductId
            ProductName
            ClientReference
            DateCreated
            deliveryMethod
            OrderTotal
            DeliveryCost
            OrderStatus
            warningText
            estimateDelivery
            IsEnabledInClientWeb
            jobs {
              JobName
              JobId
              Quantity
              JobStatus
              warningText
            }
       }
     }
`;

const GET_JOB = gql`
query getJob($jobId: Int!) {
    getJob(jobId: $jobId) {
        JobId
        OrderId
        JobName
        ProductName
        ClientJobReference
        ClientReference
        Quantity
        FinishedSizeWidth
        FinishedSizeHeight
        ColorFront
        ColorBack
        ColorThroughout
        Orientation
        Binding
        Pages
        TextPaper
        PaperType
        LaminationFront
        LaminationBack
        CoverColorFront
        CoverColorBack
        CoverColour
        CoverPaper
        CoverPaperType
        LaminationCover
        LaminationCoverFront
        LaminationCoverBack
        DeliveryCost
        TotalPrice
        warningText
        JobStatus
   }
 }
`;
class Quotes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: EDIT_QUOTE_TABS.id,
            currentOrder: {},
            currentJob: {},
            activeOrderIndex: 0,
            activeJobIndex: 0
        }

    }

    onChangeActiveTab = (tab) => {
        this.setState({
            activeTab: tab
        })
        if(tab === EDIT_QUOTE_TABS.id) {
            this.setState({
                activeJobIndex: 0
            });
        }
    }

    onSaveOrder = (data) => {
        const { getOrder } = data;
        this.setState({
            currentOrder: getOrder
        })
    }

    onSaveJob = (data) => {
        const { getJob } = data;
        this.setState({
            currentJob: getJob
        })
    }

    onChangeActiveJobIndex = (activeJobIndex) => {
        this.setState({
            activeJobIndex
        })
    }

    onChangeActiveOrderIndex = (activeOrderIndex) => {
        this.setState({
            activeOrderIndex
        })
    }
    onDisplayErrors = (errors) => {
        if (errors && errors.length) {
            return errors.map((error, index) => {
                return <p key={index}>{error.message}</p>
            })
        }
    }
    onChangeActiveIndex = index => {
        this.setState({
            activeJobIndex: index
        })
    }
    onChangeActiveJobIndex = (activeJobIndex) => {
        this.setState({
            activeJobIndex
        })
    }

    render() {
        const { activeTab, currentOrder, activeJobIndex } = this.state;
        return (
            <ApolloProvider client={client}>
                <section className="orders-section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="header-bar sticky-top col-12">
                                <div className="row">
                                    <Header locationPathName={this.props.location.pathname} />
                                    <div className="col-12 submenu">
                                        <div className="row">
                                            <div className="col-2 px-0 py-3 col-lg-1 d-flex align-items-center order-first">
                                                <div className="container-fluid">
                                                    <div className="row d-flex justify-content-center">
                                                        <button className="btn-back" onClick={() => this.props.history.goBack()}>
                                                            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M10.5381 19.863C15.9447 19.863 20.3433 15.5019 20.3433 10.1413C20.3433 4.7807 15.9447 0.419523 10.5381 0.419522C5.13157 0.419522 0.732978 4.7807 0.732978 10.1413C0.732977 15.5019 5.13156 19.863 10.5381 19.863ZM10.5381 5.2804L10.5381 9.1691L15.4407 9.1691L15.4407 11.1135L10.5381 11.1135L10.5381 15.0022L5.63555 10.1413L10.5381 5.2804Z" fill="var(--faded)" />
                                                            </svg>
                                                            <span className="px-2 d-none d-md-inline-block">Back</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-10 col-md-5 offset-md-2 col-lg-5 offset-lg-2 col-xl-4 offset-xl-2">
                                                <div className="nav col-10 col-lg-8 offset-lg-3 col-xl-8 offset-xl-5 d-flex justify-content-between">
                                                    <button className={`submenu-tab py-4 ${activeTab === EDIT_QUOTE_TABS.id ? "active" : ""}`} id={EDIT_QUOTE_TABS.id} onClick={() => (this.onChangeActiveTab(EDIT_QUOTE_TABS.id))}>Quote# {this.props.orderId}</button>
                                                    <button className={`submenu-tab py-4 ${activeTab === EDIT_QUOTE_TABS.job ? "active" : ""}`} id={EDIT_QUOTE_TABS.job} onClick={() => (this.onChangeActiveTab(EDIT_QUOTE_TABS.job))}>Job(s)</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {activeTab === EDIT_QUOTE_TABS.id &&
                                <Query fetchPolicy={'network-only'} query={GET_QUOTE} variables={{ orderId: Number(this.props.orderId) }} onCompleted={this.onSaveOrder}>
                                    {
                                        ({ error, loading, data }) => {
                                            if (loading) {
                                                return (<Spinner size='small' />)
                                            }
                                            if (error) {
                                                return error.graphQLErrors && error.graphQLErrors.length ? (<div className="col-lg-12 mt-5 section-error">{this.onDisplayErrors(error.graphQLErrors)}</div>) : null;
                                            }
                                            return data.getOrder ? (
                                                <QuoteDetail quoteData={data.getOrder} onChangeActiveTab={() => this.onChangeActiveTab(EDIT_QUOTE_TABS.job)} changeActiveJobIndex={(index) => this.onChangeActiveJobIndex(index)} />
                                            ) : null
                                        }
                                    }
                                </Query>
                            }
                            {activeTab === EDIT_QUOTE_TABS.job && Object.keys(currentOrder).length !== 0 && currentOrder.jobs.length &&
                                <Query fetchPolicy={'network-only'} query={GET_JOB} variables={{ jobId: Number(currentOrder.jobs[activeJobIndex].JobId) }}>
                                    {
                                        ({ error, loading, data }) => {
                                            if (loading) {
                                                return (<Spinner size='small' />)
                                            }
                                            return data.getJob ? (
                                                <QuoteJob jobs={currentOrder.jobs} activeJobIndex={activeJobIndex} changeActiveJobIndex={(index) => this.onChangeActiveIndex(index)} jobData={data.getJob} />
                                            ) : null
                                        }
                                    }
                                </Query>
                            }
                        </div>
                    </div>
                </section >
            </ApolloProvider>
        );
    }
}

export default withRouter(Quotes);