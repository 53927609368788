import React from 'react';
import { withRouter } from 'react-router';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './style.scss';
import { FILTER_ORDERS_NAME } from '../../../constants';

const OrdersInProgress = (props) => {
    const percentage = ((props.productionData.production * 100) / props.productionData.totalCount).toFixed(2);
    return (
        <div className={'production-chart'}>
            <div className="row">
                <div className="col-12 text-small mb-4" onClick={() => props.history.push(`/orders?filter=${FILTER_ORDERS_NAME.production}`)}>Orders in progress
                    <button type="button" className={`button-edit float-right`}>
                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.3779 0.0263672C4.96379 0.0263672 0.559082 4.41927 0.559082 9.81883C0.559082 15.2184 4.96379 19.6113 10.3779 19.6113C15.7919 19.6113 20.1967 15.2184 20.1967 9.81883C20.1967 4.41927 15.7919 0.0263672 10.3779 0.0263672ZM10.3779 14.7151V10.7981H5.46848V8.83959H10.3779V4.9226L15.2873 9.81883L10.3779 14.7151Z" fill="var(--faded)" />
                        </svg>
                    </button>
                </div>
                <div className="col-12">
                    <div className={'progress-chart'} onClick={() => props.history.push(`/orders?filter=${FILTER_ORDERS_NAME.production}`)}>
                        <CircularProgressbar value={percentage} text={`${props.productionData.totalCount}`} styles={buildStyles({
                            textColor: "white",
                            pathColor: "var(--highlight)",
                            trailColor: "var(--error)"
                        })} />
                    </div>
                </div>
                <div className="col-12 text-chart mt-4 pt-3">
                    <div className="row">
                        <div className="col-1"> <div className="circle active"></div></div>
                        <div className="col-10"><a href={`/orders?filter=${FILTER_ORDERS_NAME.production}`}>{props.productionData.production} In production</a></div>
                    </div>
                </div>
                <div className="col-12 text-chart mt-1">
                    <div className="row">
                        <div className="col-1"> <div className="circle expire"></div></div>
                        <div className="col-10"><a href={`/orders?filter=${FILTER_ORDERS_NAME.needAction}`}>{props.productionData.awaitingApproval} Need action</a></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(OrdersInProgress);