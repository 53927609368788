import React from "react";
import gql from "graphql-tag";
import moment from "moment";
import { debounce } from "lodash";
import { Form, Formik } from "formik";
import { Query } from "react-apollo";
import { apolloSecureConfig } from "../../support/settings/apollo";
import { ApolloClient } from "apollo-client";
import { Input, RadioInput, SelectInput, CheckboxInput } from "../FormElements/index.js";
import QuoteDelivery from "./QuoteDelivery";
import QuoteModal from "./QuoteModal";
import {
  BINDING,
  COLOURS_FRONT,
  COLOURS_THROUGHOUT,
  COLOURS_BACK,
  PAPER_TYPE,
  ORIENTATION,
  ORIENTATION_TYPE,
  LAMINATION_BACK,
  PRODUCTS_ORIENTATION,
  PRODUCTS_COLOURS_FRONT_BACK,
  PRODUCTS_FINISHING_FRONT_BACK,
  PRODUCTS_ADD_COVER,
  COVER_AUTO_PRODUCTS,
  PRODUCTS_FRONT_BACK_COVER,
  COLOURS_COVER,
  COVER_PAPER_TYPE,
  PRODUCT_BY_BINDING,
  ADD_CUSTOM_SIZE,
  PRODUCTS_CUSTOM_SIZE,
  ALL_PRODUCTS,
  DELIVERY_METHOD_PLANS,
  PRODUCT_BY_PAGES,
} from "../../constants";
import { orderService } from "../../services/OrderService";
import Spinner from "../Spinner/Spinner";
import "./style.scss";

const client = new ApolloClient(apolloSecureConfig);

const GET_PRINT_SPECS = gql`
  query printSpecByProductId($productName: String!, $printSpecType: Int!) {
    printSpecByProductId(
      productName: $productName
      printSpecType: $printSpecType
    ) {
      printSpecs {
        Name
        PrintSpecId
        Value1
        Value2
      }
      other {
        Name
        PrintSpecId
        Value1
        Value2
      }
    }
  }
`;

const GET_PAPER_PROPERTIES_BY_TYPE = gql`
  query getPaperPropertiesByProductId(
    $productName: String!
    $paperType: String!
  ) {
    getPaperPropertiesByProductId(
      productName: $productName
      paperType: $paperType
    ) {
      Id
      Name
    }
  }
`;
const GET_PAPER_PROPERTIES_BY_PRINT_SPEC = gql`
  query getPaperPropertiesByPrintSpec(
    $productName: String!
    $printSpecType: Int!
    $coverPaperType: String!
  ) {
    getPaperPropertiesByPrintSpec(
      productName: $productName
      printSpecType: $printSpecType
      coverPaperType: $coverPaperType
    ) {
      Name
      Id
    }
  }
`;

const GET_LAMINATION = gql`
  query printSpecByTypeAndOrientation(
    $printSpecType: Int!
    $orientation: String!
  ) {
    printSpecByTypeAndOrientation(
      printSpecType: $printSpecType
      orientation: $orientation
    ) {
      Name
      PrintSpecId
    }
  }
`;
export const CREATE_QUOTE = gql`
  mutation createQuote($input: InputQuote!) {
    createQuote(input: $input) {
      OrderName
      OrderTotal
    }
  }
`;
export const UPDATE_QUOTE = gql`
  mutation updateQuote($input: UpdateQuote!) {
    updateQuote(input: $input) {
      OrderName
      OrderTotal
    }
  }
`;

export const CALCULATE_AUTO_PRICE = gql`
  query calculateAutoPrice($input: InputPrice!) {
    calculateAutoPrice(input: $input) {
      standard {
        vat
        unitPrice
        total
        estimateDelivery
        DeliveryCost
      }
      express {
        vat
        unitPrice
        total
        estimateDelivery
        DeliveryCost
      }
    }
  }
`;

class NewQuoteForm extends React.Component {
  constructor(props) {
    super(props);
    this.calculateAutoPriceSubscriptions = {};
    this.state = {
      quotePriceStandard: {
        image: require("../../assets/img/delivery/standard.svg"),
        category: "Standard",
        estimateDelivery: moment().format("YYYY-MM-DD"),
        vatPrice: 0,
        unitPrice: 0,
        totalPrice: 0,
        DeliveryCost: 0,
        deliveryPlan: DELIVERY_METHOD_PLANS.standard,
      },
      quotePriceExpress: {
        image: require("../../assets/img/delivery/express.svg"),
        category: "Express",
        estimateDelivery: moment().format("YYYY-MM-DD"),
        vatPrice: 0,
        unitPrice: 0,
        totalPrice: 10,
        DeliveryCost: 0,
        deliveryPlan: DELIVERY_METHOD_PLANS.express,
      },
      addCover: false,
      addCustomSize: false,
      orientation: ORIENTATION_TYPE.portrait,
      coloursThroughout: COLOURS_THROUGHOUT[0].value,
      coloursFront: COLOURS_FRONT[0].value,
      coloursBack: COLOURS_BACK[0].value,
      coloursCover: COLOURS_COVER[0].value,
      coloursCoverFront: COLOURS_FRONT[0].value,
      coloursCoverBack: COLOURS_BACK[0].value,
      binding: BINDING[0].value,
      paperType: PAPER_TYPE[0].value,
      coverPaperType: COVER_PAPER_TYPE[0].value,
      laminationCover: "",
      laminationCoverFront: "",
      laminationCoverBack: LAMINATION_BACK[0].value,
      laminationCoverBackId: "",
      laminationFront: "",
      laminationBack: LAMINATION_BACK[0].value,
      laminationBackId: "",
      printSpecs: [],
      paperPropertiesItems: [],
      coverPaperPropertiesItems: [],
      coverPapers: [],
      laminationCoverProperty: [],
      laminationFrontProperty: [],
      laminationFrontCoverProperty: [],
      laminationBackCoverProperty: [],
      selectedPrintSpecs: {},
      selectedPaperPropery: {},
      selectedCoverPaperProperty: {},
      selectedLaminationFront: {},
      selectedLaminationCoverFront: {},
      selectedLaminationCover: {},
      activePrintedPages: false,
      activePaperWeight: false,
      activePrintSpec: false,
      activeCoverPaperWeight: false,
      Quantity: 1,
      Price: "",
      FinishedSizeWidth: "",
      FinishedSizeHeight: "",
      customSizeWidth: "",
      customSizeHeight: "",
      ProductId: "",
      Pages: 4,
      FlatSizeHeight: "",
      FlatSizeWidth: "",
      PriceAdjustment: "",
      TotalPrice: "",
      PaperId: "",
      coverPaperId: "",
      showModal: false,
      deliveryPlan: DELIVERY_METHOD_PLANS.standard,
      showVatStandard: false,
      showVatExpress: false,
      activePaperType: false,
      printSpecOther: null,
    };

    this.calculatePriceDebounced = debounce(this.onCalculatePrice, 700);
  }

  componentWillUnmount() {
    this.onResetState();
    if (Object.keys(this.calculateAutoPriceSubscriptions).length) {
      this.calculateAutoPriceSubscriptions.unsubscribe();
    }
  };

  onResetState = () => {
    this.setState({
      quotePriceStandard: {
        image: require("../../assets/img/delivery/standard.svg"),
        category: "Standard",
        estimateDelivery: moment().format("YYYY-MM-DD"),
        vatPrice: 0,
        unitPrice: 0,
        totalPrice: 0,
        DeliveryCost: 0,
        deliveryPlan: DELIVERY_METHOD_PLANS.standard,
      },
      quotePriceExpress: {
        image: require("../../assets/img/delivery/express.svg"),
        category: "Express",
        estimateDelivery: moment().format("YYYY-MM-DD"),
        vatPrice: 0,
        unitPrice: 0,
        totalPrice: 10,
        DeliveryCost: 0,
        deliveryPlan: DELIVERY_METHOD_PLANS.express,
      },
      addCover: false,
      addCustomSize: false,
      orientation: ORIENTATION_TYPE.portrait,
      coloursThroughout: COLOURS_THROUGHOUT[0].value,
      coloursCover: COLOURS_COVER[0].value,
      coloursFront: COLOURS_FRONT[0].value,
      coloursBack: COLOURS_BACK[0].value,
      binding: BINDING[0].value,
      paperType: PAPER_TYPE[0].value,
      coverPaperType: COVER_PAPER_TYPE[0].value,
      laminationCover: "",
      laminationCoverFront: "",
      laminationCoverBack: LAMINATION_BACK[0].value,
      laminationCoverBackId: "",
      laminationFront: "",
      laminationBack: LAMINATION_BACK[0].value,
      laminationBackId: "",
      printSpecs: [],
      paperPropertiesItems: [],
      coverPaperPropertiesItems: [],
      coverPapers: [],
      laminationCoverProperty: [],
      laminationFrontProperty: [],
      laminationFrontCoverProperty: [],
      laminationBackCoverProperty: [],
      selectedPrintSpecs: {},
      selectedPaperPropery: {},
      selectedCoverPaperProperty: {},
      selectedLaminationFront: {},
      selectedLaminationCoverFront: {},
      activePrintedPages: false,
      activePaperWeight: false,
      activePrintSpec: false,
      activeCoverPaperWeight: false,
      Quantity: 1,
      Price: "",
      FinishedSizeWidth: "",
      FinishedSizeHeight: "",
      customSizeWidth: "",
      customSizeHeight: "",
      ProductId: "",
      Pages: 4,
      FlatSizeWidth: "",
      FlatSizeHeight: "",
      PriceAdjustment: "",
      TotalPrice: "",
      PaperId: "",
      coverPaperId: "",
      showModal: false,
      deliveryPlan: DELIVERY_METHOD_PLANS.standard,
      showVatStandard: false,
      showVatExpress: false,
      activePaperType: false,
      printSpecOther: null,
    });
  };

  // Save
  onSaveSpecs = (data) => {
    const { selectedProduct } = this.props;
    const {
      FinishedSizeWidth,
      FinishedSizeHeight,
      orientation,
      customSizeWidth,
      customSizeHeight,
      laminationFrontProperty,
      selectedLaminationCover,
      Pages
    } = this.state;
    const { printSpecByProductId } = data;
    const defaultData = orderService.getDefaultQuoteData(
      selectedProduct.Name,
      printSpecByProductId.printSpecs
    );
    const findedPrintSpec = defaultData.Size;
    if (
      this.props.editQuote &&
      Object.keys(this.props.editQuote).length !== 0
    ) {
      const findedPrintSpecEdited = printSpecByProductId.printSpecs.find(
        (printSpec) =>
          Number(printSpec.Value1) ===
            Number(this.props.editQuote.FinishedSizeWidth) &&
          Number(printSpec.Value2) ===
            Number(this.props.editQuote.FinishedSizeHeight)
      );
      const splitedColors = this.props.editQuote.PrintSpecType
        ? this.props.editQuote.PrintSpecType.split("/")
        : null;
      const splitedColorsCover = this.props.editQuote.PrintSpecTypeCover
        ? this.props.editQuote.PrintSpecTypeCover.split("/")
        : null;
      this.setState({
        printSpecs: printSpecByProductId.printSpecs,
        selectedPrintSpec: findedPrintSpecEdited
          ? findedPrintSpecEdited
          : defaultData.Size,
        customSizeWidth: findedPrintSpecEdited
          ? customSizeWidth
          : this.props.editQuote.FinishedSizeWidth,
        customSizeHeight: findedPrintSpecEdited
          ? customSizeHeight
          : this.props.editQuote.FinishedSizeHeight,
        addCustomSize: findedPrintSpecEdited ? false : true,
        FinishedSizeWidth: findedPrintSpecEdited
          ? Number(this.props.editQuote.FinishedSizeWidth)
          : Number(this.props.editQuote.FinishedSizeWidth),
        FinishedSizeHeight: findedPrintSpecEdited
          ? Number(this.props.editQuote.FinishedSizeHeight)
          : Number(this.props.editQuote.FinishedSizeHeight),
        FlatSizeWidth: Number(this.props.editQuote.FlatSizeWidth),
        FlatSizeHeight: Number(this.props.editQuote.FlatSizeHeight),
        orientation:
          Number(this.props.editQuote.FinishedSizeWidth) <=
          Number(this.props.editQuote.FinishedSizeHeight)
            ? ORIENTATION_TYPE.portrait
            : ORIENTATION_TYPE.landscape,
        coloursThroughout:
          splitedColors &&
          PRODUCTS_COLOURS_FRONT_BACK.includes(selectedProduct.Name)
            ? splitedColors[0]
            : COLOURS_THROUGHOUT[0].value,
        coloursCover:
          selectedProduct && COVER_AUTO_PRODUCTS.includes(selectedProduct.Name)
            ? splitedColorsCover[0]
            : defaultData.coloursCover,
        coloursCoverFront: splitedColorsCover
          ? splitedColorsCover[0]
          : defaultData.coloursCoverFront,
        coloursCoverBack: splitedColorsCover
          ? splitedColorsCover[1]
          : defaultData.coloursCoverBack,
        coloursFront: splitedColors ? splitedColors[0] : COLOURS_FRONT[0].value,
        coloursBack: splitedColors ? splitedColors[1] : COLOURS_BACK[0].value,
        binding: defaultData.binding,
        paperType: this.props.editQuote.PaperType
          ? this.props.editQuote.PaperType
          : defaultData.paperType,
        coverPaperType: this.props.editQuote.CoverPaperType
          ? this.props.editQuote.CoverPaperType
          : defaultData.coverPaperType,
        Quantity: this.props.editQuote.Quantity
          ? this.props.editQuote.Quantity
          : defaultData.Quantity,
        Pages: this.props.editQuote.Pages
          ? this.props.editQuote.Pages
          : defaultData.Pages,
        PaperId: this.props.editQuote.PaperId
          ? this.props.editQuote.PaperId
          : "",
        coverPaperId: this.props.editQuote.CoverPaperId
          ? this.props.editQuote.CoverPaperId
          : "",
        laminationBack:
          this.props.editQuote.LaminationFrontId !==
          this.props.editQuote.LaminationBackId
            ? LAMINATION_BACK[1].value
            : LAMINATION_BACK[0].value,
        laminationBackId: this.props.editQuote.LaminationBackId
          ? this.props.editQuote.LaminationBackId.toString()
          : "",
        laminationCoverFront: this.props.editQuote.LaminationCoverFrontId
          ? this.props.editQuote.LaminationCoverFrontId.toString()
          : "",
        laminationCoverBackId: this.props.editQuote.LaminationCoverBackId
          ? this.props.editQuote.LaminationCoverBackId.toString()
          : "",
        laminationCover: this.props.editQuote.LaminationCoverId
          ? this.props.editQuote.LaminationCoverId
          : "",
        laminationCoverBack:
          Object.keys(this.props.editQuote).length !== 0
            ? this.props.editQuote.LaminationCoverFrontId ===
              this.props.editQuote.LaminationCoverBackId
              ? LAMINATION_BACK[0].value
              : LAMINATION_BACK[1].value
            : LAMINATION_BACK[0].value,
        addCover: !COVER_AUTO_PRODUCTS.includes(selectedProduct.Name)
          ? true
          : false,
        activePrintSpec: true,
        activePaperWeight: true,
        activePrintedPages: true,
        activeCoverPaperWeight: this.props.editQuote.CoverPaperId
          ? true
          : false,
        printSpecOther: printSpecByProductId.other,
      });
      if (
        this.props.editQuote.deliveryMethod === DELIVERY_METHOD_PLANS.standard
      ) {
        this.setState({
          showVatStandard: this.props.editQuote.IsVattable === 1 ? true : false,
        });
      } else {
        this.setState({
          showVatExpress: this.props.editQuote.IsVattable === 1 ? true : false,
        });
      }
      client
        .query({
          query: GET_LAMINATION,
          variables: { printSpecType: 9, orientation: "Single Sided" },
        })
        .then((response) => {
          const { printSpecByTypeAndOrientation } = response.data;
          this.setState({
            laminationFrontCoverProperty: printSpecByTypeAndOrientation,
          });
        });
      client
        .query({
          query: GET_LAMINATION,
          variables: { printSpecType: 9, orientation: "Double Sided" },
        })
        .then((response) => {
          const { printSpecByTypeAndOrientation } = response.data;
          this.setState({
            laminationBackCoverProperty: printSpecByTypeAndOrientation,
          });
        });
    } else {
      const findedFinishedFront = laminationFrontProperty.find(
        (paper) => paper.Name === "None"
      );
      const flatSizeMultiplier = selectedProduct.Name === ALL_PRODUCTS.foldedLeaflets ? (Pages / 2) : 2;
      //Value1 = Width
      //Value2 = Height
      this.setState({
        printSpecs: printSpecByProductId.printSpecs,
        selectedPrintSpec: defaultData.Size,
        customSizeWidth: defaultData.customSizeWidth,
        customSizeHeight: defaultData.customSizeHeight,
        FinishedSizeHeight: findedPrintSpec
          ? Number(findedPrintSpec.Value2)
          : FinishedSizeHeight,
        FinishedSizeWidth: findedPrintSpec
          ? Number(findedPrintSpec.Value1)
          : FinishedSizeWidth,

        FlatSizeHeight:
          PRODUCTS_ADD_COVER.includes(selectedProduct.Name) ||
          selectedProduct.Name === ALL_PRODUCTS.foldedLeaflets
            ? defaultData.orientation === ORIENTATION_TYPE.portrait
              ? Number(findedPrintSpec.Value2)
              : Number(findedPrintSpec.Value1)
            : findedPrintSpec
            ? Number(findedPrintSpec.Value2)
            : Number(FinishedSizeHeight),

        FlatSizeWidth:
          PRODUCTS_ADD_COVER.includes(selectedProduct.Name) ||
          selectedProduct.Name === ALL_PRODUCTS.foldedLeaflets
            ? defaultData.orientation === ORIENTATION_TYPE.portrait
              ? Number(findedPrintSpec.Value1) * flatSizeMultiplier
              : Number(findedPrintSpec.Value2) * flatSizeMultiplier
            : findedPrintSpec
            ? Number(findedPrintSpec.Value1)
            : FinishedSizeWidth,
        orientation: defaultData.orientation,
        coloursThroughout: defaultData.coloursThroughout,
        coloursCover: defaultData.coloursCover,
        coloursCoverFront: defaultData.coloursCoverFront,
        coloursCoverBack: defaultData.coloursCoverBack,
        coloursFront: defaultData.coloursFront,
        coloursBack: defaultData.coloursBack,
        binding: defaultData.binding,
        paperType: defaultData.paperType,
        coverPaperType: defaultData.coverPaperType,
        Quantity: defaultData.Quantity,
        Pages: defaultData.Pages,
        laminationFront: findedFinishedFront
          ? findedFinishedFront.PrintSpecId
          : "",
        laminationCoverFront: "",
        laminationCoverBackId: "",
        laminationCover: selectedLaminationCover
          ? selectedLaminationCover.PrintSpecId
          : "",
        laminationBack: LAMINATION_BACK[0].value,
        laminationBackId: findedFinishedFront
          ? findedFinishedFront.PrintSpecId
          : "",
        addCustomSize: false,
        selectedPaperPropery: {},
        selectedCoverPaperProperty: {},
        addCover: !COVER_AUTO_PRODUCTS.includes(selectedProduct.Name)
          ? false
          : true,
        printSpecOther: printSpecByProductId.other,
      });
    }

    this.onCalculateAutoPrice();
  };

  onSavePaperProperty = (data) => {
    const { activePaperWeight, activePaperType } = this.state;
    const { getPaperPropertiesByProductId } = data;
    const tempCurrentPaper = this.getCurrentPaperWeight(
      getPaperPropertiesByProductId
    );
    const selectedPaperChanged = {
      Name: tempCurrentPaper.name,
      Id: tempCurrentPaper.value,
    };
    const selectedPaperPropery =
      Object.keys(this.props.editQuote).length !== 0 && !activePaperType
        ? getPaperPropertiesByProductId.find(
            (paper) => Number(paper.Id) === Number(this.props.editQuote.PaperId)
          )
        : selectedPaperChanged;
    this.setState({
      paperPropertiesItems: getPaperPropertiesByProductId,
      PaperId: !activePaperWeight
        ? getPaperPropertiesByProductId[0].Id
        : selectedPaperPropery.Id,
      selectedPaperPropery,
    });
  };

  onSaveCoverProperty = (data) => {
    const { getPaperPropertiesByPrintSpec } = data;
    const selectedCoverPaperPropery =
      Object.keys(this.props.editQuote).length !== 0
        ? getPaperPropertiesByPrintSpec.find(
            (paper) =>
              Number(paper.Id) === Number(this.props.editQuote.CoverPaperId)
          )
        : null;
    this.setState({
      coverPaperPropertiesItems: getPaperPropertiesByPrintSpec,
      coverPaperId: selectedCoverPaperPropery
        ? selectedCoverPaperPropery.Id
        : getPaperPropertiesByPrintSpec[0].Id,
      selectedCoverPaperPropery,
    });
  };

  onSaveLaminationPropertyFront = (data) => {
    const { printSpecByTypeAndOrientation } = data;
    const selectedLaminationFront =
      Object.keys(this.props.editQuote).length !== 0 &&
      this.props.editQuote.LaminationFrontId !== null
        ? printSpecByTypeAndOrientation.find(
            (printSpec) =>
              Number(printSpec.PrintSpecId) ===
              Number(this.props.editQuote.LaminationFrontId)
          )
        : null;
    this.setState({
      laminationFront: selectedLaminationFront
        ? selectedLaminationFront.PrintSpecId
        : printSpecByTypeAndOrientation[0].PrintSpecId,
      selectedLaminationFront: selectedLaminationFront
        ? selectedLaminationFront
        : printSpecByTypeAndOrientation[0],
      laminationFrontProperty: printSpecByTypeAndOrientation,
      laminationBackId:
        this.props.editQuote &&
        Object.keys(this.props.editQuote).length !== 0 &&
        this.props.editQuote.LaminationBackId
          ? this.props.editQuote.LaminationBackId.toString()
          : printSpecByTypeAndOrientation[0].PrintSpecId,
    });
  };

  onSaveLaminationPropertyBack = (data) => {
    const { printSpecByTypeAndOrientation } = data;
    this.setState({
      laminationBackProperty: printSpecByTypeAndOrientation,
    });
  };

  onSaveLaminationPropertyCover = (data) => {
    const { printSpecByTypeAndOrientation } = data;
    const selectedLaminationCover =
      Object.keys(this.props.editQuote).length !== 0
        ? printSpecByTypeAndOrientation.find(
            (printSpec) =>
              Number(printSpec.PrintSpecId) ===
              Number(this.props.editQuote.LaminationCoverId)
          )
        : null;
    this.setState({
      laminationCover: selectedLaminationCover
        ? selectedLaminationCover.PrintSpecId
        : printSpecByTypeAndOrientation[0].PrintSpecId,
      selectedLaminationCover: selectedLaminationCover
        ? selectedLaminationCover
        : printSpecByTypeAndOrientation[0],
    });
  };

  onSaveLaminationPropertyCoverFront = (data) => {
    const { printSpecByTypeAndOrientation } = data;
    const selectedLaminationCoverFront =
      Object.keys(this.props.editQuote).length !== 0
        ? printSpecByTypeAndOrientation.find(
            (printSpec) =>
              Number(printSpec.PrintSpecId) ===
              Number(this.props.editQuote.LaminationCoverFrontId)
          )
        : null;
    this.setState({
      laminationCoverFront: selectedLaminationCoverFront
        ? selectedLaminationCoverFront.PrintSpecId
        : printSpecByTypeAndOrientation[0].PrintSpecId,
    });
  };

  // Format (Not sure what this is....)
  onFormatPrintSpecs = (printSpecs) => {
    let formatedPrintSpec = [];
    if (printSpecs && printSpecs.length) {
      printSpecs.forEach((element) => {
        formatedPrintSpec.push({
          name: element.Name,
          value: element.PrintSpecId,
        });
      });
    }
    return formatedPrintSpec;
  };

  onFormatPaperWeight = (papers) => {
    let formatedPapers = [];
    if (papers && papers.length) {
      papers.forEach((element) => {
        formatedPapers.push({
          name: element.Name,
          value: element.Id,
        });
      });
    }
    return formatedPapers;
  };

  onFormatLamination = (laminations) => {
    let formatedLaminations = [];
    if (laminations && laminations.length) {
      laminations.forEach((element) => {
        formatedLaminations.push({
          name: element.Name,
          value: element.PrintSpecId,
          title: element.Name,
        });
      });
    }
    return formatedLaminations;
  };


  // Get
  getPrintedPages = () => {
    const { selectedProduct } = this.props;
    const {FinishedSizeWidth} = this.state
    let arrPages = [];
    if (selectedProduct) {
      const maxPages =
        selectedProduct.Name === ALL_PRODUCTS.foldedLeaflets ? FinishedSizeWidth > 148.5 ? 4 : 6 : 400;
      const minPage =
        selectedProduct.Name === 4; //ALL_PRODUCTS.foldedLeaflets ? 4 : 8; // Changed to 4 from 8
      const pageName =
        selectedProduct.Name === ALL_PRODUCTS.foldedLeaflets
          ? "panels"
          : "printed pages";
      const multiplier =
        selectedProduct.Name === ALL_PRODUCTS.foldedLeaflets ? 2 : 4;
      for (let i = minPage; i <= maxPages; i = i + multiplier) {
        const bodyOption = {
          name: `${i} ${pageName}`,
          value: i,
          disabled: i === 4 && !this.state.addCover && selectedProduct.Name !== ALL_PRODUCTS.foldedLeaflets ? true : false,
        };
        arrPages.push(bodyOption);
      }
    }
    return arrPages;
  };

  getBindingByProduct = (name) => {
    const findBinding = this.props.productsByBinding.find(
      (element) => element.productName === name
    );
    return findBinding ? findBinding : null;
  };

  getCurrentPaperWeight = (paperWeights) => {
    const { selectedPaperPropery, activePaperWeight } = this.state;
    let findedItem = {};
    if (selectedPaperPropery && activePaperWeight) {
      paperWeights.forEach((paper) => {
        if (
          parseInt(selectedPaperPropery.Name, 10) === parseInt(paper.Name, 10)
        ) {
          findedItem = { name: paper.Name, value: paper.Id };
        }
      });
      if (Object.keys(findedItem).length === 0) {
        const filteredItems = paperWeights.filter(
          (paper) =>
            parseInt(selectedPaperPropery.Name, 10) < parseInt(paper.Name, 10)
        );
        if (filteredItems.length) {
          findedItem = {
            name: filteredItems[0].Name,
            value: filteredItems[0].Id,
          };
        } else {
          findedItem = {
            name: paperWeights[paperWeights.length - 1].Name,
            value: paperWeights[paperWeights.length - 1].Id,
          };
        }
      }
    }
    return findedItem;
  };

  // Change
  onChangeCopies = (copies) => {
    this.setState({
      Quantity: copies,
    });
    this.onCalculateAutoPrice();
  };

  onChangeSize = (printSpecId) => {
    const { selectedProduct } = this.props;
    const {
      orientation,
      printSpecs,
      selectedPrintSpec,
      FinishedSizeWidth,
      FinishedSizeHeight,
      Pages,
    } = this.state;
    const findedPrintSpec = printSpecs.find(
      (printSpec) => printSpec.PrintSpecId === printSpecId
    );
    const flatSizeMultiplier = selectedProduct.Name === ALL_PRODUCTS.foldedLeaflets ? (Pages / 2) : 2;

    this.setState({
      selectedPrintSpec: findedPrintSpec ? findedPrintSpec : selectedPrintSpec,
      FinishedSizeHeight: findedPrintSpec
        ? Number(findedPrintSpec.Value2)
        : FinishedSizeHeight,
      FinishedSizeWidth: findedPrintSpec
        ? Number(findedPrintSpec.Value1)
        : FinishedSizeWidth,
      FlatSizeHeight:
        PRODUCTS_ADD_COVER.includes(selectedProduct.Name) ||
        selectedProduct.Name === ALL_PRODUCTS.foldedLeaflets
          ? orientation === ORIENTATION_TYPE.portrait
            ? Number(findedPrintSpec.Value2)
            : Number(findedPrintSpec.Value1)
          : findedPrintSpec
          ? Number(findedPrintSpec.Value2)
          : FinishedSizeHeight,
      FlatSizeWidth:
        PRODUCTS_ADD_COVER.includes(selectedProduct.Name) ||
        selectedProduct.Name === ALL_PRODUCTS.foldedLeaflets
          ? orientation === ORIENTATION_TYPE.portrait
            ? findedPrintSpec.Value1 * flatSizeMultiplier
            : Number(findedPrintSpec.Value2) * flatSizeMultiplier
          : findedPrintSpec
          ? Number(findedPrintSpec.Value1)
          : FinishedSizeWidth,
      activePrintSpec: true,
    });
    this.onCalculateAutoPrice();
  };

  onChangeWidth = (value) => {
    const { customSizeHeight, Pages } = this.state;
    const { selectedProduct } = this.props;
    const tempOrientation = Number(value) > Number(customSizeHeight) ? 'Landscape' : 'Portrait'
    const flatSizeMultiplier = selectedProduct.Name === ALL_PRODUCTS.foldedLeaflets ? (Pages / 2) : 2;

    this.setState({
      orientation: tempOrientation,
      FinishedSizeWidth: Number(value),
      customSizeWidth: Number(value),
      FlatSizeWidth:
        PRODUCTS_ADD_COVER.includes(selectedProduct.Name) ||
        selectedProduct.Name === ALL_PRODUCTS.foldedLeaflets
          ? tempOrientation === ORIENTATION_TYPE.portrait
            ? Number(value) * flatSizeMultiplier
            : Number(customSizeHeight * flatSizeMultiplier)
          : Number(value),
    });
    this.onCalculateAutoPrice();
  };

  onChangeHeight = (value) => {
    const { customSizeWidth } = this.state;
    const { selectedProduct } = this.props;
    const tempOrientation = Number(customSizeWidth) > Number(value) ? 'Landscape' : 'Portrait'

    this.setState({
      orientation: tempOrientation,
      FinishedSizeHeight: Number(value),
      customSizeHeight: Number(value),
      FlatSizeHeight:
        PRODUCTS_ADD_COVER.includes(selectedProduct.Name) ||
        selectedProduct.Name === ALL_PRODUCTS.foldedLeaflets
          ? tempOrientation === ORIENTATION_TYPE.portrait
            ? Number(value)
            : customSizeWidth
          : Number(value),
    });


    this.onCalculateAutoPrice();
  };

  onChangeCustomSize = (value) => {
    const {
      orientation,
      printSpecs,
      selectedPrintSpec,
      FinishedSizeWidth,
      FinishedSizeHeight,
      customSizeWidth,
      customSizeHeight,
      Pages
    } = this.state;
    const { selectedProduct } = this.props;
    const findedPrintSpec = printSpecs.find(
      (printSpec) => printSpec.PrintSpecId === selectedPrintSpec.PrintSpecId
    );
    const findCustomSize = PRODUCTS_CUSTOM_SIZE.find(
      (element) => element.productName === selectedProduct.Name
    );
    const flatSizeMultiplier = selectedProduct.Name === ALL_PRODUCTS.foldedLeaflets ? (Pages / 2) : 2;

    if (!value) {
      this.setState({
        addCustomSize: value,
        FinishedSizeWidth: findedPrintSpec
          ? Number(findedPrintSpec.Value1)
          : FinishedSizeWidth,
        FinishedSizeHeight: findedPrintSpec
          ? Number(findedPrintSpec.Value2)
          : FinishedSizeHeight,
        FlatSizeWidth:
          PRODUCTS_ADD_COVER.includes(selectedProduct.Name) ||
          selectedProduct.Name === ALL_PRODUCTS.foldedLeaflets
            ? orientation === ORIENTATION_TYPE.portrait
              ? findedPrintSpec.Value1 * flatSizeMultiplier
              : Number(findedPrintSpec.Value1)
            : findedPrintSpec
            ? Number(findedPrintSpec.Value1)
            : FinishedSizeWidth,
        FlatSizeHeight:
          PRODUCTS_ADD_COVER.includes(selectedProduct.Name) ||
          selectedProduct.Name === ALL_PRODUCTS.foldedLeaflets
            ? orientation === ORIENTATION_TYPE.portrait
              ? Number(findedPrintSpec.Value2)
              : Number(findedPrintSpec.Value2) * flatSizeMultiplier
            : findedPrintSpec
            ? Number(findedPrintSpec.Value2)
            : FinishedSizeHeight,
      });
    } else {
      const checkCustomWidth = customSizeWidth
        ? customSizeWidth
        : findCustomSize.width;
      const checkCustomHeight = customSizeHeight
        ? customSizeHeight
        : findCustomSize.height;
      this.setState({
        addCustomSize: value,
        orientation: Number(customSizeWidth) > Number(customSizeHeight) ? 'Landscape' : 'Portrait',
        FinishedSizeWidth: Number(checkCustomWidth),
        FinishedSizeHeight: Number(checkCustomHeight),
        FlatSizeWidth:
          PRODUCTS_ADD_COVER.includes(selectedProduct.Name) ||
          selectedProduct.Name === ALL_PRODUCTS.foldedLeaflets
            ? value === ORIENTATION_TYPE.portrait
              ? Number(checkCustomWidth) * flatSizeMultiplier
              : Number(checkCustomHeight * flatSizeMultiplier)
            : Number(checkCustomWidth),
        FlatSizeHeight:
          PRODUCTS_ADD_COVER.includes(selectedProduct.Name) ||
          selectedProduct.Name === ALL_PRODUCTS.foldedLeaflets
            ? value === ORIENTATION_TYPE.portrait
              ? Number(checkCustomHeight)
              : Number(checkCustomWidth)
            : Number(checkCustomHeight),
      });
    }
    this.onCalculateAutoPrice();
  };

  onChangeOrientation = (value) => {
    const { selectedProduct } = this.props;
    const { selectedPrintSpec, Pages} = this.state;
    const flatSizeMultiplier = selectedProduct.Name === ALL_PRODUCTS.foldedLeaflets ? (Pages / 2) : 2;
    this.setState({
      orientation: value,
      FinishedSizeHeight: Number(selectedPrintSpec.Value1),
      FinishedSizeWidth: Number(selectedPrintSpec.Value2),

      FlatSizeHeight:
        PRODUCTS_ADD_COVER.includes(selectedProduct.Name) || selectedProduct.Name === ALL_PRODUCTS.foldedLeaflets
          ? value === ORIENTATION_TYPE.portrait
            ? Number(selectedPrintSpec.Value2) : Number(selectedPrintSpec.Value1)
          : Number(selectedPrintSpec.Value2),
      FlatSizeWidth:
        PRODUCTS_ADD_COVER.includes(selectedProduct.Name) ||
        selectedProduct.Name === ALL_PRODUCTS.foldedLeaflets
          ? value === ORIENTATION_TYPE.portrait
            ? Number(selectedPrintSpec.Value1) * flatSizeMultiplier
            : Number(selectedPrintSpec.Value2) * flatSizeMultiplier
          : Number(selectedPrintSpec.Value1),
    });
    this.onCalculateAutoPrice();
  };

  onChangeColourFront = (value) => {
    this.setState({
      coloursFront: value,
    });
    this.onCalculateAutoPrice();
  };

  onChangeColourBack = (value) => {
    this.setState({
      coloursBack: value,
    });
    this.onCalculateAutoPrice();
  };

  onChangeColourThroughout = (value) => {
    this.setState({
      coloursFront: value,
      coloursBack: value,
      coloursThroughout: value,
    });
    this.onCalculateAutoPrice();
  };

  onChangeColourCover = (value) => {
    this.setState({
      coloursCover: value,
      coloursCoverFront: value,
      coloursCoverBack: value,
    });
    this.onCalculateAutoPrice();
  };

  onChangeColourCoverFront = (value) => {
    this.setState({
      coloursCoverFront: value,
    });
    this.onCalculateAutoPrice();
  };

  onChangeColourCoverBack = (value) => {
    this.setState({
      coloursCoverBack: value,
    });
    this.onCalculateAutoPrice();
  };

  onChangePaperWeight = (paperId) => {
    const { paperPropertiesItems } = this.state;
    const selectedPaperPropery = paperPropertiesItems.find(
      (paper) => paper.Id === paperId
    );
    this.setState({
      PaperId: paperId,
      activePaperWeight: true,
      selectedPaperPropery,
    });
    this.onCalculateAutoPrice();
  };

  onChangeCoverPaperWeight = (paperId) => {
    this.setState({
      coverPaperId: paperId,
      activeCoverPaperWeight: true,
    });
    this.onCalculateAutoPrice();
  };

  onChangePaperType = (value) => {
    this.setState({
      paperType: value,
      activePaperType: true,
    });
    this.onCalculateAutoPrice();
  };

  onChangeCoverPaperType = (value) => {
    this.setState({
      coverPaperType: value,
    });
    this.onCalculateAutoPrice();
  };

  onChangePrintedPages = (Pages) => {

    const { selectedProduct } = this.props;
    const { selectedPrintSpec, orientation } = this.state;
    const flatSizeMultiplier = selectedProduct.Name === ALL_PRODUCTS.foldedLeaflets ? (Pages / 2) : 2;

    this.setState({
      Pages,
      activePrintedPages: true,
      FlatSizeWidth:
        PRODUCTS_ADD_COVER.includes(selectedProduct.Name) ||
        selectedProduct.Name === ALL_PRODUCTS.foldedLeaflets
          ? orientation === ORIENTATION_TYPE.portrait
            ? Number(selectedPrintSpec.Value1) * flatSizeMultiplier
            : Number(selectedPrintSpec.Value2) * flatSizeMultiplier
          : Number(selectedPrintSpec.Value1),
    });
    this.onCalculateAutoPrice();
  };

  onChangeBinding = (value) => {
    this.setState({
      binding: value,
    });
    this.props.changeSelectedProduct(value);
    this.onCalculateAutoPrice();
  };

  onChangeAddCover = (value) => {
    if (value) {
      client
        .query({
          query: GET_LAMINATION,
          variables: { printSpecType: 9, orientation: "Single Sided" },
        })
        .then((response) => {
          const { printSpecByTypeAndOrientation } = response.data;
          const selectedLaminationCoverFront =
            Object.keys(this.props.editQuote).length !== 0
              ? printSpecByTypeAndOrientation.find(
                  (printSpec) =>
                    Number(printSpec.PrintSpecId) ===
                    Number(this.props.editQuote.LaminationCoverFrontId)
                )
              : null;
          this.setState({
            laminationFrontCoverProperty: printSpecByTypeAndOrientation,
            laminationCoverFront: selectedLaminationCoverFront
              ? selectedLaminationCoverFront.PrintSpecId
              : printSpecByTypeAndOrientation[0].PrintSpecId,
          });
        });
      client
        .query({
          query: GET_LAMINATION,
          variables: { printSpecType: 9, orientation: "Double Sided" },
        })
        .then((response) => {
          const { printSpecByTypeAndOrientation } = response.data;
          const selectedLaminationCoverBack =
            Object.keys(this.props.editQuote).length !== 0
              ? printSpecByTypeAndOrientation.find(
                  (printSpec) =>
                    Number(printSpec.PrintSpecId) ===
                    Number(this.props.editQuote.LaminationCoverBackId)
                )
              : null;
          this.setState({
            laminationBackCoverProperty: printSpecByTypeAndOrientation,
            laminationCoverBack:
              Object.keys(this.props.editQuote).length !== 0
                ? this.props.editQuote.LaminationCoverFrontId ===
                  this.props.editQuote.LaminationCoverBackId
                  ? LAMINATION_BACK[0].value
                  : LAMINATION_BACK[1].value
                : LAMINATION_BACK[0].value,
            laminationCoverBackId: selectedLaminationCoverBack
              ? selectedLaminationCoverBack.PrintSpecId
              : printSpecByTypeAndOrientation[0].PrintSpecId,
          });
        });
    } else {
      this.setState({
        laminationCoverFront: "",
        laminationFrontCoverProperty: [],
        laminationCoverBack: "",
        laminationBackCoverProperty: [],
      });
    }

    if(this.state.Pages === "4" ){
        this.setState({
          Pages: "8"
      })
      this.onChangePrintedPages("8");
    }
    this.setState({
      addCover: value,
    });
    this.onCalculateAutoPrice();
  };

  onChangeLamination = (value) => {
    this.setState({
      lamination: value,
    });
    this.onCalculateAutoPrice();
  };

  onChangeLaminationFront = (value) => {
    const {
      laminationBackProperty,
      selectedLaminationFront,
      laminationFrontProperty,
      laminationBack,
    } = this.state;
    const findedFrontLamination = laminationFrontProperty.find(
      (lamination) => lamination.PrintSpecId === value
    );
    const findedBacklamination = laminationBackProperty.find(
      (lamination) => lamination.Name === findedFrontLamination.Name
    );
    this.setState({
      laminationFront: value,
      laminationBackId:
        laminationBack === "None"
          ? findedFrontLamination.PrintSpecId
          : findedBacklamination.PrintSpecId,
      selectedLaminationFront: findedFrontLamination
        ? findedFrontLamination
        : selectedLaminationFront,
    });
    this.onCalculateAutoPrice();
  };

  onChangeLaminationCoverFront = (value) => {
    const {
      selectedLaminationCoverFront,
      laminationFrontCoverProperty,
      laminationBackCoverProperty,
      laminationCoverBack,
    } = this.state;
    const findedFrontCoverLamination = laminationFrontCoverProperty.find(
      (lamination) => lamination.PrintSpecId === value
    );
    const findedBackCoverLamination = laminationBackCoverProperty.find(
      (lamination) => lamination.Name === findedFrontCoverLamination.Name
    );
    this.setState({
      laminationCoverFront: value,
      laminationCoverBackId:
        laminationCoverBack === "None"
          ? findedFrontCoverLamination.PrintSpecId
          : findedBackCoverLamination.PrintSpecId,
      selectedLaminationCoverFront: findedFrontCoverLamination
        ? findedFrontCoverLamination
        : selectedLaminationCoverFront,
    });
    this.onCalculateAutoPrice();
  };

  onChangeLaminationBack = (value) => {
    const { laminationBackProperty } = this.state;
    const findedBacklamination = laminationBackProperty.find(
      (lamination) =>
        lamination.Name === this.state.selectedLaminationFront.Name
    );
    this.setState({
      laminationBack: value,
      laminationBackId:
        value !== "None"
          ? findedBacklamination.PrintSpecId
          : this.state.selectedLaminationFront.PrintSpecId,
    });
    this.onCalculateAutoPrice();
  };

  onChangeLaminationCoverBack = (value) => {
    const {
      laminationBackCoverProperty,
      selectedLaminationCoverFront,
    } = this.state;
    const findedBackCoverLamination = laminationBackCoverProperty.find(
      (lamination) => lamination.Name === selectedLaminationCoverFront.Name
    );
    this.setState({
      laminationCoverBack: value,
      laminationCoverBackId:
        value !== "None"
          ? findedBackCoverLamination.PrintSpecId
          : selectedLaminationCoverFront.PrintSpecId,
    });
    this.onCalculateAutoPrice();
  };

  onChangeLaminationCover = (value) => {
    this.setState({
      laminationCover: value,
    });
    this.onCalculateAutoPrice();
  };

  // Get JSX
  getColoursFrontHtml = () => {
    const { coloursFront } = this.state;
    return (
      <div className="mb-3 col-12 px-0">
        <RadioInput
          id="coloursFront"
          value={coloursFront}
          name="coloursFront"
          groupClasses="d-inline-block"
          groupLabel="Colours: (front)"
          groupLabelClass="form-label"
          className=""
          itemClass="input-invisible"
          itemLabelClass="btn text-small mr-2 px-0"
          itemLabelClassSecondary=""
          options={COLOURS_FRONT}
          changedInput={this.onChangeColourFront}
        />
      </div>
    );
  };

  getColoursBackHtml = () => {
    const { coloursBack } = this.state;
    return (
      <div className="mb-3 col-12 px-0">
        <RadioInput
          id="coloursBack"
          value={coloursBack}
          name="coloursBack"
          groupClasses="d-inline-block"
          groupLabel="Colours: (back)"
          groupLabelClass="form-label"
          className=""
          itemClass="input-invisible"
          itemLabelClass="btn text-small mr-2 px-0"
          itemLabelClassSecondary=""
          options={COLOURS_BACK}
          changedInput={this.onChangeColourBack}
        />
      </div>
    );
  };

  showModal = (deliveryPlan) => {
    this.setState({ showModal: true, deliveryPlan });
  };

  // On
  onUpdateQuote = (data) => {
    const {
      Quantity,
      FinishedSizeWidth,
      FinishedSizeHeight,
      Pages,
      FlatSizeWidth,
      FlatSizeHeight,
      coloursFront,
      coloursBack,
      coloursCover,
      coloursCoverFront,
      coloursCoverBack,
      selectedPrintSpec,
      PaperId,
      coverPaperId,
      laminationFront,
      laminationCover,
      laminationCoverFront,
      laminationBackId,
      laminationCoverBackId,
      addCover,
      deliveryPlan,
      showVatStandard,
      showVatExpress,
    } = this.state;
    const { selectedProduct } = this.props;
    const PrintSpecTypeCover =
      selectedProduct.Name === ALL_PRODUCTS.wiroBound
        ? coloursCoverFront + "/" + coloursCoverBack
        : coloursCover + "/" + coloursCover;
    let bodyQuote = {
      OrderId: this.props.editQuote.OrderId,
      QuoteName: data.name,
      DeliveryMethodPlan: deliveryPlan,
      ClientReference: data.reference,
      ProductId: Number(selectedProduct.ProductId),
      Quantity: Number(Quantity),
      Size: Number(selectedPrintSpec.PrintSpecId),
      FinishedSizeWidth,
      FinishedSizeHeight,
      Pages: Number(Pages),
      FlatSizeWidth,
      FlatSizeHeight,
      PrintSpecType: coloursFront + "/" + coloursBack,
      PaperId: Number(PaperId),
      Vat:
        deliveryPlan === DELIVERY_METHOD_PLANS.standard
          ? showVatStandard
          : showVatExpress,
    };
    if (addCover) {
      if (coverPaperId) {
        bodyQuote.CoverPaperId = Number(coverPaperId);
      }
    }
    if (!PRODUCTS_ADD_COVER.includes(selectedProduct.Name)) {
      if (PRODUCTS_FINISHING_FRONT_BACK.includes(selectedProduct.Name)) {
        if (laminationFront) {
          bodyQuote.LaminationFrontId = Number(laminationFront);
        }
        if (laminationBackId) {
          bodyQuote.LaminationBackId = Number(laminationBackId);
        }
      }
    }
    if (PRODUCTS_ADD_COVER.includes(selectedProduct.Name)) {
      bodyQuote.Pages = Number(Pages);
      if (COVER_AUTO_PRODUCTS.includes(selectedProduct.Name) || addCover) {
        bodyQuote.PrintSpecTypeCover = PrintSpecTypeCover;
      }
    }
    if (addCover) {
      if (
        selectedProduct &&
        PRODUCTS_FRONT_BACK_COVER.includes(selectedProduct.Name)
      ) {
        bodyQuote.LaminationCoverFrontId = Number(laminationCoverFront);
        bodyQuote.LaminationCoverBackId = Number(laminationCoverBackId);
      } else {
        bodyQuote.LaminationCoverId = Number(laminationCover);
      }
    }
    client
      .mutate({
        mutation: UPDATE_QUOTE,
        variables: {
          input: bodyQuote,
        },
      })
      .then(() => {
        this.setState({
          showModal: false,
        });
        this.props.changeActiveTab();
      });
  };

  onSaveQuote = (data) => {
    const {
      Quantity,
      FinishedSizeWidth,
      FinishedSizeHeight,
      Pages,
      FlatSizeWidth,
      FlatSizeHeight,
      coloursFront,
      coloursBack,
      coloursCover,
      coloursCoverFront,
      coloursCoverBack,
      selectedPrintSpec,
      PaperId,
      coverPaperId,
      laminationFront,
      laminationCover,
      laminationCoverFront,
      laminationBackId,
      laminationCoverBackId,
      addCover,
      deliveryPlan,
      showVatStandard,
      showVatExpress,
    } = this.state;
    const { selectedProduct } = this.props;
    const PrintSpecTypeCover =
      selectedProduct.Name === ALL_PRODUCTS.wiroBound
        ? coloursCoverFront + "/" + coloursCoverBack
        : coloursCover + "/" + coloursCover;
    let bodyQuote = {
      QuoteName: data.name,
      DeliveryMethodPlan: deliveryPlan,
      ClientReference: data.reference,
      ProductId: Number(selectedProduct.ProductId),
      Quantity: Number(Quantity),
      Size: Number(selectedPrintSpec.PrintSpecId),
      FinishedSizeWidth,
      FinishedSizeHeight,
      FlatSizeWidth,
      FlatSizeHeight,
      PrintSpecType: !PRODUCTS_ADD_COVER.includes(selectedProduct.Name)
        ? coloursFront + "/" + coloursBack
        : coloursFront + "/" + coloursFront,
      PaperId: Number(PaperId),
      Vat:
        deliveryPlan === DELIVERY_METHOD_PLANS.standard
          ? showVatStandard
          : showVatExpress,
    };
    if (addCover) {
      if (coverPaperId) {
        bodyQuote.CoverPaperId = Number(coverPaperId);
      }
    }
    if (!PRODUCTS_ADD_COVER.includes(selectedProduct.Name)) {
      if (PRODUCTS_FINISHING_FRONT_BACK.includes(selectedProduct.Name)) {
        if (laminationFront) {
          bodyQuote.LaminationFrontId = Number(laminationFront);
        }
        if (laminationBackId) {
          bodyQuote.LaminationBackId = Number(laminationBackId);
        }
      }
    }
    if (PRODUCTS_ADD_COVER.includes(selectedProduct.Name)) {
      bodyQuote.Pages = addCover ? Number(Pages) + 4 : Number(Pages);
      if (COVER_AUTO_PRODUCTS.includes(selectedProduct.Name) || addCover) {
        bodyQuote.PrintSpecTypeCover = PrintSpecTypeCover;
      }
    }
    if (addCover) {
      if (
        selectedProduct &&
        PRODUCTS_FRONT_BACK_COVER.includes(selectedProduct.Name)
      ) {
        bodyQuote.LaminationCoverFrontId = Number(laminationCoverFront);
        bodyQuote.LaminationCoverBackId = Number(laminationCoverBackId);
      } else {
        bodyQuote.LaminationCoverId = Number(laminationCover);
      }
    }
    client
      .mutate({
        mutation: CREATE_QUOTE,
        variables: {
          input: bodyQuote,
        },
      })
      .then(() => {
        this.setState({
          showModal: false,
        });
        this.props.changeActiveTab();
      });
  };

  onCalculatePrice = () => {
    const {
      Quantity,
      FinishedSizeWidth,
      FinishedSizeHeight,
      Pages,
      FlatSizeWidth,
      FlatSizeHeight,
      coloursFront,
      coloursBack,
      coloursCover,
      coloursCoverFront,
      coloursCoverBack,
      PaperId,
      coverPaperId,
      laminationFront,
      laminationBackId,
      laminationCover,
      laminationCoverFront,
      laminationCoverBackId,
      addCover,
      showVatStandard,
      showVatExpress,
    } = this.state;
    const { selectedProduct } = this.props;
    const PrintSpecTypeCover =
      selectedProduct.Name === ALL_PRODUCTS.wiroBound
        ? coloursCoverFront + "/" + coloursCoverBack
        : coloursCover + "/" + coloursCover;
    let queryQuote = {
      Quantity: Number(Quantity),
      ProductId: Number(selectedProduct.ProductId),
      FinishedSizeWidth,
      FinishedSizeHeight,
      FlatSizeWidth,
      FlatSizeHeight,
      PrintSpecType: !PRODUCTS_ADD_COVER.includes(selectedProduct.Name)
        ? coloursFront + "/" + coloursBack
        : coloursFront + "/" + coloursFront,
      PaperId: Number(PaperId),
      VatStandart: showVatStandard,
      VatExpress: showVatExpress,
    };
    if (PRODUCTS_ADD_COVER.includes(selectedProduct.Name)) {
      queryQuote.Pages = addCover ? Number(Pages) + 4 : Number(Pages);
      if (COVER_AUTO_PRODUCTS.includes(selectedProduct.Name) || addCover) {
        queryQuote.PrintSpecTypeCover = PrintSpecTypeCover;
      }
    }
    if (addCover) {
      if (coverPaperId) {
        queryQuote.CoverPaperId = Number(coverPaperId);
      }
    }
    if (!PRODUCTS_ADD_COVER.includes(selectedProduct.Name)) {
      if (PRODUCTS_FINISHING_FRONT_BACK.includes(selectedProduct.Name)) {
        if (laminationFront) {
          queryQuote.LaminationFrontId = Number(laminationFront);
        }
        if (laminationBackId) {
          queryQuote.LaminationBackId = Number(laminationBackId);
        }
      }
    }
    if (addCover) {
      if (
        selectedProduct &&
        PRODUCTS_FRONT_BACK_COVER.includes(selectedProduct.Name)
      ) {
        queryQuote.LaminationCoverFrontId = Number(laminationCoverFront);
        queryQuote.LaminationCoverBackId = Number(laminationCoverBackId);
      } else {
        queryQuote.LaminationCoverId = Number(laminationCover);
      }
    }
    this.calculateAutoPriceSubscriptions = client
      .watchQuery({
        query: CALCULATE_AUTO_PRICE,
        variables: {
          input: queryQuote,
        },
        fetchPolicy: "network-only",
      })
      .subscribe({
        next: ({ data }) => {
          if (data) {
            const vatStandard = data.calculateAutoPrice.standard.vat;
            const unitPriceStandard =
              data.calculateAutoPrice.standard.unitPrice;
            const totalStandard = data.calculateAutoPrice.standard.total;
            const vatExpress = data.calculateAutoPrice.express.vat;
            const unitPriceExpress = data.calculateAutoPrice.express.unitPrice;
            const totalExpress = data.calculateAutoPrice.express.total;
            const deliveryCostStandard =
              data.calculateAutoPrice.standard.DeliveryCost;
            const deliveryCostExpress =
              data.calculateAutoPrice.express.DeliveryCost;
            const estimateDeliveryStandard =
              data.calculateAutoPrice.standard.estimateDelivery;
            const estimateDeliveryExpress =
              data.calculateAutoPrice.express.estimateDelivery;
            this.setState({
              quotePriceStandard: {
                ...this.state.quotePriceStandard,
                vatPrice: vatStandard,
                unitPrice: unitPriceStandard,
                totalPrice: totalStandard,
                DeliveryCost: deliveryCostStandard,
                estimateDelivery: estimateDeliveryStandard,
              },
              quotePriceExpress: {
                ...this.state.quotePriceExpress,
                vatPrice: vatExpress,
                unitPrice: unitPriceExpress,
                totalPrice: totalExpress,
                DeliveryCost: deliveryCostExpress,
                estimateDelivery: estimateDeliveryExpress,
              },
            });
          }
        },
        error: (e) => console.error(e),
      });
  };

  onCalculateAutoPrice = () => {
    this.calculatePriceDebounced();
  };

  onChangeVatStandard = (vat) => {
    this.setState({
      showVatStandard: vat,
    });
    this.onCalculateAutoPrice();
  };

  onChangeVatExpress = (vat) => {
    this.setState({
      showVatExpress: vat,
    });
    this.onCalculateAutoPrice();
  };

  render() {
    const { selectedProduct } = this.props;

    const {
      orientation,
      coloursThroughout,
      paperType,
      binding,
      laminationCover,
      laminationCoverFront,
      laminationCoverBack,
      laminationFront,
      laminationBack,
      laminationFrontCoverProperty,
      laminationBackCoverProperty,
      coloursCover,
      coloursCoverFront,
      coloursCoverBack,
      coverPaperType,
      addCover,
      selectedPrintSpec,
      PaperId,
      Pages,
      coverPaperId,
      showModal,
      addCustomSize,
      customSizeWidth,
      customSizeHeight,
      Quantity,
      quotePriceStandard,
      quotePriceExpress,
      showVatStandard,
      showVatExpress,
    } = this.state;
    const addSize =
      selectedProduct && ADD_CUSTOM_SIZE.includes(selectedProduct.Name)
        ? false
        : true;
    const findCustomSize = selectedProduct
      ? PRODUCTS_CUSTOM_SIZE.find(
          (element) => element.productName === selectedProduct.Name
        )
      : null;

    const productsOrientation =
      selectedProduct && PRODUCTS_ORIENTATION.includes(selectedProduct.Name)
        ? true
        : false;
    const frontBackColour =
      selectedProduct &&
      PRODUCTS_COLOURS_FRONT_BACK.includes(selectedProduct.Name)
        ? true
        : false;
    const frontBackFinishing =
      selectedProduct &&
      PRODUCTS_FINISHING_FRONT_BACK.includes(selectedProduct.Name)
        ? true
        : false;
    const frontBackCover =
      selectedProduct &&
      PRODUCTS_FRONT_BACK_COVER.includes(selectedProduct.Name)
        ? true
        : false;

    const bindingView =
      selectedProduct &&
      PRODUCT_BY_BINDING.find(
        (element) => element.productName === selectedProduct.Name
      )
        ? true
        : false;
    const bindingProduct =
      selectedProduct && selectedProduct.Name
        ? this.getBindingByProduct(selectedProduct.Name)
        : null;
    const printedPages = this.getPrintedPages();

    const checkCover =
      selectedProduct && PRODUCTS_ADD_COVER.includes(selectedProduct.Name)
        ? true
        : false;
    const autoCovered =
      selectedProduct && COVER_AUTO_PRODUCTS.includes(selectedProduct.Name)
        ? true
        : addCover;
    const coverDisabled =
      selectedProduct && COVER_AUTO_PRODUCTS.includes(selectedProduct.Name)
        ? true
        : false;
    return (
      <div className="col-12">
        <Formik>
          <Form id="quote-form">
            <div className="col-md-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2 mt-3 mt-md-5">
              <div className="row">
                <div className="col-12 col-lg-9">
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-6 mt-3">
                      <Input
                        label="Copies:"
                        type="number"
                        name="copies"
                        labelcols={2}
                        fieldClass="text-input"
                        labelClass="col-12"
                        groupStyle="col-12 px-0"
                        min={1}
                        value={Quantity}
                        changeInput={this.onChangeCopies}
                      />
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 pl-lg-0">
                      <div className="row">
                        <div className="col-5">
                          <label htmlFor="size" className="form-label mt-3">
                            Finished Size:
                          </label>
                        </div>

                        {addSize ? (
                          <div className="col-7 col-md-5 col-lg-5">
                            <CheckboxInput
                              name="customSize"
                              groupStyle="col-12 px-0 mt-3 mb-0"
                              id="customSize"
                              label="Custom Size"
                              labelClass="label-checkbox-size px-2 col-10 text-right"
                              inputClass="col-2"
                              checked={addCustomSize}
                              changed={this.onChangeCustomSize}
                            />
                          </div>
                        ) : null}
                      </div>
                      <div
                        className={
                          selectedProduct &&
                          selectedProduct.Name &&
                          !addCustomSize
                            ? "d-block"
                            : "d-none"
                        }
                      >
                        {selectedProduct && selectedProduct.Name ? (
                          <Query
                            fetchPolicy={"cache-and-network"}
                            query={GET_PRINT_SPECS}
                            variables={{
                              productName: selectedProduct.Name,
                              printSpecType: 2,
                            }}
                            onCompleted={this.onSaveSpecs}
                          >
                            {({ error, loading, data }) => {
                              if (loading) {
                                return <Spinner size="small" />;
                              }
                              return data.printSpecByProductId &&
                                data.printSpecByProductId.printSpecs &&
                                data.printSpecByProductId.printSpecs[0] ? (
                                <div className="col-12 col-lg-10 px-0">
                                  <SelectInput
                                    groupStyle="col-12 mt-1 mb-3 select px-0"
                                    labelClass="col-12"
                                    fieldClass="select"
                                    selectedOption={
                                      Object.keys(this.props.editQuote) !== 0
                                        ? {}
                                        : {
                                            name:
                                              data.printSpecByProductId
                                                .printSpecs[0].Name,
                                            value:
                                              data.printSpecByProductId
                                                .printSpecs[0].value,
                                          }
                                    }
                                    options={this.onFormatPrintSpecs(
                                      data.printSpecByProductId.printSpecs
                                    )}
                                    optionClass=""
                                    name="printSpec"
                                    changeSelect={this.onChangeSize}
                                    active={true}
                                    itemValue={
                                      selectedPrintSpec
                                        ? selectedPrintSpec.PrintSpecId
                                        : null
                                    }
                                  />
                                </div>
                              ) : null;
                            }}
                          </Query>
                        ) : null}
                      </div>

                      {addCustomSize ? (
                        <div className="row">
                          <div className="col-6 col-lg-5 pr-lg-0">
                            <Input
                              groupStyle="col-12 col-md-10 px-0"
                              label="W:"
                              type="number"
                              name="FinishedSizeWidth"
                              labelcols={2}
                              fieldClass="text-input pl-5 col-12 px-0"
                              labelClass="label-input col-2"
                              min={10}
                              max={findCustomSize ? findCustomSize.width : ""}
                              changeInput={this.onChangeWidth}
                              value={customSizeWidth}
                            />
                          </div>
                          <div className="col-6">
                            <Input
                              groupStyle="col-12 col-md-10 px-0"
                              label="H:"
                              type="number"
                              name="FinishedSizeHeight"
                              labelcols={2}
                              fieldClass="text-input pl-5 col-12 px-0"
                              labelClass="label-input col-2"
                              min={1}
                              max={findCustomSize ? findCustomSize.height : ""}
                              changeInput={this.onChangeHeight}
                              value={customSizeHeight}
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-6 my-3">
                      {frontBackColour ? (
                        this.getColoursFrontHtml()
                      ) : (
                        <RadioInput
                          id="coloursThroughout"
                          value={coloursThroughout}
                          name="coloursThroughout"
                          groupClasses="d-inline-block"
                          groupLabel="Colours: (throughout)"
                          groupLabelClass="form-label"
                          className=""
                          itemClass="input-invisible"
                          itemLabelClass="btn text-small mr-2 px-0 mt-2"
                          itemLabelClassSecondary=""
                          options={COLOURS_THROUGHOUT}
                          changedInput={this.onChangeColourThroughout}
                        />
                      )}
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 pl-lg-0 my-3">
                      {frontBackColour ? (
                        this.getColoursBackHtml()
                      ) : (
                        <RadioInput
                          id="orientationColourThroughout"
                          value={orientation}
                          name="orientation"
                          groupClasses="d-inline-block px-0"
                          groupLabel="Orientation:"
                          groupLabelClass="form-label"
                          className=""
                          itemClass="input-invisible"
                          itemLabelClass="btn text-small mr-2 mt-1"
                          itemLabelClassSecondary=""
                          elementClass={{
                            firstClass: "py-4",
                            secondaryClass: "py-4 px-4",
                          }}
                          options={ORIENTATION}
                          changedInput={this.onChangeOrientation}
                        />
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-6">
                      {frontBackCover ? (
                        <RadioInput
                          id="bindingOrientation"
                          value={
                            bindingProduct
                              ? bindingProduct.bindingName
                              : binding
                          }
                          name="binding"
                          groupClasses="d-inline-block mt-2"
                          groupLabel="Binding:"
                          groupLabelClass="form-label"
                          className=""
                          itemClass="input-invisible"
                          itemLabelClass="btn mr-2 mb-3 px-3"
                          itemLabelClassSecondary=""
                          options={BINDING}
                          changedInput={this.onChangeBinding}
                        />
                      ) : null}
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 pl-lg-0">
                      {productsOrientation ? null : (
                        <RadioInput
                          id="orientationColourFrontBack"
                          value={orientation}
                          name="orientation"
                          groupClasses="d-inline-block px-0"
                          groupLabel="Orientation:"
                          groupLabelClass="form-label"
                          className=""
                          itemClass="input-invisible"
                          itemLabelClass="btn text-small mr-2 mt-1"
                          itemLabelClassSecondary=""
                          elementClass={{
                            firstClass: "py-4",
                            secondaryClass: "py-4 px-4",
                          }}
                          options={ORIENTATION}
                          changedInput={this.onChangeOrientation}
                        />
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="container-fluid">
                        <div className="row d-flex justify-content-center">
                          <div className="col-12 px-0">
                            <RadioInput
                              id="paperType"
                              value={paperType}
                              name="paperType"
                              groupClasses="d-inline-block"
                              groupLabel="Paper Type:"
                              groupLabelClass="form-label"
                              className=""
                              itemClass="input-invisible"
                              itemLabelClass="btn mr-2"
                              itemLabelClassSecondary=""
                              options={PAPER_TYPE}
                              changedInput={this.onChangePaperType}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 pl-lg-0">
                      {selectedProduct && selectedProduct.ProductId ? (
                        <Query
                          query={GET_PAPER_PROPERTIES_BY_TYPE}
                          variables={{
                            paperType,
                            productName: selectedProduct.Name,
                          }}
                          onCompleted={this.onSavePaperProperty}
                        >
                          {({ error, loading, data }) => {
                            if (loading) {
                              return <Spinner size="small" />;
                            }
                            return data.getPaperPropertiesByProductId &&
                              data.getPaperPropertiesByProductId.length ? (
                              <div className="col-12 col-md-10 px-0">
                                <SelectInput
                                  groupStyle="col-12 mb-3 px-0 select-icon"
                                  label="Paper weight:"
                                  labelClass="col-12"
                                  fieldClass="select"
                                  selectedOption={this.getCurrentPaperWeight(
                                    data.getPaperPropertiesByProductId
                                  )}
                                  options={this.onFormatPaperWeight(
                                    data.getPaperPropertiesByProductId
                                  )}
                                  optionClass=""
                                  name="papers"
                                  changeSelect={this.onChangePaperWeight}
                                  active={true}
                                  itemValue={PaperId ? PaperId : null}
                                />
                              </div>
                            ) : null;
                          }}
                        </Query>
                      ) : null}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="container-fluid">
                        <div className="row d-flex justify-content-center">
                          <div className="col-12 px-0">
                            <div className="col-12 col-md-10 px-0">
                              {selectedProduct &&
                              PRODUCT_BY_PAGES.find(
                                (page) =>
                                  page.productName === selectedProduct.Name
                              ) ? (
                                <SelectInput
                                  groupStyle="select-icon mt-3"
                                  label="Printed Pages:"
                                  fieldClass="select"
                                  selectedOption={ printedPages[0]}
                                  options={printedPages}
                                  optionClass=""
                                  name="Pages"
                                  changeSelect={this.onChangePrintedPages}
                                  active={true}
                                  itemValue={this.state.Pages ? this.state.Pages : null}
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-6 px-0">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-12 mt-3">
                            <div className="container-fluid pr-0">
                              <div className="row d-flex justify-content-between">
                                {bindingView && !frontBackCover ? (
                                  <RadioInput
                                    id="binding"
                                    value={
                                      bindingProduct
                                        ? bindingProduct.bindingName
                                        : binding
                                    }
                                    name="binding"
                                    groupClasses="d-inline-block"
                                    groupLabel="Binding:"
                                    groupLabelClass="form-label"
                                    className=""
                                    itemClass="input-invisible"
                                    itemLabelClass="btn mr-2 mb-3 px-3"
                                    itemLabelClassSecondary=""
                                    options={BINDING}
                                    changedInput={this.onChangeBinding}
                                  />
                                ) : null}
                                {frontBackCover ? (
                                  <CheckboxInput
                                    name="cover"
                                    groupStyle="mt-3"
                                    id="cover"
                                    label="Add Cover (4 additional pages)"
                                    labelClass="label-checkbox px-1 px-lg-3"
                                    inputClass="col-12"
                                    checked={autoCovered}
                                    changed={this.onChangeAddCover}
                                    disabled={coverDisabled}
                                  />
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-md-6 col-lg-6 pl-lg-0 order-first order-md-last">
                      <div className="container-fluid">
                        <div className="row d-flex justify-content-center">
                          <div className="col-12 px-0">
                            <div className="col-12 col-md-10 px-0">
                              {bindingView ? (
                                <SelectInput
                                  groupStyle="select-icon mt-3"
                                  label="Printed Pages:"
                                  fieldClass="select"
                                  selectedOption={{
                                    name: printedPages[0].name,
                                    value: printedPages[0].value,
                                  }}
                                  options={printedPages}
                                  optionClass=""
                                  name="Pages"
                                  changeSelect={this.onChangePrintedPages}
                                  active={true}
                                  itemValue={Pages ? Pages : null}
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {frontBackFinishing ? (
                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-6 mt-3 pl-0">
                        <div className="container-fluid">
                          <div className="row d-flex justify-content-between">
                            <Query
                              fetchPolicy={"network-only"}
                              query={GET_LAMINATION}
                              variables={{
                                printSpecType: 7,
                                orientation: "Single Sided",
                              }}
                              onCompleted={this.onSaveLaminationPropertyFront}
                            >
                              {({ error, loading, data }) => {
                                if (loading) {
                                  return <Spinner size="small" />;
                                }
                                return data.printSpecByTypeAndOrientation &&
                                  data.printSpecByTypeAndOrientation[0] ? (
                                  <div className="col-12 px-0">
                                    <RadioInput
                                      id="laminationFront"
                                      value={laminationFront}
                                      name="laminationFront"
                                      groupClasses="col-12"
                                      groupLabel="Lamination (front):"
                                      groupLabelClass="form-label col-12 px-0"
                                      className=""
                                      itemClass="input-invisible"
                                      itemLabelClass="btn mr-2 mb-3"
                                      itemLabelClassSecondary=""
                                      options={this.onFormatLamination(
                                        data.printSpecByTypeAndOrientation
                                      )}
                                      changedInput={
                                        this.onChangeLaminationFront
                                      }
                                    />
                                  </div>
                                ) : null;
                              }}
                            </Query>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-md-6 col-lg-6 pl-lg-0 mt-3">
                        <div className="container-fluid">
                          <div className="row d-flex justify-content-between">
                            <Query
                              fetchPolicy={"network-only"}
                              query={GET_LAMINATION}
                              variables={{
                                printSpecType: 7,
                                orientation: "Double Sided",
                              }}
                              onCompleted={this.onSaveLaminationPropertyBack}
                            >
                              {({ error, loading, data }) => {
                                if (loading) {
                                  return <Spinner size="small" />;
                                }
                                return data.printSpecByTypeAndOrientation &&
                                  data.printSpecByTypeAndOrientation[0] ? (
                                  <div className="col-12 px-0">
                                    <RadioInput
                                      id="laminationBack"
                                      value={laminationBack}
                                      name="laminationBack"
                                      groupClasses="col-12 px-0"
                                      groupLabel="Lamination (back):"
                                      groupLabelClass="form-label col-12 px-0"
                                      className=""
                                      itemClass="input-invisible"
                                      itemLabelClass="btn mr-2 mb-3"
                                      itemLabelClassSecondary=""
                                      options={LAMINATION_BACK}
                                      changedInput={this.onChangeLaminationBack}
                                    />
                                  </div>
                                ) : null;
                              }}
                            </Query>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div className="row">
                    <div className="col-12 my-4">
                      {checkCover && !frontBackCover ? (
                        <CheckboxInput
                          name="cover"
                          groupStyle=""
                          id="cover"
                          label="Add Cover (4 additional pages)"
                          labelClass="label-checkbox px-1 px-lg-3"
                          inputClass="col-12"
                          checked={autoCovered}
                          changed={this.onChangeAddCover}
                          disabled={coverDisabled}
                        />
                      ) : null}
                    </div>
                  </div>
                  {autoCovered && checkCover ? (
                    <React.Fragment>
                      {!frontBackCover ? (
                        <div className="row">
                          <div className="col-12 col-lg-6 px-0">
                            <div className="mb-4 col-12">
                              <RadioInput
                                id="coloursCover"
                                value={coloursCover}
                                name="coloursCover"
                                groupClasses="d-inline-block"
                                groupLabel="Cover Colour: (throughout)"
                                groupLabelClass="form-label"
                                className=""
                                itemClass="input-invisible"
                                itemLabelClass="btn text-small mr-2 px-0 pb-3 mt-2"
                                itemLabelClassSecondary=""
                                options={COLOURS_COVER}
                                changedInput={this.onChangeColourCover}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="row">
                          <div className="col-12 col-lg-6">
                            <div className="mb-3 col-12 px-0">
                              <RadioInput
                                id="coloursCoverFront"
                                value={coloursCoverFront}
                                name="coloursCoverFront"
                                groupClasses="d-inline-block"
                                groupLabel="Cover Colour: (front)"
                                groupLabelClass="form-label"
                                className=""
                                itemClass="input-invisible"
                                itemLabelClass="btn text-small mr-2 px-0"
                                itemLabelClassSecondary=""
                                options={COLOURS_FRONT}
                                changedInput={this.onChangeColourCoverFront}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-lg-6 pl-lg-0">
                            <div className="mb-3 col-12 px-0">
                              <RadioInput
                                id="coloursCoverBack"
                                value={coloursCoverBack}
                                name="coloursCoverBack"
                                groupClasses="d-inline-block"
                                groupLabel="Cover Colour: (back)"
                                groupLabelClass="form-label"
                                className=""
                                itemClass="input-invisible"
                                itemLabelClass="btn text-small mr-2 px-0"
                                itemLabelClassSecondary=""
                                options={COLOURS_BACK}
                                changedInput={this.onChangeColourCoverBack}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="row">
                        <div className="col-12 col-lg-6">
                          <div className="container-fluid">
                            <div className="row d-flex justify-content-center">
                              <div className="col-12 px-0">
                                <RadioInput
                                  id="coverPaperType"
                                  value={coverPaperType}
                                  name="coverPaperType"
                                  groupClasses="d-inline-block"
                                  groupLabel="Cover paper type:"
                                  groupLabelClass="form-label"
                                  className=""
                                  itemClass="input-invisible"
                                  itemLabelClass="btn mr-2"
                                  itemLabelClassSecondary=""
                                  options={COVER_PAPER_TYPE}
                                  changedInput={this.onChangeCoverPaperType}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-lg-6 px-0">
                          <Query
                            query={GET_PAPER_PROPERTIES_BY_PRINT_SPEC}
                            variables={{
                              productName: selectedProduct.Name,
                              printSpecType: 6,
                              coverPaperType: coverPaperType,
                            }}
                            onCompleted={this.onSaveCoverProperty}
                          >
                            {({ error, loading, data }) => {
                              if (loading) {
                                return <Spinner size="small" />;
                              }
                              return data.getPaperPropertiesByPrintSpec &&
                                data.getPaperPropertiesByPrintSpec.length ? (
                                <div className="col-12 col-md-10 px-0">
                                  <SelectInput
                                    groupStyle="col-12 mb-3 px-0 select-icon"
                                    label="Cover paper weight:"
                                    labelClass="col-12"
                                    fieldClass="select"
                                    selectedOption={
                                      Object.keys(this.props.editQuote)
                                        .length !== 0
                                        ? {}
                                        : {
                                            name:
                                              data
                                                .getPaperPropertiesByPrintSpec[0]
                                                .Name,
                                            value:
                                              data
                                                .getPaperPropertiesByPrintSpec[0]
                                                .Id,
                                          }
                                    }
                                    options={this.onFormatPaperWeight(
                                      data.getPaperPropertiesByPrintSpec
                                    )}
                                    optionClass=""
                                    name="coverPapers"
                                    changeSelect={this.onChangeCoverPaperWeight}
                                    active={true}
                                    itemValue={
                                      coverPaperId ? coverPaperId : null
                                    }
                                  />
                                </div>
                              ) : null;
                            }}
                          </Query>
                        </div>
                      </div>

                      {!frontBackCover ? (
                        <div className="row">
                          <div className="col-12 col-lg-6 mt-3">
                            <div className="container-fluid">
                              <div className="row d-flex justify-content-between">
                                <Query
                                  fetchPolicy={"network-only"}
                                  query={GET_LAMINATION}
                                  variables={{
                                    printSpecType: 9,
                                    orientation: "Single Sided",
                                  }}
                                  onCompleted={
                                    this.onSaveLaminationPropertyCover
                                  }
                                >
                                  {({ error, loading, data }) => {
                                    if (loading) {
                                      return <Spinner size="small" />;
                                    }
                                    return data.printSpecByTypeAndOrientation &&
                                      data.printSpecByTypeAndOrientation[0] ? (
                                      <div className="col-12 px-0">
                                        <RadioInput
                                          id="laminationCover"
                                          value={
                                            laminationCover
                                              ? laminationCover
                                              : data
                                                  .printSpecByTypeAndOrientation[0]
                                                  .PrintSpecId
                                          }
                                          name="laminationCover"
                                          groupClasses="col-12 px-0"
                                          groupLabel="Cover Finishing:"
                                          groupLabelClass="form-label col-12 px-0"
                                          className=""
                                          itemClass="input-invisible"
                                          itemLabelClass="btn mr-2 mb-3"
                                          itemLabelClassSecondary=""
                                          options={this.onFormatLamination(
                                            data.printSpecByTypeAndOrientation
                                          )}
                                          changedInput={
                                            this.onChangeLaminationCover
                                          }
                                        />
                                      </div>
                                    ) : null;
                                  }}
                                </Query>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {frontBackCover ? (
                        <div className="row">
                          <div className="col-12 col-lg-6 mt-3 pl-0">
                            <div className="container-fluid">
                              <div className="row d-flex justify-content-between">
                                {laminationCoverFront &&
                                laminationFrontCoverProperty &&
                                laminationFrontCoverProperty.length ? (
                                  <RadioInput
                                    id="laminationCoverFront"
                                    value={laminationCoverFront}
                                    name="laminationCoverFront"
                                    groupClasses="col-12 px-0"
                                    groupLabel="Cover Lamination (front):"
                                    groupLabelClass="form-label col-12 px-0"
                                    className=""
                                    itemClass="input-invisible"
                                    itemLabelClass="btn mr-2 mb-3"
                                    itemLabelClassSecondary=""
                                    options={this.onFormatLamination(
                                      laminationFrontCoverProperty
                                    )}
                                    changedInput={
                                      this.onChangeLaminationCoverFront
                                    }
                                  />
                                ) : (
                                  <Spinner size="small" />
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="col-12 col-lg-6 mt-3">
                            <div className="container-fluid">
                              <div className="row d-flex justify-content-between">
                                {laminationCoverBack &&
                                laminationBackCoverProperty &&
                                laminationBackCoverProperty.length ? (
                                  <div className="col-12 px-0">
                                    <RadioInput
                                      id="laminationCoverBack"
                                      value={laminationCoverBack}
                                      name="laminationCoverBack"
                                      groupClasses="col-12 px-0"
                                      groupLabel="Cover Lamination (back):"
                                      groupLabelClass="form-label col-12 px-0"
                                      className=""
                                      itemClass="input-invisible"
                                      itemLabelClass="btn mr-2 mb-3"
                                      itemLabelClassSecondary=""
                                      options={LAMINATION_BACK}
                                      changedInput={
                                        this.onChangeLaminationCoverBack
                                      }
                                    />
                                  </div>
                                ) : (
                                  <Spinner size="small" />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </React.Fragment>
                  ) : null}
                </div>

                <div className="col-12 col-lg-3">
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-12">
                      <QuoteDelivery
                        vat={showVatStandard}
                        updated={
                          Object.keys(this.props.editQuote).length !== 0
                            ? true
                            : false
                        }
                        quoteData={quotePriceStandard}
                        onShowModal={this.showModal}
                        changedVatStandard={this.onChangeVatStandard}
                      />
                    </div>
                    <div className="col-12 col-md-6 col-lg-12 hidden">
                      <QuoteDelivery
                        vat={showVatExpress}
                        updated={
                          Object.keys(this.props.editQuote).length !== 0
                            ? true
                            : false
                        }
                        quoteData={quotePriceExpress}
                        onShowModal={this.showModal}
                        changedVatExpress={this.onChangeVatExpress}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
        <QuoteModal
          editQuote={this.props.editQuote}
          updated={
            Object.keys(this.props.editQuote).length !== 0 ? true : false
          }
          showModal={showModal}
          hideModal={() => this.setState({ showModal: false })}
          saveQuote={(data) => this.onSaveQuote(data)}
          updateQuote={(data) => this.onUpdateQuote(data)}
        />
      </div>
    );
  }
}

export default NewQuoteForm;
