import React from 'react';
import BarOrderStatus from '../BarOrderStatus/BarOrderStatus';
import {
    JOB_STATUSES_BAR, JOB_STATUSES_TITLE, VIEW_BAR_STATUS,
    PRODUCTS_ADD_COVER, PRODUCTS_FRONT_BACK_COVER, PRODUCTS_FINISHING_FRONT_BACK, PRODUCTS_COLOURS_FRONT_BACK, PRODUCT_BY_BINDING,
} from '../../constants';
import './style.scss';
import { withRouter } from 'react-router';



const renderSlider = (props) => {
    const { jobs, activeJobIndex } = props;
    const { warningText } = props.jobData;

    const renderCircle = () => {
        return jobs.map((job, index) => <div className={`circle ${job.warningText ? 'expire' : ''} ${activeJobIndex === index && !warningText ? 'active' : ''} `} key={index} onClick={() => props.changeActiveJobIndex(index)}></div>)
    }

    const onChangeForward = () => {
        if (activeJobIndex < jobs.length - 1) {
            props.changeActiveJobIndex(activeJobIndex + 1);
        }
    }

    const onChangeBackward = () => {
        if (activeJobIndex > 0) {
            props.changeActiveJobIndex(activeJobIndex - 1);
        }
    }

    return (
        <div className="row d-flex justify-content-between align-items-center">
            <button type="button" className="button-edit" onClick={() => onChangeBackward()}>
                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.88436 20.2539C15.2984 20.2539 19.7031 15.861 19.7031 10.4614C19.7031 5.06189 15.2984 0.668993 9.88436 0.668992C4.4703 0.668992 0.0656008 5.06189 0.0656003 10.4614C0.0655998 15.861 4.4703 20.2539 9.88436 20.2539ZM9.88436 5.56522L9.88436 9.4822L14.7937 9.4822L14.7937 11.4407L9.88436 11.4407L9.88436 15.3577L4.97498 10.4614L9.88436 5.56522Z" fill="var(--faded)" />
                </svg>
            </button>
            <div className="slider">
                <div className="row d-flex justify-content-between">
                    {renderCircle()}
                </div>
            </div>
            <button type="button" className="button-edit" onClick={() => onChangeForward()}>
                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.3779 0.0263672C4.96379 0.0263672 0.559082 4.41927 0.559082 9.81883C0.559082 15.2184 4.96379 19.6113 10.3779 19.6113C15.7919 19.6113 20.1967 15.2184 20.1967 9.81883C20.1967 4.41927 15.7919 0.0263672 10.3779 0.0263672ZM10.3779 14.7151V10.7981H5.46848V8.83959H10.3779V4.9226L15.2873 9.81883L10.3779 14.7151Z" fill="var(--faded)" />
                </svg>
            </button>
        </div>
    )
}

const QuoteJob = (props) => {
    const { JobId, JobName, ProductName, Quantity, FinishedSizeWidth, FinishedSizeHeight, ColorFront, ColorBack, ColorThroughout, Orientation, Binding,
        TextPaper, PaperType, LaminationFront, LaminationBack, Pages, CoverColorFront, CoverColorBack, CoverColour, CoverPaper, CoverPaperType, LaminationCoverFront, LaminationCoverBack, LaminationCover,
        TotalPrice, JobStatus, warningText, ClientReference } = props.jobData;

    const frontBackColour = ProductName && PRODUCTS_COLOURS_FRONT_BACK.includes(ProductName) ? true : false;
    const frontBackFinishing = ProductName && PRODUCTS_FINISHING_FRONT_BACK.includes(ProductName) ? true : false;
    const bindingView = ProductName && PRODUCT_BY_BINDING.find(element => element.productName === ProductName) ? true : false;
    const checkCover = ProductName && PRODUCTS_ADD_COVER.includes(ProductName) ? true : false;
    const frontBackCover = ProductName && PRODUCTS_FRONT_BACK_COVER.includes(ProductName) ? true : false;

    return (
        <section className="quote-job col-12 col-xl-10 offset-xl-1 mt-5">
            <div className="row">
                <div className="col-12 col-md-5 col-lg-5 px-0 mb-3">
                    <div className="col-10 offset-2 col-xl-8 mb-3">
                        <div className="container-fluid">
                            {renderSlider(props)}
                        </div>
                    </div>
                    <div className="col-12 id">
                        <div className="row">
                            <div className="col-2 px-0 d-flex justify-content-end">
                                {warningText ? <div className="circle active expire"></div> : null}
                            </div>
                            <span className="col-10"> Job# {JobId}</span>
                        </div>
                    </div>
                    <div className="col-10 offset-2 quote mt-2">{JobName ? JobName : null}</div>
                </div>

                <div className="col-12 col-md-7 col-lg-7 d-none d-md-inline-block">
                    <div className="col-12">
                        <BarOrderStatus status={JobStatus} warningText={warningText} view={VIEW_BAR_STATUS.job} title={JOB_STATUSES_TITLE} statusBar={JOB_STATUSES_BAR} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-5 col-lg-5 mb-3">
                    <div className="row">
                        <div className="col-10 offset-2 job-details my-2">
                            <div className="row">
                                {ProductName ? <p className="col-12">Product: {ProductName}</p> : null}
                                {ClientReference ? <p className="col-12">Your Reference: {ClientReference}</p> : null}
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-10 offset-2 data">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="row mt-1">
                                                <p className="col-12">Quantity:</p>
                                                <p className="col-12 mt-1 value">{Quantity}</p>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="row mt-1">
                                                <p className="col-12">Size:</p>
                                                <p className="col-12 mt-1 value">{FinishedSizeWidth} x {FinishedSizeHeight}</p>
                                            </div>
                                        </div>
                                    </div>

                                    {frontBackCover ?
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="row mt-1">
                                                    <p className="col-12">Binding:</p>
                                                    <p className="col-12 mt-1 value">{Binding}</p>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="row mt-1">
                                                    <p className="col-12">Printed Pages:</p>
                                                    <p className="col-12 mt-1 value">{Pages}</p>
                                                </div>
                                            </div>
                                        </div>
                                        : null}

                                    {!frontBackCover && bindingView ?
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="row mt-1">
                                                    <p className="col-12">Binding:</p>
                                                    <p className="col-12 mt-1 value">{Binding}</p>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="row mt-1">
                                                    <p className="col-12">Printed Pages:</p>
                                                    <p className="col-12 mt-1 value">{Pages}</p>
                                                </div>
                                            </div>
                                        </div>
                                        : null}

                                    {checkCover ?
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="row mt-1">
                                                    <p className="col-12">Orientation:</p>
                                                    <p className="col-12 mt-1 value">{Orientation}</p>
                                                </div>
                                            </div>
                                        </div> : null}
                                </div>

                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-2 px-0 d-flex justify-content-end align-items-baseline">
                                            {checkCover ? <img src={require('../../assets/img/quotes/job-icon.svg')} alt="icon" /> : null}
                                        </div>
                                        <div className="col-10 data">
                                            {frontBackColour ?
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="row mt-1">
                                                            <p className="col-12">{checkCover ? <span>Text </span> : null} Colour: (front)</p>
                                                            <p className="col-12 mt-1 value">{ColorFront}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row mt-1">
                                                            <p className="col-12">{checkCover ? <span>Text </span> : null} Colour: (back)</p>
                                                            <p className="col-12 mt-1 value">{ColorBack}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="row mt-1">
                                                            <p className="col-12">{checkCover ? <span>Text </span> : null}  Colour: (throughout)</p>
                                                            <p className="col-12 mt-1 value">{ColorThroughout}</p>
                                                        </div>
                                                    </div>
                                                </div>}

                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="row mt-1">
                                                        <p className="col-12">{checkCover ? <span>Text </span> : null} Paper weight:</p>
                                                        <p className="col-12 mt-1 value">{TextPaper}</p>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="row mt-1">
                                                        <p className="col-12">{checkCover ? <span>Text </span> : null} Paper type:</p>
                                                        <p className="col-12 mt-1 value">{PaperType}</p>
                                                    </div>

                                                </div>
                                            </div>

                                            {frontBackFinishing ?
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="row mt-1">
                                                            <p className="col-12">{checkCover ? <span>Text </span> : null} Finishing: (front)</p>
                                                            <p className="col-12 mt-1 value">{LaminationFront}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row mt-1">
                                                            <p className="col-12">{checkCover ? <span>Text </span> : null} Finishing: (back)</p>
                                                            <p className="col-12 mt-1 value">{LaminationBack}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {checkCover ?
                            <React.Fragment>
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-2 px-0 d-flex justify-content-end align-items-baseline">
                                            <img src={require('../../assets/img/quotes/job-cover-icon.svg')} alt="icon" />
                                        </div>
                                        <div className="col-10 data">
                                            {frontBackCover ?
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <p className="col-12">Cover Colour: (front)</p>
                                                            <p className="col-12 mt-1 value">{CoverColorFront}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row mt-1">
                                                            <p className="col-12">Cover Colour: (back)</p>
                                                            <p className="col-12 mt-1 value">{CoverColorBack}</p>
                                                        </div>
                                                    </div>
                                                </div> :
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="row mt-1">
                                                            <p className="col-12">Cover Colour: (throughout)</p>
                                                            <p className="col-12 mt-1 value">{CoverColour}</p>
                                                        </div>
                                                    </div>
                                                </div>}
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="row mt-1">
                                                        <p className="col-12">Cover paper weight</p>
                                                        <p className="col-12 mt-1 value">{CoverPaper}</p>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="row mt-1">
                                                        <p className="col-12">Cover paper type</p>
                                                        <p className="col-12 mt-1 value">{CoverPaperType}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {frontBackCover ?
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="row mt-1">
                                                            <p className="col-12">Cover Finishing: (front)</p>
                                                            <p className="col-12 mt-1 value">{LaminationCoverFront}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row mt-1">
                                                            <p className="col-12">Cover Finishing: (back)</p>
                                                            <p className="col-12 mt-1 value">{LaminationCoverBack}</p>
                                                        </div>
                                                    </div>
                                                </div> :
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="row mt-1">
                                                            <p className="col-12">Cover Finishing</p>
                                                            <p className="col-12 mt-1 value">{LaminationCover}</p>
                                                        </div>
                                                    </div>
                                                </div>}
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                            : null}
                        <div className="col-10 offset-2 job-details my-4">
                            <div className="row">
                                <p className="col-12">Total: <span className="order-price">£{Math.round(TotalPrice)}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );
}

export default withRouter(QuoteJob);