export const APP_VERSION = "1.3.8";
export const LOCAL_STORAGE_NAME = process.env.REACT_APP_LOCAL_STORAGE_NAME;
export const GOOGLE_RECAPTCHA_SITE_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;
export const GRAVAVATAR_URL = process.env.REACT_APP_GRAVAVATAR_URL;
export const MAP_BOX_ACCESS_TOKEN = process.env.REACT_APP_MAP_BOX_ACCESS_TOKEN;
export const VIMEO_IFRAME_URL = process.env.REACT_APP_VIMEO_IFRAME_URL;
export const PRINTSPIRATION_URL = process.env.REACT_APP_PRINTSPIRATION_URL;
export const JIMS_URL = process.env.REACT_APP_JIMS_URL;

export const VIEW_BAR_STATUS = {
  order: "order",
  job: "job",
  artwork: "artwork",
  tracking: "tracking",
};

export const ORDER_STATUSES = {
  Estimate: 1,
  Ordered: 2,
  Production: 3,
  Despatched: 4,
  Invoiced: 5,
  Archived: 6,
};


export const DELIVERY_STATUSES = {
  Pending: 0,
  InTransit: 1,
  Delivered: 2,
  Unknown: 3,
};

export const WARNING_NOTIFICATIONS = {
  waitingArtwork: "Waiting Artwork",
  awaitingApproval: "Waiting approval",
  proofRejected: "Proof Rejected",
  exception: "Exception",
  failedAttempt: "Failed Attempt",
};

export const DELIVERY_STATUSES_TITLE = {
  [DELIVERY_STATUSES.Pending]: "Pending",
  [DELIVERY_STATUSES.InTransit]: "In Transit",
  [DELIVERY_STATUSES.Delivered]: "Delivered",
  [DELIVERY_STATUSES.Unknown]: "Unknown",
};

export const ORDER_STATUSES_TITLE = {
  1: {
    title: "Estimated",
  },
  2: {
    title: "Ordered",
  },
  3: {
    title: "In Production",
  },
  4: {
    title: "Dispatched",
  },
  5: {
    title: "Invoiced",
  },
  6: {
    title: "Archived",
  },
  7: {
    title: "Part Invoiced",
  },
  8: {
    title: "Processing",
  },
  9: {
    title: "Processing Error",
  },
  10: {
    title: "Part Despatched",
  },
  11: {
    title: "Part Packed",
  },
  12: {
    title: "Packed",
  },
  13: {
    title: "Queued for auto Invoice",
  },
};

export const ORDER_STATUSES_BAR = [
  {
    orderStatus: 2,
    line: false,
    circle: false,
    expire: false,
  },
  {
    orderStatus: 3,
    line: false,
    circle: false,
    expire: false,
  },
  {
    orderStatus: 4,
    line: false,
    circle: false,
    expire: false,
  },
  {
    orderStatus: 5,
    line: false,
    circle: false,
    expire: false,
  },
];

export const DELIVERY_STATUSES_BAR = [
  {
    deliveryStatus: DELIVERY_STATUSES.Pending,
    line: false,
    circle: false,
    expire: false,
  },
  {
    deliveryStatus: DELIVERY_STATUSES.InTransit,
    line: false,
    circle: false,
    expire: false,
  },
  {
    deliveryStatus: DELIVERY_STATUSES.Delivered,
    line: false,
    circle: false,
    expire: false,
  }
];
export const JOB_STATUSES = {
  Estimate: 1,
  Ordered: 2,
  WaitingArtwork: 3,
  OnProof: 4,
  Approved: 5,
  Imposed: 6,
  Production: 7,
  Printed: 8,
  ProofEmailed: 9,
  WaitingClientReview: 10,
  ClientReviewed: 11,
  PartInvoiced: 12,
  Invoiced: 13,
  Despatched: 14,
  SendToProduction: 15,
  SendToProductionFailed: 16,
  PartDespatched: 17,
  Packed: 18,
  PartPacked: 19,
};

export const JOB_STATUSES_TITLE = {
  1: {
    title: "Estimate",
  },
  2: {
    title: "Ordered",
  },
  3: {
    title: "Waiting Artwork",
  },
  4: {
    title: "On Proof",
  },
  5: {
    title: "Complete",
  },
  6: {
    title: "Imposed",
  },
  7: {
    title: "In Production",
  },
  8: {
    title: "Printed",
  },
  9: {
    title: "Proof Emailed",
  },
  10: {
    title: "Waiting Aproval",
  },
  11: {
    title: "Client Reviewed",
  },
  12: {
    title: "Part Invoiced",
  },
  13: {
    title: "Invoiced",
  },
  14: {
    title: "Despatched",
  },
  15: {
    title: "Queued for Production",
  },
  16: {
    title: "Queued for Production",
  },
  17: {
    title: "Part Despatched",
  },
  18: {
    title: "Packed",
  },
  19: {
    title: "Part Packed",
  },
};

export const JOB_STATUSES_BAR = [
  {
    jobStatus: 3,
    line: false,
    circle: false,
    expire: false,
  },
  {
    jobStatus: 10,
    line: false,
    circle: false,
    expire: false,
  },
  {
    jobStatus: 11,
    line: false,
    circle: false,
    expire: false,
  },
  {
    jobStatus: 7,
    line: false,
    circle: false,
    expire: false,
  },
  {
    jobStatus: 14,
    line: false,
    circle: false,
    expire: false,
  },
  {
    jobStatus: 13,
    line: false,
    circle: false,
    expire: false,
  },
];
export const FILTER_ORDERS_NAME = {
  all: "All",
  ordered: "Ordered",
  production: "In Production",
  needAction: "Need Action",
  dispatched: "Dispatched",
  invoiced: "Invoiced",
};

export const FILTER_ORDERS = [
  {
    name: "All",
    value: 100,
  },
  {
    name: "Need Action",
    value: 10,
  },
  {
    name: "Ordered",
    value: 2,
  },
  {
    name: "In Production",
    value: 3,
  },
  {
    name: "Dispatched",
    value: 4,
  },
  {
    name: "Invoiced",
    value: 5,
  },
];

export const FILTER_DELIVERIES = [
  {
    name: "All",
    value: -100,
  },
  {
    name: "Pending",
    value: 0,
  },
  {
    name: "In Transit",
    value: 1,
  },
  {
    name: "Delivered",
    value: 2,
  },
];

export const ARTWORK_STATUSES = {
  NewArtwork: 1,
  ProofSuccess: 2,
  ProofError: 3,
  ClientApproved: 4,
  ClientRejected: 5,
  Imposed: 6,
  QueuedForProduction: 7,
  Production: 8,
  Printed: 9,
  Preflighted: 10,
  DownloadFailed: 11,
  SendToProduction: 12,
  SendToProductionFailed: 13,
};

export const ARTWORK_STATUSES_TITLE = {
  1: {
    title: "New Artwork",
  },
  2: {
    title: "Proof Success",
  },
  3: {
    title: "Proof Error",
  },
  4: {
    title: "Proof Approved",
  },
  5: {
    title: "Proof Rejected",
  },
  6: {
    title: "Imposed",
  },
  7: {
    title: "Queued For Production",
  },
  8: {
    title: "Production",
  },
  9: {
    title: "Printed",
  },
  10: {
    title: "Preflighted",
  },
  11: {
    title: "Download Failed",
  },
  12: {
    title: "Queued for Production",
  },
  13: {
    title: "Queued for Production",
  },
};

export const ARTWORK_TYPES = {
  Text: 1,
  Cover: 2,
  Guide: 3,
  Pocket: 4,
};

export const ARTWORK_TYPES_TITLE = {
  1: {
    title: "Text",
  },
  2: {
    title: "Cover",
  },
  3: {
    title: "Guide",
  },
  4: {
    title: "Pocket",
  },
};

export const TRACKING_STATUSES = {
  Pending: "Pending",
  InfoReceived: "InfoReceived",
  Booked: "InfoReceived",
  InTransit: "InTransit",
  OutForDelivery: "OutForDelivery",
  Athub: "AtHub",
  AttemptFail: "AttemptFail",
  Delivered: "Delivered",
  Exception: "Exception",
  Expired: "Expired",
};

export const TRACKING_STATUSES_BAR = [
  {
    trackingStatus: "Booked",
    line: false,
    circle: false,
    expire: false,
  },
  {
    trackingStatus: "InfoReceived",
    line: false,
    circle: false,
    expire: false,
  },
  {
    trackingStatus: "InTransit",
    line: false,
    circle: false,
    expire: false,
  },
  {
    trackingStatus: "OutForDelivery",
    line: false,
    circle: false,
    expire: false,
  },
  {
    trackingStatus: "Delivered",
    line: false,
    circle: false,
    expire: false,
  },
];

export const TRACKING_STATUSES_TITLE = {
  Pending: {
    title: "Pending",
  },
  Booked: {
    title: "Booked",
  },
  InfoReceived: {
    title: "Info received",
  },
  InTransit: {
    title: "In Transit",
  },
  AtHub: {
    title: "At Hub",
  },
  OutForDelivery: {
    title: "Out For Delivery",
  },
  Delivered: {
    title: "Delivered",
  },
};

export const CHECKPOINT_TITLE = {
  Pending: {
    title: "Pending",
  },
  "Info Received": {
    title: "Info received",
  },
  Booked: {
    title: "Booked",
  },
  "In Transit": {
    title: "In Transit",
  },
  "At Hub": {
    title: "At Hub",
  },
  "Out For Delivery": {
    title: "Out For Delivery",
  },
  AttemptFail: {
    title: "Attempt fail",
  },
  Delivered: {
    title: "Delivered",
  },
  Exception: {
    title: "Exception",
  },
  Expired: {
    title: "Expired",
  },
};

export const SLUG = {
  "Rush": {
    image: require("../assets/img/orders/DPD.svg"),
  },
  "DPD": {
    image: require("../assets/img/orders/DPD.svg"),
  },
  "Royal Mail": {
    image: require("../assets/img/orders/ROYALMAIL.svg"),
  },
  FedEx: {
    image: require("../assets/img/orders/FEDEX.svg"),
  },
  "DX": {
    image: require("../assets/img/orders/dx-logo.svg"),
  },

};

export const BINDING = [
  {
    name: "Staple",
    value: "Staple",
    title: "Staple",
  },
  {
    name: "Perfect",
    value: "Perfect",
    title: "Perfect",
  },
  {
    name: "PUR",
    value: "PUR",
    title: "PUR",
  },
  {
    name: "Wiro",
    value: "Wiro",
    title: "Wiro",
  },
  // { name: "Spiral", value: "Spiral", title: "Spiral" }
];

export const COLOURS_FRONT = [
  {
    name: "FColour",
    value: "4",
    title: "Colour",
    icon: require("../assets/img/colours/colour.svg"),
    selectedIcon: require("../assets/img/colours/activeColour.svg"),
  },
  {
    name: "FBlack",
    value: "1",
    title: "Black",
    icon: require("../assets/img/colours/greyscale.svg"),
    selectedIcon: require("../assets/img/colours/greyscale.svg"),
  },
];

export const COLOURS_BACK = [
  {
    name: "BColour",
    value: "4",
    title: "Colour",
    icon: require("../assets/img/colours/colour.svg"),
    selectedIcon: require("../assets/img/colours/activeColour.svg"),
  },
  {
    name: "BBlack",
    value: "1",
    title: "Black",
    icon: require("../assets/img/colours/greyscale.svg"),
    selectedIcon: require("../assets/img/colours/greyscale.svg"),
  },
  {
    name: "BNone",
    value: "0",
    title: "None",
    icon: require("../assets/img/colours/none.svg"),
    selectedIcon: require("../assets/img/colours/none.svg"),
  },
];

export const COLOURS_THROUGHOUT = [
  {
    name: "TColour",
    value: "4",
    title: "Colour",
    icon: require("../assets/img/colours/colour.svg"),
    selectedIcon: require("../assets/img/colours/activeColour.svg"),
  },
  {
    name: "TBlack",
    value: "1",
    title: "Black",
    icon: require("../assets/img/colours/greyscale.svg"),
    selectedIcon: require("../assets/img/colours/greyscale.svg"),
  },
];

export const COLOURS_COVER = [
  {
    name: "CoverColour",
    value: "4",
    title: "Colour",
    icon: require("../assets/img/colours/colour.svg"),
    selectedIcon: require("../assets/img/colours/activeColour.svg"),
  },
  {
    name: "CoverBlack",
    value: "1",
    title: "Black",
    icon: require("../assets/img/colours/greyscale.svg"),
    selectedIcon: require("../assets/img/colours/greyscale.svg"),
  },
];

export const PAPER_TYPE = [
  {
    name: "PGloss",
    value: "Gloss",
    title: "Gloss",
  },
  {
    name: "PSilk",
    value: "Silk",
    title: "Silk",
  },
  {
    name: "PUncoated",
    value: "Uncoated",
    title: "Uncoated",
  },
  // { name: "PRecycled", value: "Recycled", title: "Recycled" },
];

export const COVER_PAPER_TYPE = [
  {
    name: "CGloss",
    value: "Gloss",
    title: "Gloss",
  },
  {
    name: "CSilk",
    value: "Silk",
    title: "Silk",
  },
  {
    name: "CUncoated",
    value: "Uncoated",
    title: "Uncoated",
  },
  // { name: "CRecycled", value: "Recycled", title: "Recycled" },
];

export const LAMINATION_BACK = [
  {
    name: "LNone",
    value: "None",
    title: "None",
  },
  {
    name: "LSame as Front",
    value: "Same as Front",
    title: "Same as Front",
  },
];

export const ORIENTATION = [
  {
    name: "Portrait",
    value: "Portrait",
    title: "Portrait",
    icon: require("../assets/img/orientation/portrait.svg"),
    selectedIcon: require("../assets/img/orientation/selectedPortrait.svg"),
  },
  {
    name: "Landscape",
    value: "Landscape",
    title: "Landscape",
    icon: require("../assets/img/orientation/landscape.svg"),
    selectedIcon: require("../assets/img/orientation/selectedLandscape.svg"),
  },
];

export const ORIENTATION_TYPE = {
  portrait: "Portrait",
  landscape: "Landscape",
};

export const PRINT_SPECS_TYPES = {
  Colour: 4,
  Greyscale: 1,
  None: 0,
};

export const PRODUCT_BY_BINDING = [
  {
    bindingName: "Staple",
    productName: "FST Brochures",
  },
  {
    bindingName: "Perfect",
    productName: "Perfect Bound Brochures",
  },
  {
    bindingName: "PUR",
    productName: "PUR Bound Brochures",
  },
  {
    bindingName: "Wiro",
    productName: "Wiro Bound",
  },
  // { bindingName: "Spiral", productName: "Spiral Bound" }
];

export const PRODUCT_BY_PAGES = [
  {
    productName: "Folded Leaflets",
  },
];

export const PRODUCTS_ORIENTATION = [
  "Leaflet",
  "Folded Leaflets",
  "Postcard",
  "Comp Slip",
  "Business Card",
  "FST Brochures",
  "Perfect Bound Brochures",
  "PUR Bound Brochures",
];

export const PRODUCTS_COLOURS_FRONT_BACK = [
  "Leaflet",
  "Folded Leaflets",
  "Postcard",
  "Comp Slip",
  "Business Card",
  "Wiro Bound",
];

export const PRODUCTS_FINISHING_FRONT_BACK = [
  "Leaflet",
  "Postcard",
  "Business Card",
  "Folded Leaflets",
];

export const PRODUCTS_ADD_COVER = [
  "FST Brochures",
  "Perfect Bound Brochures",
  "PUR Bound Brochures",
  "Wiro Bound",
];

export const COVER_AUTO_PRODUCTS = [
  "Perfect Bound Brochures",
  "PUR Bound Brochures",
];

export const PRODUCTS_FRONT_BACK_COVER = ["Wiro Bound"];

export const ADD_CUSTOM_SIZE = ["Postcard", "Comp Slip"];

export const PRODUCTS_CUSTOM_SIZE = [
  {
    productName: "Leaflet",
    width: "210",
    height: "297",
  },
  {
    productName: "Folded Leaflets",
    width: "210",
    height: "297",
  },
  {
    productName: "Business Card",
    width: "85",
    height: "55",
  },
  {
    productName: "Wiro Bound",
    width: "210",
    height: "297",
  },
  {
    productName: "Perfect Bound Brochures",
    width: "210",
    height: "297",
  },
  {
    productName: "FST Brochures",
    width: "210",
    height: "297",
  },

  {
    productName: "PUR Bound Brochures",
    width: "210",
    height: "297",
  },
];

export const ALL_PRODUCTS = {
  leaflet: "Leaflet",
  foldedLeaflets: "Folded Leaflets",
  postCard: "Postcard",
  businessCard: "Business Card",
  wiroBound: "Wiro Bound",
  compSlip: "Comp Slip",
  perfectBoundBrochures: "Perfect Bound Brochures",
  fstBrochures: "FST Brochures",
  purBoundBrochures: "PUR Bound Brochures",
};

export const DELIVERY_METHOD_PLANS = {
  standard: "standard",
  express: "express",
};

export const ORDER_QUOTE = {
  asc: "ASC",
  desc: "DESC",
};

export const PROFILE_TABS = {
  profile: "profile",
  security: "security",
};

export const PROFILE_TITLE = [
  {
    name: "Mr",
    value: 1,
  },
  {
    name: "Mrs",
    value: 2,
  },
  {
    name: "Ms",
    value: 3,
  },
  {
    name: "Miss",
    value: 4,
  },
  {
    name: "Dr.",
    value: 5,
  },
  {
    name: "Rev",
    value: 6,
  },
  {
    name: "None",
    value: 0,
  },
];

export const QUOTES_TABS = {
  new: "newquote",
  saved: "quotes",
  editquote: "editquote",
};

export const EDIT_QUOTE_TABS = {
  id: "Quote#",
  job: "Job(s)",
};

export const ORDER_TABS = {
  order: "orders",
  editorder: "editorder",
};

export const SHIPMENT_TABS = {
  id: "SHP_",
  job: "Job(s)",
};

export const EDIT_ORDER_TABS = {
  id: "Order#",
  job: "Job(s)",
  artwork: "Artwork(s)",
  tracking: "Tracking",
};

export const SHORT_MONTHS = {
  January: "Jan",
  February: "Feb",
  March: "Mar",
  April: "Apr",
  May: "May",
  June: "Jun",
  July: "Jul",
  August: "Aug",
  September: "Sep",
  October: "Oct",
  November: "Nov",
  December: "Dec",
};

export const REGEX_EXPRESSION_TIME = {
  time: /^(?:0?\d|1[012]):[0-5]\d(am|pm|AM|PM)/,
};

export const ACTION_EDIT_ORDER = {
  fromTab: "fromTab",
  fromJob: "fromJob",
};

export const PRINT_INSPIRATIONS = [
  {
    author: "Layflat Binding",
    time: "Wed, 11 Feb 2020",
    videoId: 238480244,
  },
  {
    author: "Layflat Binding",
    time: "Wed, 11 Feb 2020",
    videoId: 359256358,
  },
  {
    author: "Layflat Binding",
    time: "Wed, 11 Feb 2020",
    videoId: 359129575,
  },
];
