import React from "react";
import './style.scss';

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
   const handlePrevious = () => {
      if (currentPage > 0) {
         onPageChange(currentPage - 1);
      }
   };

   const handleNext = () => {
      if (currentPage < totalPages - 1) {
         onPageChange(currentPage + 1);
      }
   };

   const renderPageNumbers = () => {
      if (totalPages < 6) {
         return new Array(totalPages).fill(1).map((_, index) => (
            <li
               className={`page-item ${currentPage === index ? "active" : ""}`}
               key={index}
            >
               <a
                  className="page-link d-flex justify-content-center align-items-center"
                  onClick={() => onPageChange(index)}
               >
                  {index + 1}
               </a>
            </li>
         ));
      } else {
         return new Array(totalPages).fill(1).map((_, index) => {
            if (
               index === 0 ||
               (index === 1 && currentPage < 2) ||
               index === currentPage ||
               (currentPage === totalPages - 1 && index === totalPages - 2) ||
               index === totalPages - 1
            ) {
               return (
                  <li
                     className={`page-item ${currentPage === index ? "active" : ""}`}
                     key={index}
                  >
                     <a
                        className="page-link d-flex justify-content-center align-items-center"
                        onClick={() => onPageChange(index)}
                     >
                        {index + 1}
                     </a>
                  </li>
               );
            }
            if (
               index === currentPage - 1 ||
               (currentPage === totalPages - 1 && index === totalPages - 3)
            ) {
               return (
                  <li
                     className={`page-item ${currentPage === index ? "active" : ""}`}
                     key={index}
                  >
                     <a
                        className="page-link d-flex justify-content-center align-items-center"
                        onClick={() => handlePrevious()}
                     >
                        ...
                     </a>
                  </li>
               );
            }
            if (index === totalPages - 2) {
               return (
                  <li
                     className={`page-item ${currentPage === index ? "active" : ""}`}
                     key={index}
                  >
                     <a
                        className="page-link d-flex justify-content-center align-items-center"
                        onClick={() => handleNext()}
                     >
                        ...
                     </a>
                  </li>
               );
            }
            return null;
         });
      }
   };

   return (
      <ul className="pagination">
         <li className="page-item">
            <a className="page-link side" onClick={handlePrevious}>
               <button type="button" className="button-edit">
                  <svg
                     width="20"
                     height="21"
                     viewBox="0 0 20 21"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                  >
                     <path
                        d="M9.88436 20.2539C15.2984 20.2539 19.7031 15.861 19.7031 10.4614C19.7031 5.06189 15.2984 0.668993 9.88436 0.668992C4.4703 0.668992 0.0656008 5.06189 0.0656003 10.4614C0.0655998 15.861 4.4703 20.2539 9.88436 20.2539ZM9.88436 5.56522L9.88436 9.4822L14.7937 9.4822L14.7937 11.4407L9.88436 11.4407L9.88436 15.3577L4.97498 10.4614L9.88436 5.56522Z"
                        fill="var(--faded)"
                     />
                  </svg>
               </button>
            </a>
         </li>
         {renderPageNumbers()}
         <li className="page-item">
            <a className="page-link side" onClick={handleNext}>
               <button type="button" className="button-edit">
                  <svg
                     width="21"
                     height="20"
                     viewBox="0 0 21 20"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                  >
                     <path
                        d="M10.3779 0.0263672C4.96379 0.0263672 0.559082 4.41927 0.559082 9.81883C0.559082 15.2184 4.96379 19.6113 10.3779 19.6113C15.7919 19.6113 20.1967 15.2184 20.1967 9.81883C20.1967 4.41927 15.7919 0.0263672 10.3779 0.0263672ZM10.3779 14.7151V10.7981H5.46848V8.83959H10.3779V4.9226L15.2873 9.81883L10.3779 14.7151Z"
                        fill="var(--faded)"
                     />
                  </svg>
               </button>
            </a>
         </li>
      </ul>
   );
};

export default Pagination;